import React, { useEffect, useState } from "react";
import PaymentNavbar from "./Payment/comp/PaymentNavbar";
import Required from "../components/Required";
import { useGlobalContext } from "../Contexts/GlobalContext";
import axios from "axios";
import { BASE_URL } from "../Utlils";

const Onboarding = () => {
  const {
    currencies,
    user,
    setSnackbar,
    getOrganization,
    organization,
    taxIdTypes,
  } = useGlobalContext();
  const [activeTab, setActiveTab] = useState(0);
  const [inputData, setInputData] = useState({});
  const [disableButton, setDisableButton] = useState(false);
  const [addressDetails, setAddressDetails] = useState({});
  const renderOnboarding = () => {
    switch (activeTab) {
      case 0:
        return (
          <OrganizationDetails
            currencies={currencies}
            inputData={inputData}
            setInputData={setInputData}
            taxIdTypes={taxIdTypes}
          />
        );
      default:
        return (
          <AddressDetails
            addressDetails={addressDetails}
            setAddressDetails={setAddressDetails}
          />
        );
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisableButton(true);
    try {
      if (activeTab == 0) {
        localStorage.setItem("organizationDetails", JSON.stringify(inputData));
        setActiveTab((prev) => prev + 1);
      } else {
        let orgData = JSON.parse(localStorage.getItem("organizationDetails"));
        let finalData = {
          addresses: { billingAddress: addressDetails },
          name: orgData?.name,
          finance: orgData?.finance,
          projectSettings: orgData?.projectSettings,
          id: user?.organizationId,
        };
        if (organization?.id) {
          await axios.put(`${BASE_URL}/company/${organization?.id}`, finalData);
        } else {
          await axios.post(`${BASE_URL}/company`, finalData);
        }

        await axios.put(`${BASE_URL}/users?id=${user?._id}`, {
          organizationName: orgData?.name,
        });
        getOrganization(finalData?.id);
        setSnackbar({
          open: true,
          msg: "Organization details updated successfully !",
          severity: "success",
        });
        localStorage.removeItem("organizationDetails");
        window.location.href = window.location.origin;
      }
    } catch (error) {
      console.log("error in creating org data", error);
      setSnackbar({
        open: true,
        msg: "Something went wrong !",
        severity: "error",
      });
    } finally {
      setDisableButton(false);
    }
  };
  useEffect(() => {
    setInputData({
      ...organization,
      finance: {
        taxIDType: organization?.finance?.taxIDType || "",
        taxID: organization?.finance?.taxID || "",
        timeZone:
          organization?.finance?.timeZone ||
          Intl.DateTimeFormat().resolvedOptions().timeZone,
        currency: organization?.finance?.currency || "USD",
      },
      projectSettings: {
        expectedDropOfRate: 10,
        expectedQuotaRate: 20,
        ...organization?.projectSettings,
      },
    });
    setAddressDetails((prev) => ({
      ...prev,
      ...organization?.addresses?.billingAddress,
    }));
  }, [Object.keys(organization)?.length]);
  // console.log("organization=>", organization);
  // console.log("inputData=>", inputData);
  // console.log("addressDetails=>", addressDetails);
  return (
    <>
      <PaymentNavbar />
      <div className="w-full  h-[700px] md:h-[650px] xl:h-[700px] flex items-center justify-center">
        <form
          onSubmit={handleSubmit}
          className=" shadow-lg border border-gray-100 rounded-[32px] p-[20px_25px] w-[90%] md:w-[75%] lg:w-[50%] xl:w-[45%] h-[75%] space-y-6"
        >
          {" "}
          <header className="grid grid-cols-4 items-center justify-between ">
            <div className="col-span-3 space-y-1">
              <h1 className="text-[22px] text-[#333] font-[500]">
                Set-Up your organisation
              </h1>
              <p className="text-[14px] text-[#666] font-[500]">
                {activeTab == 0
                  ? " This will help us to maintain your information"
                  : "You can change the details from profile section"}
              </p>
            </div>
            <div className="text-end">
              {" "}
              {+activeTab + 1} Of <b>2</b>
            </div>
          </header>
          <main className=" w-full overflow-y-auto containerScroll h-[60%] md:h-[65%] lg:h-[70%]">
            {renderOnboarding()}
          </main>
          <footer className="w-full  flex items-center justify-end">
            <button
              type="submit"
              disabled={disableButton}
              className={`bg-primary p-[8px_20px] rounded-lg text-[#fff] ${
                disableButton ? "cursor-not-allowed" : "cursor-pointer"
              }`}
            >
              {activeTab == 0 ? "Next" : "Submit"}
            </button>
          </footer>
        </form>
      </div>
    </>
  );
};
const OrganizationDetails = ({
  currencies,
  inputData,
  setInputData,
  taxIdTypes,
}) => {
  const handleFinanceChange = (e) => {
    let { name, value } = e.target;
    setInputData((prev) => ({
      ...prev,
      finance: { ...prev?.finance, [name]: value },
    }));
  };
  const handleProjectSettingChange = (e) => {
    let { name, value } = e.target;
    setInputData((prev) => ({
      ...prev,
      projectSettings: { ...prev?.projectSettings, [name]: value },
    }));
  };
  return (
    <div className="space-y-[0.8rem] px-2 ">
      <div className="space-y-2 flex flex-col">
        <label
          htmlFor="organizationName"
          className="text-[#333] text-[14px] font-[500]"
        >
          Organization Name <Required />
        </label>
        <input
          type="text"
          required
          name="organizationName"
          value={inputData?.name}
          onChange={(e) => {
            setInputData((prev) => ({ ...prev, name: e.target.value }));
          }}
          className="border py-[0.7rem] px-4 rounded-[8px] text-[#333] font-[500] capitalize text-[14px]"
        />
      </div>
      <div className="grid grid-cols-2 gap-[20px]">
        <div className="space-y-2 flex flex-col">
          <label
            htmlFor="timeZone"
            className="text-[#333] text-[14px] font-[500]"
          >
            Time Zone <Required />
          </label>
          <select
            name="timeZone"
            id="timeZone"
            required
            // defaultValue={inputData?.finance?.timeZone ?? "USD"}
            value={inputData?.finance?.timeZone}
            onChange={handleFinanceChange}
            className="border py-3 px-4 rounded-[8px] text-[#333] font-[500] capitalize text-[14px]"
          >
            <option value="">Select Time Zone</option>
            {Intl?.supportedValuesOf("timeZone")?.map((d, i) => (
              <option value={d} key={i}>
                {d}
              </option>
            ))}
          </select>
        </div>
        <div className="space-y-2 flex flex-col">
          <label
            htmlFor="currency"
            className="text-[#333] text-[14px] font-[500]"
          >
            Currency <Required />
          </label>
          <select
            name="currency"
            id="currency"
            required
            defaultValue={inputData?.finance?.currency ?? "USD"}
            value={inputData?.finance?.currency}
            onChange={handleFinanceChange}
            className="border py-3 px-4 rounded-[8px] text-[#333] font-[500] capitalize text-[14px]"
          >
            <option value="">Select Currency</option>
            {currencies?.map((d, i) => (
              <option value={d?.value} key={i}>
                {d?.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-[20px]">
        <div className="space-y-2 flex flex-col">
          <label
            htmlFor="taxIDType"
            className="text-[#333] text-[14px] font-[500]"
          >
            Tax ID Type <Required />
          </label>
          <input
            type="text"
            name="taxIDType"
            id="taxIDType"
            required
            value={inputData?.finance?.taxIDType}
            onChange={handleFinanceChange}
            className="border py-[0.7rem] px-4 rounded-[8px] text-[#333] font-[500] capitalize text-[14px]"
            list="taxIDTypes"
            placeholder="Enter TAX Type"
          />

          <datalist id="taxIDTypes">
            {taxIdTypes?.map((d, i) => (
              <option value={d} key={i}>
                {d}
              </option>
            ))}
          </datalist>
        </div>
        <div className="space-y-2 flex flex-col">
          <label htmlFor="taxID" className="text-[#333] text-[14px] font-[500]">
            Tax ID <Required />
          </label>
          <input
            type="text"
            required
            name="taxID"
            value={inputData?.finance?.taxID}
            onChange={handleFinanceChange}
            className="border py-[0.7rem] px-4 rounded-[8px] text-[#333] font-[500] capitalize text-[14px]"
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-[20px]">
        <div className="space-y-2 flex flex-col">
          <label
            htmlFor="expectedDropOfRate"
            className="text-[#333] text-[14px] font-[500]"
          >
            Expected Drop of Rate (%) <Required />
          </label>
          <input
            type="number"
            placeholder="10"
            required
            name="expectedDropOfRate"
            value={inputData?.projectSettings?.expectedDropOfRate}
            onChange={handleProjectSettingChange}
            className="border py-[0.7rem] placeholder:text-gray-300 px-4 rounded-[8px] text-[#333] font-[500] capitalize text-[14px]"
          />
        </div>
        <div className="space-y-2 flex flex-col">
          <label
            htmlFor="expectedQuotaRate"
            className="text-[#333] text-[14px] font-[500]"
          >
            Expected Quota Rate (%) <Required />
          </label>
          <input
            type="number"
            placeholder="20"
            required
            name="expectedQuotaRate"
            value={inputData?.projectSettings?.expectedQuotaRate}
            onChange={handleProjectSettingChange}
            className="border py-[0.7rem] placeholder:text-gray-300  px-4 rounded-[8px] text-[#333] font-[500] capitalize text-[14px]"
          />
        </div>
      </div>
    </div>
  );
};
const AddressDetails = ({ addressDetails, setAddressDetails }) => {
  const [countryStateCity, setCountryStateCity] = useState({
    country: [],
    state: [],
    city: [],
    countryLoading: true,
    stateLoading: true,
    cityLoading: true,
  });
  function handleChange(e) {
    const { name, value } = e.target;
    setAddressDetails({ ...addressDetails, [name]: value });
  }
  async function getAllCountry(e) {
    try {
      const countryData = await (
        await fetch("https://api.countrystatecity.in/v1/countries", {
          method: "GET",
          headers: {
            "X-CSCAPI-KEY": process.env.REACT_APP_COUNTRY_STATE_API_KEY,
          },
          redirect: "follow",
        })
      ).json();
      if (addressDetails?.countryCode) {
        getAllCountryStates(addressDetails?.countryCode);
      }
      if (addressDetails?.stateCode) {
        getAllStatesCities(addressDetails?.stateCode);
      }
      setCountryStateCity((prev) => ({
        ...prev,
        country: countryData,
        countryLoading: false,
      }));
    } catch (error) {
      console.log(error);
      setCountryStateCity((prev) => ({ ...prev, countryLoading: false }));
    }
  }
  async function getAllCountryStates(country) {
    try {
      setCountryStateCity((prev) => ({ ...prev, stateLoading: true }));
      const statesData = await (
        await fetch(
          `https://api.countrystatecity.in/v1/countries/${country}/states`,
          {
            method: "GET",
            headers: {
              "X-CSCAPI-KEY": process.env.REACT_APP_COUNTRY_STATE_API_KEY,
            },
            redirect: "follow",
          }
        )
      ).json();
      setCountryStateCity((prev) => ({
        ...prev,
        state: statesData?.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),
        stateLoading: false,
      }));
    } catch (error) {
      console.log(error);
      setCountryStateCity((prev) => ({ ...prev, stateLoading: false }));
    }
  }
  async function getAllStatesCities(state) {
    try {
      setCountryStateCity((prev) => ({ ...prev, cityLoading: true }));
      const citiesData = await (
        await fetch(
          `https://api.countrystatecity.in/v1/countries/${addressDetails?.countryCode}/states/${state}/cities`,
          {
            method: "GET",
            headers: {
              "X-CSCAPI-KEY": process.env.REACT_APP_COUNTRY_STATE_API_KEY,
            },
            redirect: "follow",
          }
        )
      ).json();
      setCountryStateCity((prev) => ({
        ...prev,
        city: citiesData,
        cityLoading: false,
      }));
    } catch (error) {
      console.log(error);
      setCountryStateCity((prev) => ({ ...prev, cityLoading: false }));
    }
  }
  useEffect(() => {
    getAllCountry();
  }, []);
  // console.log(countryStateCity);
  // console.log(addressDetails);
  return (
    <div className="space-y-[0.8rem] px-2 ">
      <div className="space-y-2 flex flex-col">
        <label htmlFor="street1" className="text-[#333] text-[14px] font-[500]">
          Street 1 <Required />
        </label>
        <input
          type="text"
          required
          onChange={handleChange}
          value={addressDetails?.street1}
          name="street1"
          className="border py-[0.7rem] px-4 rounded-[8px] text-[#333] font-[500] capitalize text-[14px]"
        />
      </div>
      <div className="space-y-2 flex flex-col">
        <label htmlFor="street2" className="text-[#333] text-[14px] font-[500]">
          Street 2
        </label>
        <input
          type="text"
          name="street2"
          onChange={handleChange}
          value={addressDetails?.street2}
          className="border py-[0.7rem] px-4 rounded-[8px] text-[#333] font-[500] capitalize text-[14px]"
        />
      </div>
      <div className="grid grid-cols-2 gap-4 ">
        <div className="space-y-2 flex flex-col">
          <label
            htmlFor="Country"
            className="text-[#333] text-[14px] font-[500]"
          >
            Country <Required />
          </label>
          <select
            name="country"
            id="Country"
            required
            value={addressDetails?.countryCode}
            onChange={(e) => {
              if (e.target.value !== "") {
                setCountryStateCity((prev) => ({
                  ...prev,
                  city: [],
                  state: [],
                }));
                getAllCountryStates(e.target.value);
                setAddressDetails((prev) => ({
                  ...prev,
                  country: e.target.options[e.target.selectedIndex].text,
                  countryCode: e.target.value,
                  state: "",
                  stateCode: "",
                  city: "",
                }));
              }
            }}
            className="border py-3 px-4 rounded-[8px] text-[#333] font-[500] capitalize text-[14px]"
          >
            <option value="">Select Country</option>
            {countryStateCity?.countryLoading ? (
              <option value="" disabled>
                Loading Country..
              </option>
            ) : (
              countryStateCity?.country?.map((d, i) => (
                <option value={d?.iso2} key={i}>
                  {d?.name}
                </option>
              ))
            )}
          </select>
        </div>
        <div className="space-y-2 flex flex-col">
          <label htmlFor="state" className="text-[#333] text-[14px] font-[500]">
            State <Required />
          </label>
          <select
            name="state"
            id="state"
            required
            value={addressDetails?.stateCode}
            onChange={(e) => {
              if (e.target.value !== "") {
                getAllStatesCities(e.target.value);
                setAddressDetails((prev) => ({
                  ...prev,
                  state: e.target.options[e.target.selectedIndex].text,
                  stateCode: e.target.value,
                  city: "",
                }));
              }
            }}
            className="border py-3 px-4 rounded-[8px] text-[#333] font-[500] capitalize text-[14px]"
          >
            <option value="">Select State</option>
            {countryStateCity?.stateLoading ? (
              <option value="" disabled>
                Loading States..
              </option>
            ) : countryStateCity?.state?.length ? (
              countryStateCity?.state?.map((d, i) => (
                <option value={d?.iso2} key={i}>
                  {d?.name}
                </option>
              ))
            ) : (
              <option value="" disabled>
                No States found..
              </option>
            )}
          </select>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 ">
        <div className="space-y-2 flex flex-col">
          <label htmlFor="city" className="text-[#333] text-[14px] font-[500]">
            City <Required />
          </label>
          <select
            name="city"
            id="city"
            required
            value={addressDetails?.city}
            onChange={(e) => {
              if (e.target.value !== "") {
                setAddressDetails((prev) => ({
                  ...prev,
                  city: e.target.options[e.target.selectedIndex].text,
                }));
              }
            }}
            className="border py-3 px-4 rounded-[8px] text-[#333] font-[500] capitalize text-[14px]"
          >
            <option value="">Select City</option>
            {countryStateCity?.cityLoading ? (
              <option value="" disabled>
                Loading Cities..
              </option>
            ) : countryStateCity?.city?.length ? (
              countryStateCity?.city?.map((d, i) => (
                <option value={d?.iso2} key={i}>
                  {d?.name}
                </option>
              ))
            ) : (
              <option value="" disabled>
                No Cities found..
              </option>
            )}
          </select>
        </div>
        <div className="space-y-2 flex flex-col">
          <label
            htmlFor="zipCode"
            className="text-[#333] text-[14px] font-[500]"
          >
            Zip Code <Required />
          </label>
          <input
            type="text"
            required
            name="zipCode"
            value={addressDetails?.zipCode}
            onChange={handleChange}
            className="border py-[0.7rem] px-4 rounded-[8px] text-[#333] font-[500] capitalize text-[14px]"
          />
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
