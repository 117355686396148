import { useEffect } from 'react';

function SignUpRedirect() {
  useEffect(() => {
    window.location.href = 'https://accounts.miratsgroup.com/signup';
  }, []);

  return null;
}

export default SignUpRedirect;
