import React, { useState } from "react";
import TransactionFailureIcon from "../../assets/transactionFailure.svg";

const Failure = () => {
  const [transactionDetails, setTransactionDetails] = useState({});
  return (
    <div className="min-h-screen flex items-center justify-center ">
      <div className="w-[40%] shadow-md border rounded-[24px] p-[32px] flex flex-col bg-white items-center justify-center gap-[20px]   ">
        <div className="flex items-center flex-col justify-center space-y-[20px]">
          <img
            src={TransactionFailureIcon}
            alt="TransactionFailureIcon"
            className="w-[120px] h-[120px] object-contain rounded-full "
          />
          <div className="space-y-[10px]">
            <h1 className="text-[#F79009] font-[700] text-[26px] text-center">
              Payment Issue
            </h1>
            <p className="text-[#475467] font-[400] text-[14px] text-center">
              We are sorry to inform that we were not able to execute the
              payment because of some technical issue. Please try again after
              some time.
            </p>
          </div>
        </div>

        <div className="mt-[12px] w-full flex items-center justify-between gap-[18px]">
          <button
            className="p-[9.3px_15.5px] text-center border border-[#101828] rounded-[6px] text-[#101828] font-[500] w-1/2"
            onClick={() => {
              window.location.replace(window.location.origin);
            }}
          >
            Back to Dashboard
          </button>
          <button
            className="p-[9.3px_15.5px] border border-[#F79009] bg-[#F79009] rounded-[6px] text-[#FFFFFF] font-[500] w-1/2"
            onClick={(e) => {
              window.location.replace(
                `${window.location.origin}/invoice-billing`
              );
            }}
          >
            Try Again
          </button>
        </div>
        <h1 className="text-[#00000080] text-[14px]">
          Questions?{" "}
          <a
            href="mailto:diysupport@miratsinsights.com"
            className="text-[#1570EF] font-[400] underline decoration-[#1570EF] underline-offset-4 cursor-pointer"
          >
            {" "}
            Send us an email.
          </a>
        </h1>
      </div>
    </div>
  );
};

export default Failure;
