import React, { useState } from "react";
import { getDashboardInvoiceGraphData } from "../../../Actions/DashboardActions";
import { useQuery } from "@tanstack/react-query";
import { Doughnut } from "react-chartjs-2";
import InvoiceGraphLoader from "./InvoiceGraphLoader";
import NoData from "../../../components/NoData";
const InvoiceOverviewGraph = ({ user, allMonthsAndYear }) => {
  const [invoiceOverviewYear, setInvoicesOverviewYear] = useState(
    new Date()?.getFullYear()
  );
  // invoice graph data
  const {
    data: invoicesGraphData,
    isLoading: invoicesGraphDataLoading,
    isError: invoicesGraphDataError,
  } = useQuery({
    queryFn: () =>
      getDashboardInvoiceGraphData(user?.organizationId, invoiceOverviewYear),
    queryKey: [
      "dashboardInvoiceGraph",
      user?.organizationId,
      invoiceOverviewYear,
    ],
    enabled: !!user?.organizationId,
    staleTime: 10000,
    cacheTime: 15000,
  });
  // console.log(
  //   invoicesGraphData,
  //   invoicesGraphDataLoading,
  //   invoicesGraphDataError
  // );
  const data = {
    labels: [],
    datasets: [
      {
        label: "Invoices",
        data: [
          invoicesGraphData?.unpaidInvoiceCount,
          invoicesGraphData?.paidInvoiceCount,
        ],
        borderColor: ["rgba(255,206,86,0.2)"],
        backgroundColor: ["#EE799F", "#0226BEB2"],
        pointBackgroundColor: "#000000b3",
        hoverBackgroundColor: ["#f5588a", "#4a68ee"],
        borderWidth: 1,
        cutoutPercentage: 10,
        cutout: 75,
        hoverOffset: 4,
        spacing: 6,
        borderRadius: 15,
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        text: "",
        display: false,
        color: "blue",
        font: {
          size: 5,
        },
        padding: {
          top: 10,
          bottom: 10,
        },
        borderWidth: 1,
        responsive: true,
        animation: {
          animateScale: true,
        },
      },
    },
  };
  return (
    <div className="w-[35%] dashboardShadow h-[360px]  ">
      <div className="mx-5 py-5 border-b border-[#E0E0E0] flex items-center justify-between">
        <h1 className="text-[#333] text-[16px] font-[500] inter">
          Invoice Overview
        </h1>
        <div className="flex items-center gap-[33px]">
          <select
            className="text-[#0F2552] text-[12px] font-[600] px-3"
            value={invoiceOverviewYear}
            name="year"
            onChange={(e) => setInvoicesOverviewYear(e.target.value)}
          >
            <option value="">Year</option>
            {allMonthsAndYear?.years?.map((d) => (
              <option value={d}>{d}</option>
            ))}
          </select>
        </div>
      </div>
      {invoicesGraphDataLoading ? (
        <InvoiceGraphLoader />
      ) : invoicesGraphDataError ? (
        <div className="h-[300px] flex items-center justify-center">
          <h1 className="text-[#e12121] text-[14px] ml-8 font-[300] tracking-wide flex gap-[10px] items-center">
            Something went wrong !
          </h1>
        </div>
      ) : Object?.keys(invoicesGraphData || {})?.length ? (
        <div className="h-[300px]  flex items-center justify-between">
          <div className="mx-5 flex flex-col justify-between  h-[100%] ">
            <div className=" mt-5">
              <p className="text-[#4D4D4D80] text-[16px] font-[500]">
                Total Invoices
              </p>
              <h3 className="text-[#000] text-[18px] font-[700]">
                {invoicesGraphData?.totalInvoiceCount || 0}
              </h3>
            </div>
            <div className=" mb-5 flex flex-col gap-2">
              <div className="flex items-center ">
                <div className="w-[35px] h-[23px] rounded-[9px] bg-[#4E67D2]"></div>
                <h1 className="ml-3 text-[#000000b3] text-[18px] font-[700]">
                  {Number(invoicesGraphData?.paidRate || 0)?.toFixed(1)}%
                </h1>
                <p className="ml-2 text-[#00000080] text-[16px] font-[500]">
                  Paid
                </p>
              </div>
              <div className="flex items-center ">
                <div className="w-[35px] h-[23px] rounded-[9px] bg-[#EE799F]"></div>
                <h1 className="ml-3 text-[#000000b3] text-[18px] font-[700]">
                  {Number(invoicesGraphData?.unpaidRate || 0)?.toFixed(1)}%
                </h1>
                <p className="ml-2 text-[#00000080] text-[16px] font-[500]">
                  Unpaid
                </p>
              </div>
            </div>
          </div>
          <div className="w-[60%] h-[100%]  flex items-center justify-center overflow-hidden mr-4">
            <Doughnut data={data} options={options} />
          </div>
        </div>
      ) : (
        <div className="h-[300px] flex items-center justify-center">
          <NoData msg={"Try Changing the year"} />
        </div>
      )}
    </div>
  );
};

export default InvoiceOverviewGraph;
