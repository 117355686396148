import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import axios from "axios";
import { BASE_URL } from "../../../Utlils";

import CreatableSelect from "react-select/creatable";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    marginRight: 0,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#0226BE" : "#0226BE",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const components = {
  DropdownIndicator: null,
};
const createOption = (label) => ({
  label: label?.toLowerCase(),
  value: label?.toLowerCase(),
});
const MyNotification = ({ user }) => {
  const { organization, getOrganization, setSnackbar } = useGlobalContext();
  const [notificationInputs, setNotificationInputs] = useState({
    financeEmails: [],
    complianceEmails: [],
    projectEmails: [],
    projectUpdatesEmails: [],
    accountRelatedChangesEmails: [],
  });
  const updateNotifications = async (inputData) => {
    try {
      let data = await axios.put(
        `${BASE_URL}/company/update-notifications/${organization?.id}`,
        { notifications: inputData }
      );
      setSnackbar({
        open: true,
        msg: "Notification updated successfully !",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        msg: "Something went wrong !",
        severity: "error",
      });
    } finally {
      getOrganization(organization?.id);
    }
  };
  const handleKeyDown = (event, name) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!notificationInputs?.[`${name}Input`]) return;
    if (!emailRegex.test(notificationInputs?.[`${name}Input`])) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setNotificationInputs((prev) => ({
          ...prev,
          [name]: [
            ...prev?.[name],
            createOption(notificationInputs?.[`${name}Input`]),
          ],
          [`${name}Input`]: "",
        }));
        event.preventDefault();
    }
  };
  const handleUpdateContacts = async (e) => {
    try {
      let data = await axios.put(
        `${BASE_URL}/company/update-contacts/${organization?.id}`,
        {
          contacts: {
            financeEmails: notificationInputs?.financeEmails?.map(
              (d) => d?.value
            ),
            complianceEmails: notificationInputs?.complianceEmails?.map(
              (d) => d?.value
            ),
            projectEmails: notificationInputs?.projectEmails?.map(
              (d) => d?.value
            ),
            projectUpdatesEmails: notificationInputs?.projectUpdatesEmails?.map(
              (d) => d?.value
            ),
            accountRelatedChangesEmails:
              notificationInputs?.accountRelatedChangesEmails?.map(
                (d) => d?.value
              ),
          },
        }
      );
      setSnackbar({
        open: true,
        msg: "Contacts updated successfully !",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        msg: "Something went wrong !",
        severity: "error",
      });
    } finally {
      getOrganization(organization?.id);
    }
  };
  const handleSelectOnchange = (newValue, name) => {
    setNotificationInputs((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };
  const handleSelectInputChange = (newValue, name) => {
    setNotificationInputs((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  };
  useEffect(() => {
    if (organization) {
      setNotificationInputs((prev) => ({
        ...prev,
        pushNotifications: organization?.notifications?.pushNotifications,
        emailNotifications: organization?.notifications?.emailNotifications,
        financeEmails: organization?.contacts?.financeEmails?.map((d) => ({
          label: d?.toLowerCase(),
          value: d?.toLowerCase(),
        })),
        complianceEmails: organization?.contacts?.complianceEmails?.map(
          (d) => ({ label: d?.toLowerCase(), value: d?.toLowerCase() })
        ),
        projectEmails: organization?.contacts?.projectEmails?.map((d) => ({
          label: d?.toLowerCase(),
          value: d?.toLowerCase(),
        })),
        projectUpdatesEmails: organization?.contacts?.projectUpdatesEmails?.map(
          (d) => ({
            label: d?.toLowerCase(),
            value: d?.toLowerCase(),
          })
        ),
        accountRelatedChangesEmails:
          organization?.contacts?.accountRelatedChangesEmails?.map((d) => ({
            label: d?.toLowerCase(),
            value: d?.toLowerCase(),
          })),
      }));
    }
  }, [Object.keys(organization || {})?.length]);
  // console.log("user=>", user);
  // console.log("organization=>", organization);
  // console.log("notificationInputs=>", notificationInputs);
  return (
    <div className="w-full flex flex-col gap-[40px] h-full py-5">
      <div className="flex flex-col gap-[25px]">
        <div className="pb-8 flex items-center justify-between  border-b border-[#E0E0E0] ">
          <div className="flex flex-col gap-[15px] justify-center">
            <h1 className="text-[#333333] text-[28px] font-[500]">
              My Notifications
            </h1>
            <p>Get notification from Mirats Quanto on your devices</p>
          </div>
        </div>
        <div className="pb-5 flex items-center justify-between  border-b border-[#E0E0E0] ">
          <div className="flex flex-col gap-[15px] justify-center">
            <h1 className="text-[#333333] text-[18px] font-[500]">
              Push Notification
            </h1>
            <p className="text-[#666] text-[16px]">
              Receive push notifications on mentions and comments via your web
              browser or app.
            </p>
          </div>
          <FormControlLabel
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={notificationInputs?.pushNotifications || false}
                onChange={(e) => {
                  if (user?.role === "admin") {
                    setNotificationInputs((prev) => ({
                      ...prev,
                      pushNotifications: e.target.checked,
                    }));
                    updateNotifications({
                      ...notificationInputs,
                      pushNotifications: e.target.checked,
                    });
                  } else {
                    setSnackbar({
                      open: true,
                      msg: "Only admin can change these details",
                      severity: "warning",
                    });
                  }
                }}
              />
            }
          />
        </div>
        <div className="pb-5 flex items-center justify-between   ">
          <div className="flex flex-col gap-[15px] justify-center">
            <h1 className="text-[#333333] text-[18px] font-[500]">
              Email Notification
            </h1>
            <p className="text-[#666] text-[16px]">
              Receive email notifications on important updates and alerts
            </p>
          </div>
          <FormControlLabel
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={notificationInputs?.emailNotifications || false}
                onChange={(e) => {
                  setNotificationInputs((prev) => ({
                    ...prev,
                    emailNotifications: e.target.checked,
                  }));
                  updateNotifications({
                    ...notificationInputs,
                    emailNotifications: e.target.checked,
                  });
                }}
              />
            }
          />
        </div>
      </div>
      <div className="flex flex-col ">
        <div className="pb-10 flex items-center justify-between  border-b border-[#E0E0E0] ">
          <div className="flex flex-col gap-[15px] justify-center">
            <h1 className="text-[#333333] text-[24px] font-[500]">
              Email Address for Communication
            </h1>
            <div className="space-y-1">
              <p>
                Please provide the email addresses for respective department so
                that we can communicate with you clearly.
              </p>
              <p className="text-[#666] font-[500] text-[14px]">
                ( Press 'Enter' or 'Tab' after entering email )
              </p>
            </div>
          </div>
        </div>
        <div className="py-5 grid grid-cols-2 gap-[30px] items-center justify-between  ">
          <div className=" space-y-3">
            <h1 className="text-[#333333] text-[16px] font-[500]">
              Finance Team Emails
            </h1>
            <CreatableSelect
              components={components}
              inputValue={notificationInputs?.financeEmailsInput}
              isClearable
              isMulti
              isDisabled={user?.role !== "admin"}
              menuIsOpen={false}
              onChange={(newValue) =>
                handleSelectOnchange(newValue, "financeEmails")
              }
              onInputChange={(newValue) =>
                handleSelectInputChange(newValue, "financeEmailsInput")
              }
              onKeyDown={(e) => handleKeyDown(e, "financeEmails")}
              placeholder={
                <p className="text-[14px]">Type an email and press enter...</p>
              }
              value={notificationInputs?.financeEmails}
            />
          </div>
          <div className=" space-y-3">
            <h1 className="text-[#333333] text-[16px] font-[500]">
              Compliances Emails
            </h1>

            <CreatableSelect
              components={components}
              inputValue={notificationInputs?.complianceEmailsInput}
              isClearable
              isDisabled={user?.role !== "admin"}
              isMulti
              menuIsOpen={false}
              onChange={(newValue) =>
                handleSelectOnchange(newValue, "complianceEmails")
              }
              onInputChange={(newValue) =>
                handleSelectInputChange(newValue, "complianceEmailsInput")
              }
              onKeyDown={(e) => handleKeyDown(e, "complianceEmails")}
              placeholder={
                <p className="text-[14px]">Type an email and press enter...</p>
              }
              value={notificationInputs?.complianceEmails}
            />
          </div>
          <div className=" space-y-3">
            <h1 className="text-[#333333] text-[16px] font-[500]">
              Project Emails
            </h1>
            <CreatableSelect
              components={components}
              inputValue={notificationInputs?.projectEmailsInput}
              isClearable
              isDisabled={user?.role !== "admin"}
              isMulti
              menuIsOpen={false}
              onChange={(newValue) =>
                handleSelectOnchange(newValue, "projectEmails")
              }
              onInputChange={(newValue) =>
                handleSelectInputChange(newValue, "projectEmailsInput")
              }
              onKeyDown={(e) => handleKeyDown(e, "projectEmails")}
              placeholder={
                <p className="text-[14px]">Type an email and press enter...</p>
              }
              value={notificationInputs?.projectEmails}
            />
          </div>
          <div className=" space-y-3">
            <h1 className="text-[#333333] text-[16px] font-[500]">
              Business Related Emails
            </h1>
            <CreatableSelect
              components={components}
              inputValue={notificationInputs?.projectUpdatesEmailsInput}
              isClearable
              isDisabled={user?.role !== "admin"}
              isMulti
              menuIsOpen={false}
              onChange={(newValue) =>
                handleSelectOnchange(newValue, "projectUpdatesEmails")
              }
              onInputChange={(newValue) =>
                handleSelectInputChange(newValue, "projectUpdatesEmailsInput")
              }
              onKeyDown={(e) => handleKeyDown(e, "projectUpdatesEmails")}
              placeholder={
                <p className="text-[14px]">Type an email and press enter...</p>
              }
              value={notificationInputs?.projectUpdatesEmails}
            />
          </div>
          <div className=" space-y-3">
            <h1 className="text-[#333333] text-[16px] font-[500]">
              Account Related Changes
            </h1>
            <CreatableSelect
              components={components}
              inputValue={notificationInputs?.accountRelatedChangesEmailsInput}
              isClearable
              isDisabled={user?.role !== "admin"}
              isMulti
              menuIsOpen={false}
              onChange={(newValue) =>
                handleSelectOnchange(newValue, "accountRelatedChangesEmails")
              }
              onInputChange={(newValue) =>
                handleSelectInputChange(
                  newValue,
                  "accountRelatedChangesEmailsInput"
                )
              }
              onKeyDown={(e) => handleKeyDown(e, "accountRelatedChangesEmails")}
              placeholder={
                <p className="text-[14px]">Type an email and press enter...</p>
              }
              value={notificationInputs?.accountRelatedChangesEmails}
            />
          </div>
          <div className="flex justify-end items-center py-4 col-span-2">
            <button
              type="button"
              onClick={handleUpdateContacts}
              disabled={user?.role !== "admin"}
              style={
                user?.role === "admin"
                  ? { cursor: "pointer" }
                  : { cursor: "not-allowed" }
              }
              className={`${
                user?.role === "admin" ? " bg-primary" : "bg-primary/50"
              } border text-white py-2 px-4 rounded-[8px]`}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyNotification;
