import axios from "axios";
import { BASE_URL } from "../../Utlils";

// get the survey count by statuses
export const getSurveyCount = async (id) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/survey/${id}/survey-counts`);
    return data;
  } catch (error) {
    console.log("Unable to get survey count by status");
    throw error;
  }
};
// archive the surveys
export const archiveSurvey = async (selectRows) => {
  try {
    const requests = selectRows.map((row) =>
      axios.put(`${BASE_URL}/survey?id=${row?._id}`, {
        surveyStatus: "archived",
      })
    );
    const responses = await Promise.all(requests);
    return responses.map((response) => response.data);
  } catch (error) {
    console.log("Unable to archive survey");
    throw error;
  }
};
// add new survey
export const addNewSurvey = async (inputData, user) => {
  try {
    if (
      !Object.keys(inputData || {})?.length &&
      !Object.keys(inputData || {})?.length
    ) {
      throw new Error("Missing Required fields");
    }
    const B2CQuestions = [
      {
        question: "656d6d54f1cdf363aab57950",
        validOptions: [
          { from: 18, to: inputData?.setup?.targetAudience == "B2C" ? 75 : 60 },
        ],
        displayOptions: [],
        status: true,
      },
      {
        question: "656d6d54f1cdf363aab57954",
        validOptions: ["Male", "Female"],
        displayOptions: ["Male", "Female", "others"],
        status: true,
        condition: {
          min: "",
          max: "",
        },
      },
    ];
    const B2BQuestions = {
      question: "656d6d55f1cdf363aab579c0",
      validOptions: [
        "Employed full-time",
        "Employed part-time",
        "Self-employed full-time",
        "Self-employed part-time",
        "Active military",
        "Inactive military/Veteran",
        "Temporarily unemployed",
        "Full-time homemaker",
        "Retired",
        "Student",
        "Disabled",
        "Prefer not to answer",
      ],
      displayOptions: [
        "Employed full-time",
        "Employed part-time",
        "Self-employed full-time",
        "Self-employed part-time",
        "Active military",
        "Inactive military/Veteran",
        "Temporarily unemployed",
        "Full-time homemaker",
        "Retired",
        "Student",
        "Disabled",
        "Prefer not to answer",
      ],
      status: true,
      condition: {
        min: "",
        max: "",
      },
    };
    let res = await axios.post(`${BASE_URL}/survey`, {
      ...inputData,
      setup: {
        ...inputData?.setup,
        businessUnit: user?.organizationName,
      },
      createdBy: user?.organizationId,
      qualification:
        inputData?.setup?.targetAudience == "B2C"
          ? B2CQuestions
          : [...B2CQuestions, B2BQuestions],
    });
    if (inputData?.surveyGroup) {
      await axios.put(
        `${BASE_URL}/survey-group/add-survey?sid=${res?.data?._id}&sgId=${inputData?.surveyGroup}`
      );
    }
    return res.data;
  } catch (error) {
    console.log("Unable to add new survey");
    throw error;
  }
};
export const editSurveyAction = async (inputData) => {
  try {
    if (!Object.keys(inputData || {}).length) {
      throw new Error("Missing Required fields");
    }

    const requests = [
      axios.put(`${BASE_URL}/survey?id=${inputData._id}`, inputData),
    ];

    if (
      inputData.prevSurveyGroup &&
      inputData.prevSurveyGroup !== inputData.surveyGroup
    ) {
      requests.push(
        axios.put(
          `${BASE_URL}/survey-group/remove-survey-and-add-new?oSgId=${inputData.prevSurveyGroup}&newSgId=${inputData.surveyGroup}&sid=${inputData._id}`
        )
      );
    }

    const [res] = await Promise.all(requests);
    return res.data;
  } catch (error) {
    console.log("Unable to edit  survey");
    throw error;
  }
};
export const getAllSurveyStats = async (
  id,
  reset,
  tab,
  page,
  rows,
  industry,
  projectManager,
  studyType,
  country,
  searchText
) => {
  try {
    let data = await axios(
      `${BASE_URL}/session/survey-sessions?organization=${id}&reset=${reset}&status=${tab}&page=${
        Number(page) + 1
      }&rows=${rows}&industry=${industry}&projectManager=${projectManager}&studyType=${studyType}&country=${country}&searchText=${searchText}`
    );
    let newData = data.data;
    return newData;
  } catch (error) {
    // console.log(error);
    console.log("something went wrong while fetching the survey stats");
    throw error;
  }
};
