import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useGlobalContext } from "../../../../Contexts/GlobalContext";
import axios from "axios";
import { BASE_URL } from "../../../../Utlils";
import { CircularProgress } from "@mui/material";
import Required from "../../../../components/Required";
import { getValueByCountryRateAndLOI } from "./helperData";

const Details = ({ data, getSingleSurvey, singleSurveyStats }) => {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const {
    setSnackbar,
    teamMembers,
    studyTypesData,
    industryData,
    allLanguages,
    countryData,
  } = useGlobalContext();
  const [surveyData, setSurveyData] = useState({});

  useEffect(() => {
    setSurveyData({
      ...data,
    });
  }, [Object.keys(data || {})?.length]);
  // console.log(data);
  function transformUrl(url) {
    const pattern =
      /(r|respID|respondentId|respondentID|pid|pID|pId)=((?:[{}[\]<>@%]+)?[^&]*)/g;
    return url.replace(pattern, (match, p1, p2) => {
      return `${p1}=[%rid%]`;
    });
  }
  // console.log(surveyData?.allocation?.[0]?.allocation?.number);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios
      .put(`${BASE_URL}/survey?id=${data?._id}`, {
        ...surveyData,
        setup: {
          ...surveyData?.setup,
          liveUrl: surveyData?.setup?.liveUrl
            ? transformUrl(surveyData?.setup?.liveUrl)
            : "",
          testUrl: surveyData?.setup?.testUrl
            ? transformUrl(surveyData?.setup?.testUrl)
            : "",
        },
        allocation: [
          {
            ...surveyData?.allocation?.[0],
            allocation: {
              number:
                surveyData?.expectedMatrix?.expectedCompletes ||
                surveyData?.allocation?.[0]?.allocation?.number,
              percentage: 100,
            },
          },
        ],
      })
      .then((res) => {
        setLoading(false);
        // setSurveyData({});
        getSingleSurvey();
        // navigate("/surveys");
        setSnackbar((prev) => ({
          ...prev,
          open: true,
          msg: "Survey Details Updated !",
          severity: "success",
        }));
      })
      .catch((er) => {
        setLoading(false);
        setSnackbar({
          msg: "Something went wrong !",
          open: true,
          severity: "danger",
        });
      });
  };
  const renderComponent = (e) => {
    switch (tab) {
      case 0:
        return (
          <SetupRequirement
            surveyData={surveyData}
            setSurveyData={setSurveyData}
            countryData={countryData}
            loading={loading}
            studyTypesData={studyTypesData}
            industryData={industryData}
            allLanguages={allLanguages}
            setSnackbar={setSnackbar}
            singleSurveyStats={singleSurveyStats}
          />
        );
      case 1:
        return (
          <ExpectedMatrix
            surveyData={surveyData}
            setSurveyData={setSurveyData}
            loading={loading}
            setSnackbar={setSnackbar}
          />
        );
      case 2:
        return (
          <Peoples
            surveyData={surveyData}
            setSurveyData={setSurveyData}
            teamMembers={teamMembers}
            loading={loading}
          />
        );
      case 3:
        return (
          <ProjectSettings
            loading={loading}
            surveyData={surveyData}
            setSurveyData={setSurveyData}
            getSingleSurvey={getSingleSurvey}
            setSnackbar={setSnackbar}
          />
        );
      default:
        return <h1>No Page</h1>;
    }
  };
  // console.log("singleSurveyStats=>", singleSurveyStats);
  // console.log("surveyData=>", surveyData);
  // console.log("countryData=>", countryData);

  return (
    <div className="flex flex-col w-full gap-[48px] ">
      <div className="flex flex-col gap-[48px] ">
        <div className="w-full tableBorder flex items-center justify-between px-10 py-3 mt-[5px]">
          {[
            "Setup Requirement",
            "Expected Metrics & Data",
            "Peoples",
            "Project Settings",
          ]?.map((data, ind) => (
            <h1
              key={ind}
              className={`text-[#000] text-[16px] font-[400] cursor-pointer transition-all ease-linear ${
                tab == ind &&
                `text-[#0226BE] font-[700] underline decoration-2 decoration-[#0226BE] underline-offset-[17px]`
              }`}
              onClick={(e) => {
                setTab(ind);
              }}
            >
              {data}
            </h1>
          ))}
        </div>
        <form onSubmit={handleSubmit}>{renderComponent()}</form>
      </div>
    </div>
  );
};
const SetupRequirement = ({
  surveyData,
  setSurveyData,
  loading,
  industryData,
  studyTypesData,
  allLanguages,
  setSnackbar,
  countryData,
  singleSurveyStats,
}) => {
  const handleChange = (e) => {
    let { name, value } = e.target;
    // console.log(name, value);
    setSurveyData((prev) => ({
      ...prev,
      setup: {
        ...prev?.setup,
        [name]: value,
      },
    }));
  };
  const dynamicCPISuggestion =
    (10 / 100) * surveyData?.expectedMatrix?.expectedCompletes <
    singleSurveyStats?.completed;

  let CPIValue = useMemo(
    () =>
      dynamicCPISuggestion
        ? getValueByCountryRateAndLOI(
            surveyData?.setup?.country,
            Math.ceil(Number(singleSurveyStats?.incidenceRate)),
            Math.ceil(Number(singleSurveyStats?.avgCompletionLoi)),
            surveyData?.setup?.targetAudience || "B2C"
          )
        : getValueByCountryRateAndLOI(
            surveyData?.setup?.country,
            surveyData?.expectedMatrix?.expectedIncidenceRate,
            surveyData?.expectedMatrix?.expectedCompletionLOI,
            surveyData?.setup?.targetAudience
          ),
    [
      dynamicCPISuggestion,
      surveyData?.setup?.country,
      surveyData?.expectedMatrix?.expectedIncidenceRate,
      surveyData?.expectedMatrix?.expectedCompletionLOI,
      surveyData?.setup?.targetAudience,
      surveyData?.setup?.surveyCpi,
    ]
  );
  const getRandomNoticeLine = useCallback((value) => {
    let arr = [
      `For optimal conversion rates and audience engagement, ensure that your CPI (Country, LOI, IR) value meets or exceeds $${value}`,
      `To achieve better conversion and reach your target audience effectively, maintain a minimum CPI value of $${value} based on your selected Country, LOI, and IR.`,
      `Boost your conversion success and audience interaction by aiming for a CPI value of at least $${value} considering your Country, LOI, and IR parameters.`,
      `A CPI value of $${value} or higher is recommended to secure strong conversion rates and effectively engage your audience, tailored to your Country, LOI, and IR metrics.`,
    ];
    return arr[Math.floor(Math.random() * arr.length)];
  }, []);
  // console.log("CPIValue=>", CPIValue);
  // console.log("surveyData=>", surveyData);
  // console.log(
  //   getValueByCountryRateAndLOI(
  //     surveyData?.setup?.country,
  //     surveyData?.expectedMatrix?.expectedIncidenceRate,
  //     surveyData?.expectedMatrix?.expectedCompletionLOI,
  //     surveyData?.setup?.targetAudience
  //   )
  // );
  return (
    <div>
      <div className="w-full tableBorder py-3 text-center">
        <h1 className="text-[#333] text-[20px] font-[600]">
          Setup Requirement
        </h1>
      </div>
      <div className="w-full tableBorder pt-6 pb-8 flex items-center justify-center">
        <div className="w-full px-10   flex flex-col gap-[28px]">
          <p className="text-[#333] text-[16px] font-[500]">
            Complete this information to set your survey live.
          </p>
          <div className="flex flex-col gap-[16px]">
            <div className="flex items-center justify-between gap-[30px]">
              <div className="w-2/4 flex flex-col gap-[8px]">
                <p className="text-[#333] font-[500] text-[14px]">
                  Study Type
                  <Required />{" "}
                </p>
                <select
                  name="studyType"
                  id="studyType"
                  className="w-full border border-[#E0E0E0]  rounded-[4px] pl-3 text-[#333] font-[400] text-[14px] h-[40px]"
                  required
                  onChange={handleChange}
                  value={surveyData?.setup?.studyType}
                >
                  <option value="">Select Study Type</option>
                  {studyTypesData?.map((data, ind) => (
                    <option value={data?.value} key={ind}>
                      {data?.label}
                    </option>
                  ))}
                </select>
                {/* <input
                  type="text"
                  // placeholder="Abcd"
                  name="studyType"
                  required
                  onChange={handleChange}
                  value={surveyData?.setup?.studyType}
                  className="w-full border border-[#E0E0E0] bg-[#FAFAFA] rounded-[4px] pl-3 text-[#12112766] font-[400] text-[14px] h-[40px]"
                /> */}
              </div>
              <div className="w-2/4 flex flex-col gap-[8px]">
                <p className="text-[#333] font-[500] text-[14px]">
                  Business Unit
                </p>
                <input
                  type="text"
                  // placeholder="Mirats Insights"
                  name="businessUnit"
                  required
                  readOnly
                  // onChange={handleChange}
                  value={surveyData?.setup?.businessUnit}
                  className="w-full border border-[#E0E0E0] bg-[#FAFAFA] rounded-[4px] pl-3 text-[#12112766] font-[400] text-[14px] h-[40px] hover:cursor-not-allowed"
                />
              </div>
            </div>
            <div className="flex items-center justify-between gap-[30px]">
              <div className="w-2/4 flex flex-col gap-[8px]">
                <p className="text-[#333] font-[500] text-[14px]">
                  Industry <Required />
                </p>
                <select
                  className="w-full border border-[#E0E0E0]  rounded-[4px] pl-3 text-myBlack font-[400] text-[14px] h-[40px]"
                  name="industry"
                  required
                  onChange={handleChange}
                  value={surveyData?.setup?.industry}
                >
                  <option value="">Select industry</option>
                  {industryData?.map((data, key) => (
                    <option key={key} value={data?.value}>
                      {data?.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-2/4 flex flex-col gap-[8px]">
                <p className="text-[#333] font-[500] text-[14px]">
                  Live URL <Required />
                </p>
                <input
                  type="url"
                  required
                  // placeholder="https://kjssdjks894jkadk89wejk/84kjsd8/av..."
                  name="liveUrl"
                  onChange={handleChange}
                  value={surveyData?.setup?.liveUrl}
                  className="w-full border border-[#E0E0E0]  rounded-[4px] pl-3 text-myBlack font-[400] text-[14px] h-[40px]"
                />
              </div>
            </div>
            <div className="grid grid-cols-2  gap-[30px] ">
              <div className="w-full flex flex-col gap-[8px] justify-between  ">
                <p className="text-[#333] font-[500] text-[14px]">Test URL</p>
                <input
                  type="url"
                  // placeholder="https://kjssdjks894jkadk89wejk/84kjsd8/av..."
                  name="testUrl"
                  onChange={handleChange}
                  value={surveyData?.setup?.testUrl}
                  className="w-full border border-[#E0E0E0]  rounded-[4px] pl-3 text-myBlack font-[400] text-[14px] h-[40px]"
                />
              </div>
              <div className="w-full flex flex-col gap-[8px]  ">
                <p className="text-[#333] font-[500] text-[14px]">
                  Survey CPI (USD) <Required />{" "}
                  <span className="text-blue-500 text-[12px]">
                    {isNaN(Number(CPIValue))
                      ? CPIValue?.includes("Contact support")
                        ? CPIValue
                        : ""
                      : Number(
                          surveyData?.setup?.personalInformation
                            ? parseFloat((Number(CPIValue) + 2)?.toFixed(2))
                            : CPIValue
                        ) > Number(surveyData?.setup?.surveyCpi)
                      ? getRandomNoticeLine(
                          surveyData?.setup?.personalInformation
                            ? parseFloat((Number(CPIValue) + 2)?.toFixed(2))
                            : CPIValue
                        )
                      : ""}
                  </span>
                </p>
                <input
                  type="number"
                  placeholder="$ USD"
                  name="surveyCpi"
                  step="0.01"
                  min="0.50"
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "+" || e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value <= 499) {
                      handleChange(e);
                    } else {
                      setSnackbar({
                        msg: "Survey CPI should be less than or equal to 499",
                        open: true,
                        severity: "error",
                      });
                    }
                  }}
                  required
                  value={surveyData?.setup?.surveyCpi}
                  className="w-full border border-[#E0E0E0] rounded-[4px] pl-3 text-myBlack font-[400] text-[14px] h-[40px]"
                />
              </div>
            </div>
            <div className="flex items-center justify-between gap-[30px]">
              <div className="w-2/4 flex flex-col gap-[8px]">
                <p className="text-[#333] font-[500] text-[14px]">
                  Client CPI (USD)
                </p>
                <input
                  type="number"
                  placeholder="$ USD"
                  name="clientCpi"
                  step="0.01"
                  min="0.50"
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "+" || e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value <= 499) {
                      handleChange(e);
                    } else {
                      setSnackbar({
                        msg: "Client CPI should be less than or equal to 499",
                        open: true,
                        severity: "error",
                      });
                    }
                  }}
                  value={surveyData?.setup?.clientCpi}
                  className="w-full border border-[#E0E0E0]  rounded-[4px] pl-3 text-myBlack font-[400] text-[14px] h-[40px]"
                />
              </div>
              <div className="w-2/4 flex flex-col gap-[8px]">
                <p className="text-[#333] font-[500] text-[14px]">
                  Country <Required />
                </p>
                <select
                  className="w-full border border-[#E0E0E0]  rounded-[4px] pl-3 text-myBlack font-[400] text-[14px] h-[40px]"
                  name="country"
                  required
                  onChange={(e) => {
                    setSurveyData((prev) => ({
                      ...prev,
                      setup: {
                        ...prev?.setup,
                        country: e.target.options[e.target.selectedIndex].text,
                        countryCode: e.target.value,
                      },
                    }));
                  }}
                  value={surveyData?.setup?.countryCode}
                >
                  <option value="">Select Country </option>
                  {countryData?.isLoading ? (
                    <option value="" disabled>
                      Loading Countries...
                    </option>
                  ) : countryData?.error ? (
                    <option value="" disabled>
                      Error while fetching countries try refreshing the page
                    </option>
                  ) : (
                    countryData?.data?.map((data, key) => (
                      <option value={data?.iso2} key={key}>
                        {data?.name}
                      </option>
                    ))
                  )}
                </select>
              </div>
            </div>
            <div className="flex items-center justify-between gap-[30px]">
              <div className="w-2/4 flex flex-col gap-[8px]">
                <p className="text-[#333] font-[500] text-[14px] ">
                  Industry Lockout
                </p>
                <select
                  className="w-full border border-[#E0E0E0]  rounded-[4px] pl-3 text-myBlack font-[400] text-[14px] h-[40px]"
                  name="industryLockout"
                  onChange={handleChange}
                  required
                  value={surveyData?.setup?.industryLockout}
                >
                  <option selected disabled>
                    Select Lockout
                  </option>
                  <option value="7 Days">Past 7 Days / 1 Week</option>
                  <option value="14 Days">Past 14 Days / 2 Week</option>
                  <option value="21 Days">Past 21 Days / 3 Week</option>
                  <option value="28 Days">Past 28 Days / 4 Week</option>
                </select>
              </div>
              <div className="w-2/4 flex flex-col gap-[8px]">
                <p className="text-[#333] font-[500] text-[14px]">
                  Language <Required />
                </p>
                <select
                  className="w-full border border-[#E0E0E0] rounded-[4px] pl-3 text-myBlack font-[400] text-[14px] h-[40px]"
                  name="language"
                  required
                  onChange={handleChange}
                  value={surveyData?.setup?.language}
                >
                  <option selected disabled>
                    Select language{" "}
                  </option>
                  {allLanguages?.map((data, key) => (
                    <option value={data} key={key}>
                      {data}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex items-center justify-between gap-[30px]">
              <div className="w-2/4 flex flex-col gap-[8px]">
                <p className="text-[#333] font-[500] text-[14px] w-[80%]">
                  Does your survey collect personal information that can be used
                  to identify an individual ? <Required />
                </p>
                <div className="flex items-center justify-between w-[20%] gap-[16px]">
                  <div className="flex items-center gap-[8px]">
                    <input
                      type="radio"
                      id="personalInfo"
                      className="radioChange"
                      name="personalInformation"
                      onChange={(e) =>
                        setSurveyData((prev) => ({
                          ...prev,
                          setup: {
                            ...prev?.setup,
                            personalInformation: true,
                          },
                        }))
                      }
                      checked={surveyData?.setup?.personalInformation}
                    />
                    <label htmlFor="personalInfo">Yes</label>
                  </div>
                  <div className="flex items-center gap-[8px]">
                    <input
                      type="radio"
                      name="personalInformation"
                      id="personalInfo2"
                      className="radioChange"
                      onChange={(e) =>
                        setSurveyData((prev) => ({
                          ...prev,
                          setup: {
                            ...prev?.setup,
                            personalInformation: false,
                          },
                        }))
                      }
                      checked={surveyData?.setup?.personalInformation === false}
                    />
                    <label htmlFor="personalInfo2">No</label>
                  </div>
                </div>
              </div>
              <div className="w-2/4 flex flex-col gap-[8px]">
                <p className="text-[#333] font-[500] text-[14px]">
                  Target Audience <Required />
                </p>
                <select
                  className="w-full border border-[#E0E0E0] rounded-[4px] pl-3 text-myBlack font-[400] text-[14px] h-[40px]"
                  name="targetAudience"
                  required
                  onChange={(e) =>
                    setSurveyData((prev) => ({
                      ...prev,
                      setup: { ...prev?.setup, targetAudience: e.target.value },
                    }))
                  }
                  value={surveyData?.setup?.targetAudience}
                >
                  <option selected disabled>
                    Select Target Audience{" "}
                  </option>
                  {["B2B", "B2C", "Healthcare"]?.map((data, i) => (
                    <option value={data} key={i}>
                      {data}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {loading ? (
              <div className="flex justify-center w-full">
                <CircularProgress className="text-center" size={25} />
              </div>
            ) : (
              <div className="flex items-center justify-center gap-[8px] mt-[48px]">
                <button
                  className="text-[#0226BE] border border-[#0226BE] text-[14px] font-[400] py-[5px] px-[16px] rounded-[4px]"
                  type="reset"
                >
                  Clear
                </button>
                <button
                  className="text-[#fff] rounded-[4px]  bg-[#0226BE] text-[14px] font-[400] py-[5px] px-[16px]"
                  type="submit"
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const ExpectedMatrix = ({
  surveyData,
  setSurveyData,
  loading,
  setSnackbar,
}) => {
  const handleChange = (e) => {
    let { name, value } = e.target;
    setSurveyData((prev) => ({
      ...prev,
      expectedMatrix: {
        ...prev?.expectedMatrix,
        [name]: value,
      },
    }));
  };
  // console.log(surveyData);
  return (
    <div>
      <div className="w-full tableBorder py-3 text-center">
        <h1 className="text-[#333] text-[20px] font-[600]">
          Expected Metrics & Data
        </h1>
      </div>
      <div className="w-full tableBorder pt-6 pb-8 flex items-center justify-center">
        <div className="w-full px-10   flex flex-col gap-[28px]">
          <p className="text-[#333] text-[16px] font-[500]">
            Fill in to inform suppliers of your survey before receiving traffic
            review in-field data when survey is live.
          </p>
          <div className="flex flex-col gap-[16px]">
            <div className="flex items-center justify-between gap-[30px]">
              <div className="w-2/4 flex flex-col gap-[8px]">
                <p className="text-[#333] font-[500] text-[14px]">
                  Expected Completes
                  <Required />
                </p>
                <input
                  type="number"
                  // placeholder="293"
                  min="10"
                  name="expectedCompletes"
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "+" || e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                  required
                  value={surveyData?.expectedMatrix?.expectedCompletes}
                  onChange={(e) => {
                    if (e.target.value <= 1000000) {
                      handleChange(e);
                    } else {
                      setSnackbar({
                        open: true,
                        msg: "Expected Completes should be less than or equal to 1000000",
                        severity: "error",
                      });
                    }
                  }}
                  className="w-full border  rounded-[4px] pl-3 text-myBlack font-[400] text-[14px] h-[40px]"
                />
              </div>
              <div className="w-2/4 flex flex-col gap-[8px]">
                <p className="text-[#333] font-[500] text-[14px]">
                  Expected Incidence Rate
                  <Required />
                </p>
                <input
                  type="number"
                  // placeholder="80"
                  name="expectedIncidenceRate"
                  step="0.01"
                  required
                  min="1"
                  value={surveyData?.expectedMatrix?.expectedIncidenceRate}
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "+" || e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value <= 100) {
                      handleChange(e);
                    } else {
                      setSnackbar({
                        open: true,
                        msg: "Expected IR should be less than or equal to 100 %",
                        severity: "error",
                      });
                    }
                  }}
                  className="w-full border  rounded-[4px] pl-3 text-myBlack font-[400] text-[14px] h-[40px]"
                />
              </div>
            </div>
            <div className="flex items-center justify-between gap-[30px]">
              <div className="w-2/4 flex flex-col gap-[8px]">
                <p className="text-[#333] font-[500] text-[14px]">
                  Expected Completion LOI <Required />
                </p>
                <input
                  type="number"
                  // placeholder="339"
                  step="0.01"
                  required
                  min="1"
                  name="expectedCompletionLOI"
                  value={
                    surveyData?.expectedMatrix?.expectedCompletionLOI || ""
                  }
                  onKeyDown={(e) => {
                    if (e.key === "e" || e.key === "+" || e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value <= 90) {
                      handleChange(e);
                    } else {
                      setSnackbar({
                        open: true,
                        msg: "Expected LOI should be less than or equal to 90 mins",
                        severity: "error",
                      });
                    }
                  }}
                  className="w-full border  rounded-[4px] pl-3 text-myBlack font-[400] text-[14px] h-[40px]"
                />
              </div>
              <div className="w-2/4 "></div>
            </div>
            {loading ? (
              <div className="flex justify-center w-full">
                <CircularProgress className="text-center" size={25} />
              </div>
            ) : (
              <div className="flex items-center justify-center gap-[8px] mt-[48px]">
                <button className="text-[#0226BE] border border-[#0226BE] text-[14px] font-[400] py-[5px] px-[16px] rounded-[4px]">
                  Clear
                </button>
                <button
                  className="text-[#fff] rounded-[4px]  bg-[#0226BE] text-[14px] font-[400] py-[5px] px-[16px]"
                  type="submit"
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const Peoples = ({ surveyData, setSurveyData, teamMembers, loading }) => {
  const handleChange = (e) => {
    let { name, value } = e.target;
    setSurveyData((prev) => ({
      ...prev,
      peoples: {
        ...prev?.peoples,
        [name]: value,
      },
    }));
  };
  // console.log(surveyData);
  // console.log(teamMembers);
  return (
    <div>
      <div className="w-full tableBorder py-3 text-center">
        <h1 className="text-[#333] text-[20px] font-[600]">Peoples</h1>
      </div>
      <div className="w-full tableBorder pt-6 pb-8 flex items-center justify-center">
        <div className="w-full px-10   flex flex-col gap-[28px]">
          <p className="text-[#333] text-[16px] font-[500]">
            Manage ownership of the survey for reporting and communication.
          </p>
          <div className="flex flex-col gap-[16px]">
            <div className="flex items-center justify-between gap-[30px]">
              <div className="w-2/4 flex flex-col gap-[8px]">
                <p className="text-[#333] font-[500] text-[14px]">
                  Project Manager <Required />
                </p>
                <select
                  className="w-full border border-[#E0E0E0]  rounded-[4px] pl-3 text-myBlack font-[400] text-[14px] h-[40px]"
                  name="projectManager"
                  onChange={handleChange}
                  required
                  value={
                    surveyData?.peoples?.projectManager?._id
                      ? surveyData?.peoples?.projectManager?._id
                      : surveyData?.peoples?.projectManager
                  }
                >
                  <option value="">Select Project Manager</option>
                  {teamMembers?.map((data, key) => (
                    <option value={data?._id} key={key}>
                      {data?.personalDetails?.fullName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-2/4 flex flex-col gap-[8px]">
                <p className="text-[#333] font-[500] text-[14px]">
                  Account Executive
                </p>
                <select
                  className="w-full border border-[#E0E0E0]  rounded-[4px] pl-3 text-myBlack font-[400] text-[14px] h-[40px]"
                  name="accountExecutive"
                  onChange={handleChange}
                  value={
                    surveyData?.peoples?.accountExecutive?._id
                      ? surveyData?.peoples?.accountExecutive?._id
                      : surveyData?.peoples?.accountExecutive
                  }
                >
                  <option value="">Select Account Executive</option>
                  {teamMembers?.map((data, key) => (
                    <option value={data?._id} key={key}>
                      {data?.personalDetails?.fullName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex items-center justify-between gap-[30px]">
              <div className="w-2/4 flex flex-col gap-[8px]">
                <p className="text-[#333] font-[500] text-[14px]">
                  Expected Alternate Project Manager
                </p>
                <select
                  className="w-full border border-[#E0E0E0]  rounded-[4px] pl-3 text-myBlack font-[400] text-[14px] h-[40px]"
                  name="alternateProjectManager"
                  onChange={handleChange}
                  value={
                    surveyData?.peoples?.alternateProjectManager?._id
                      ? surveyData?.peoples?.alternateProjectManager?._id
                      : surveyData?.peoples?.alternateProjectManager
                  }
                >
                  <option value="">Select Alternate Project Manager</option>
                  {teamMembers?.map((data, key) => (
                    <option value={data?._id} key={key}>
                      {data?.personalDetails?.fullName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-2/4 flex flex-col gap-[8px]"></div>
            </div>
            {loading ? (
              <div className="flex justify-center w-full">
                <CircularProgress className="text-center" size={25} />
              </div>
            ) : (
              <div className="flex items-center justify-center gap-[8px] mt-[48px]">
                <button className="text-[#0226BE] border border-[#0226BE] text-[14px] font-[400] py-[5px] px-[16px] rounded-[4px]">
                  Clear
                </button>
                <button
                  className="text-[#fff] rounded-[4px]  bg-[#0226BE] text-[14px] font-[400] py-[5px] px-[16px]"
                  type="submit"
                >
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const ProjectSettings = ({
  loading,
  surveyData,
  setSurveyData,
  getSingleSurvey,
  setSnackbar,
}) => {
  const { user } = useGlobalContext();
  // console.log("surveyData=>", surveyData);
  const handleChange = async (e) => {
    await axios
      .put(`${BASE_URL}/survey?id=${surveyData?._id}`, {
        objectType: Number(e.target.value),
      })
      .then(() => {
        getSingleSurvey();
        setSnackbar((prev) => ({
          ...prev,
          open: true,
          msg: "Survey Details Updated !",
          severity: "success",
        }));
      })
      .catch((er) => {
        setSnackbar({
          msg: "Something went wrong !",
          open: true,
          severity: "danger",
        });
      });
  };
  return (
    <div>
      <div className="w-full tableBorder py-3 text-center">
        <h1 className="text-[#333] text-[20px] font-[600]">Project Settings</h1>
      </div>
      <div className="w-full tableBorder pt-6 pb-8 flex items-center justify-center">
        <div className="w-full px-10   flex flex-col gap-[28px]">
          <p className="text-[#333] text-[16px] font-[500]">
            Group survey for improved security and financial reporting benefits.
          </p>
          <div className="flex items-start gap-[30px] justify-between">
            <div>
              <p className="text-[#333] text-[14px] font-[500]">Client</p>
              <h1 className="text-[#333] text-[20px] font-[600]">
                {user?.organizationName || "_"}
              </h1>
            </div>
            <div className="w-2/4 flex flex-col gap-[8px]">
              <p className="text-[#333] font-[500] text-[14px]">Object Type</p>
              <select
                className="w-full border border-[#E0E0E0]  rounded-[4px] pl-3 text-myBlack font-[400] text-[14px] h-[40px]"
                name="accountExecutive"
                onChange={(e) => {
                  setSurveyData((prev) => ({
                    ...prev,
                    objectType: Number(e.target.value),
                  }));
                  handleChange(e);
                }}
                value={surveyData?.objectType}
              >
                <option value="">Select Object Type</option>
                {[
                  { label: "Survey", value: 0 },
                  { label: "Campaign", value: 1 },
                ]?.map((d) => (
                  <option key={d?.value} value={d?.value}>
                    {d?.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Details;
