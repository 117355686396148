import React from "react";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import InvoiceOverviewGraph from "./components/InvoiceOverviewGraph";
import MySurveys from "./components/MySurveys";
import MyInvoices from "./components/MyInvoices";
import SurveyAnalytics from "./components/SurveyAnalytics";
import MySurveyGroups from "./components/MySurveyGroups";
import MyQuestions from "./components/MyQuestions";

const Dashboard = () => {
  const { user, allMonthsAndYear } = useGlobalContext();

  return (
    <div className="w-full flex flex-col gap-[35px] py-6">
      <div className="w-full flex items-center justify-between ">
        <h1 className="text-[#333] text-[32px] font-[500] inter ">Dashboard</h1>
      </div>
      <div className="w-full flex flex-col gap-[23px]">
        <h1 className="text-[#333] text-[20px] font-[500] inter">
          Survey Overview
        </h1>

        <div className="w-full flex  gap-[27px]">
          {/* Survey Analysis graph */}
          <SurveyAnalytics allMonthsAndYear={allMonthsAndYear} user={user} />
          {/* Invoice Overview graph */}
          <InvoiceOverviewGraph
            user={user}
            allMonthsAndYear={allMonthsAndYear}
          />
        </div>
        <div className="w-full flex  gap-[18px]">
          {/* my surveys table */}
          <MySurveys user={user} />
          {/* my invoices table */}
          <MyInvoices user={user} />
        </div>
        <div className="w-full flex  gap-[27px] mt-3">
          <div className="w-[40%] flex flex-col gap-[20px] ">
            <MySurveyGroups user={user} />
          </div>

          <div className="w-[60%] flex flex-col gap-[20px]">
            <MyQuestions user={user} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
