import axios from "axios";
import { getCookie } from "../Utlils";

axios.interceptors.request.use(
  (config) => {
    const user = getCookie("frontendCookie");
    if (user) {
      config.headers.Authorization = `Diy ${user}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
