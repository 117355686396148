import React from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { getDashboardSurveyGroups } from "../../../Actions/DashboardActions";
import { useQuery } from "@tanstack/react-query";
import NoData from "../../../components/NoData";
const MySurveyGroups = ({ user }) => {
  const navigate = useNavigate();
  const {
    data: surveyGroupsData,
    isLoading: surveyGroupsDataLoading,
    isError: surveyGroupsDataError,
  } = useQuery({
    queryFn: () => getDashboardSurveyGroups(user?.organizationId),
    queryKey: ["dashboardSurveyGroups", user?.organizationId],
    enabled: !!user?.organizationId,
    staleTime: 10000,
    cacheTime: 15000,
  });
  //   console.log({
  //     surveyGroupsData,
  //     surveyGroupsDataLoading,
  //     surveyGroupsDataError,
  //   });
  return (
    <div className="w-full dashboardShadow h-[360px] ">
      <div className="mx-5 py-6  flex items-center justify-between">
        <h1 className="text-[#333] text-[16px] font-[500] inter">
          My Survey Groups
        </h1>
        <p
          className="text-[#ACACAC] text-[14px] font-[400] cursor-pointer hover:text-primary hover:font-[500] hover:underline underline-offset-[3px] hover:transition-all duration-500 ease-linear decoration-2 overflow-hidden"
          onClick={(e) => navigate("/survey-groups")}
        >
          See all
        </p>
      </div>
      <div className=" mx-5 w-[95%]  max-h-[320px] overflow-y-auto containerScroll ">
        <table className=" w-full  ">
          <thead className="">
            <tr className=" border-b border-[#E0E0E0]">
              {["Group Name", "Description", "Survey ID", "Status"]?.map(
                (data, ind) => (
                  <td
                    className="text-[#999999] pb-3 text-[12px] font-[500]"
                    key={ind}
                  >
                    {data}
                  </td>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {surveyGroupsDataLoading ? (
              <tr>
                <td colSpan={4}>
                  <div className="w-full text-center py-6 text-[#666] font-[500] text-[18px] flex items-center justify-center flex-col space-y-5 h-[250px]">
                    <h1 className="text-[#666666] text-[14px] ml-8 font-[300] tracking-wide flex gap-[10px] items-center">
                      Loading...
                    </h1>
                  </div>
                </td>
              </tr>
            ) : surveyGroupsDataError ? (
              <tr>
                <td colSpan={4}>
                  <div className="w-full text-center py-6 text-[#666] font-[500] text-[18px] flex items-center justify-center flex-col space-y-5 h-[250px]">
                    <h1 className="text-[#e12121] text-[14px] ml-8 font-[300] tracking-wide flex gap-[10px] items-center">
                      Something went wrong !
                    </h1>
                  </div>
                </td>
              </tr>
            ) : surveyGroupsData?.length ? (
              surveyGroupsData?.map((data, ind) => (
                <tr className="" key={ind}>
                  <td
                    className="text-[#333333] font-[400] text-[12px] py-3 cursor-pointer hover:text-primary hover:font-[500] hover:underline underline-offset-[3px] hover:transition-all duration-500 ease-linear decoration-2 overflow-hidden"
                    onClick={(e) => navigate(`/survey-groups/${data?._id}`)}
                  >
                    <Tooltip title={data?.groupName}>
                      <p className="text-[#333333] font-[400] text-[12px]  w-[100px]  text-ellipsis overflow-hidden whitespace-nowrap">
                        {data?.groupName}
                      </p>
                    </Tooltip>
                  </td>
                  <td className="text-[#333333] font-[400] text-[12px] py-3 ">
                    <Tooltip title={data?.description}>
                      <h1 className="text-[#333333] font-[400] text-[12px] w-[140px]  text-ellipsis overflow-hidden whitespace-nowrap">
                        {data?.description}
                      </h1>
                    </Tooltip>
                  </td>
                  <td className="text-[#333333] font-[400] text-[12px] py-3 ">
                    {data?.surveys?.length > 1
                      ? `QID#${data?.surveys[0]?.surveyNumber}, ${
                          data?.surveys?.length - 1
                        } More`
                      : `QID#${data?.surveys[0]?.surveyNumber}`}
                  </td>
                  <td className="text-[#333333] font-[400] text-[12px] py-3 ">
                    {data?.isActive ? (
                      <div className="px-[12px] py-[4px] bg-[#EDFBF4] text-[#2FBB85] text-[10px] font-[500]  rounded-[11px] text-center">
                        Active
                      </div>
                    ) : (
                      <div className="px-[12px] py-[4px] bg-[#FFFAF1] rounded-[11px] text-[#CB8A14] text-[10px] font-[500] text-center">
                        Inactive
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>
                  <NoData
                    msg={
                      <h1
                        className="text-primary font-[500] underline underline-offset-4 decoration-2 cursor-pointer"
                        onClick={(e) => navigate("/survey-groups")}
                      >
                        Add new
                      </h1>
                    }
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MySurveyGroups;
