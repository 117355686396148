import { Tooltip } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getDashboardQuestions } from "../../../Actions/DashboardActions";
import NoData from "../../../components/NoData";

const MyQuestions = ({ user }) => {
  const navigate = useNavigate();
  const {
    data: myQuestions,
    isLoading: myQuestionsLoading,
    isError: myQuestionsError,
  } = useQuery({
    queryFn: () => getDashboardQuestions(user?.organizationId),
    queryKey: ["dashboardQuestions", user?.organizationId],
    enabled: !!user?.organizationId,
    staleTime: 10000,
    cacheTime: 15000,
  });
  //   console.log(myQuestions, myQuestionsLoading, myQuestionsError);
  return (
    <div className="w-full dashboardShadow h-[360px]">
      <div className="mx-5 py-6  flex items-center justify-between">
        <h1 className="text-[#333] text-[16px] font-[500] inter">
          List Of Questions
        </h1>
        <p
          className="text-[#ACACAC] text-[14px] font-[400] cursor-pointer hover:text-primary hover:font-[500] hover:underline underline-offset-[3px] hover:transition-all duration-500 ease-linear decoration-2 overflow-hidden"
          onClick={(e) => navigate("/qualification-library")}
        >
          See all
        </p>
      </div>
      <div className=" mx-5 w-[95%] max-h-[280px]  overflow-y-auto containerScroll ">
        <table className=" w-full  ">
          <thead className="">
            <tr className=" border-b border-[#E0E0E0]">
              {[
                "Qualifications Name",
                "Question",
                "Qualification Type",
                "Status",
              ]?.map((data, ind) => (
                <td
                  className="text-[#999999] pb-3 text-[12px] font-[500]"
                  key={ind}
                >
                  {data}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {myQuestionsLoading ? (
              <tr>
                <td colSpan={4}>
                  <div className="w-full text-center py-6 text-[#666] font-[500] text-[18px] flex items-center justify-center flex-col space-y-5 h-[240px]">
                    <h1 className="text-[#666666] text-[14px] ml-8 font-[300] tracking-wide flex gap-[10px] items-center">
                      Loading...
                    </h1>
                  </div>
                </td>
              </tr>
            ) : myQuestionsError ? (
              <tr>
                <td colSpan={4}>
                  <div className="w-full text-center py-6 text-[#666] font-[500] text-[18px] flex items-center justify-center flex-col space-y-5 h-[240px]">
                    <h1 className="text-[#e12121] text-[14px] ml-8 font-[300] tracking-wide flex gap-[10px] items-center">
                      Something went wrong !
                    </h1>
                  </div>
                </td>
              </tr>
            ) : myQuestions?.length ? (
              myQuestions?.map((data) => (
                <tr className="">
                  <td className="text-[#333333] font-[400] w-[200px] text-[12px] py-3 ">
                    <Tooltip title={data?.questionName || "-"}>
                      <p className="w-[85%]   overflow-hidden text-ellipsis  whitespace-nowrap">
                        {data?.questionName || "-"}
                      </p>
                    </Tooltip>
                  </td>
                  <td className="text-[#333333]  font-[400] w-[270px] text-[12px] py-3  ">
                    <Tooltip title={data?.questionText || "-"}>
                      <p className="w-[250px]  overflow-hidden text-ellipsis  whitespace-nowrap">
                        {data?.questionText || "-"}
                      </p>
                    </Tooltip>
                  </td>
                  <td className="text-[#333333] font-[400] text-[12px] py-3 ">
                    {data?.questionType || "-"}
                  </td>

                  <td className="text-[#333333] font-[400] text-[12px] py-3 capitalize">
                    {data?.questionStatus || "-"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>
                  <div className="w-full text-center py-6 text-[#666] font-[500] text-[18px] flex items-center justify-center flex-col space-y-5 h-[240px]">
                    <NoData
                      msg={
                        <h1
                          className="text-primary font-[500] underline underline-offset-4 decoration-2 cursor-pointer"
                          onClick={(e) => navigate("/qualification-library")}
                        >
                          Add new
                        </h1>
                      }
                    />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MyQuestions;
