import { useEffect } from 'react';

function LoginRedirect() {
  useEffect(() => {
    window.location.href = 'https://accounts.miratsgroup.com/login/?redirect=https%3A%2F%2Fapp.miratsquanto.com&eu=U2FsdGVkX1%252B9Vp%252F0AGdLxVHqj%252BwyR5AJt1HGJMktaikyYaQbHX%252FzL24kTIyOpnTL';
  }, []);

  return null;
}

export default LoginRedirect;
