import React from "react";
import Skeleton from "../../../../components/Skeleton";
const QualificationLoader = () => {
  return (
    <div>
      <div className="border-b py-3 px-4 gap-[20px] flex items-center justify-between">
        {[1, 2, 3, 4, 5]?.map((d, i) => (
          <Skeleton className="w-[100px] h-[30px]" key={i} />
        ))}
      </div>
      <div className=" py-3 px-4 space-y-3">
        {[1, 2, 3, 4]?.map((d, i) => (
          <div
            key={i}
            className="border-b py-3  gap-[20px] flex items-center justify-between"
          >
            <div className="space-y-2">
              <Skeleton className="w-[180px] h-[20px]" />
            </div>
            {[1, 2, 3, 4, 5]?.map((dd, ii) => (
              <Skeleton className="w-[80px] h-[20px]" key={ii} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default QualificationLoader;
