import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../../../Utlils";
import { useGlobalContext } from "../../../../Contexts/GlobalContext";

const Security = ({ data, getSingleSurvey }) => {
  const [inputData, setInputData] = useState({});
  const [deviceType, setDeviceType] = useState({});
  const { setSnackbar } = useGlobalContext();
  const handleChange = (e, something) => {
    let { name, value } = e.target;
    if (e.target.checked) {
      if (something) {
        setDeviceType((prev) => ({ ...prev, [name]: true }));
      } else {
        setInputData((prev) => ({ ...prev, [name]: true }));
      }
    } else {
      if (something) {
        setDeviceType((prev) => ({ ...prev, [name]: false }));
      } else {
        setInputData((prev) => ({ ...prev, [name]: false }));
      }
    }
  };
  const deviceTypeData = ["mobile", "tablet", "desktop"];
  const handleSubmit = async (e) => {
    e.preventDefault();
    axios
      .put(`${BASE_URL}/survey?id=${data?._id}`, {
        security_checks: inputData,
        deviceType,
      })
      .then((res) => {
        getSingleSurvey();
        setSnackbar((prev) => ({
          ...prev,
          open: true,
          msg: "Survey Security Updated !",
          severity: "success",
        }));
      })
      .catch((er) =>
        setSnackbar({
          msg: "Something went wrong !",
          open: true,
          severity: "danger",
        })
      );
  };
  useEffect(() => {
    setInputData(data?.security_checks ?? {});
    setDeviceType(data?.deviceType ?? {});
  }, [data]);
  // console.log(inputData);
  // console.log(deviceType);
  // console.log("data=>",data);
  return (
    <div className="w-full reconciliationShadow flex flex-col gap-[18px] items-center justify-center pb-10">
      <div className="w-[93%]    my-6">
        <div className="w-full  flex flex-col gap-[24px]">
          <h1 className="text-[#666] font-[600] text-[18px]">Security</h1>
          <div className="w-full flex flex-col gap-[20px]">
            <div className="space-y-3 ">
              <h1 className="text-[#333] font-[600] text-[20px]">Security</h1>
              <div className="p-[25px_15px] border rounded-md shadow-sm flex items-start justify-between gap-[30px]">
                <div className="  w-1/2 ">
                  <h1 className="text-[16px] font-[600] text-[#666]">
                    Survey Validation
                  </h1>
                  <div className="py-3 w-[70%]  space-y-5 ">
                    <div className="flex items-center justify-between">
                      <h1 className="text-[14px]"> Geo Location</h1>
                      <input
                        name="geoLocation"
                        type="checkbox"
                        checked={true}
                        className="w-[16px] h-[16px] cursor-pointer"
                      />
                    </div>
                    <div className="flex items-center justify-between">
                      <h1 className="text-[14px]"> Bot Detection</h1>
                      <input
                        name="geoLocation"
                        type="checkbox"
                        checked={true}
                        className="w-[16px] h-[16px] cursor-pointer"
                      />
                    </div>
                    <div className="flex items-center justify-between">
                      <h1 className="text-[14px]"> Verify Callback</h1>
                      <input
                        type="checkbox"
                        name="verify_callbacks"
                        checked={inputData?.verify_callbacks}
                        onChange={handleChange}
                        className="w-[16px] h-[16px] cursor-pointer"
                      />
                    </div>
                    <div className="flex items-center justify-between">
                      <h1 className="text-[14px]">Unique IP Address</h1>
                      <input
                        type="checkbox"
                        name="unique_ip"
                        onChange={handleChange}
                        checked={inputData?.unique_ip}
                        className="w-[16px] h-[16px] cursor-pointer"
                      />
                    </div>
                    <div className="flex items-center justify-between">
                      <h1 className="text-[14px]"> Unique RID</h1>
                      <input
                        type="checkbox"
                        name="unique_rid"
                        onChange={handleChange}
                        checked={inputData?.unique_rid}
                        className="w-[16px] h-[16px] cursor-pointer"
                      />
                    </div>
                    <div className="flex items-center justify-between">
                      <h1 className="text-[14px]"> Unique Fingerprint</h1>
                      <input
                        name="unique_fingerprint"
                        onChange={handleChange}
                        type="checkbox"
                        checked={inputData?.unique_fingerprint}
                        className="w-[16px] h-[16px] cursor-pointer"
                      />
                    </div>
                  </div>
                </div>
                <div className="  w-1/2">
                  <h1 className="text-[16px] font-[500] text-[#666]">
                    Device Type
                  </h1>
                  <div className="py-3 w-[70%]  space-y-5 ">
                    {deviceTypeData?.map((d) => (
                      <div className="flex items-center justify-between">
                        <h1 className="text-[14px] capitalize"> {d}</h1>
                        <input
                          name={d}
                          type="checkbox"
                          checked={deviceType?.[d] || false}
                          onChange={(e) => handleChange(e, "something")}
                          className="w-[16px] h-[16px] cursor-pointer"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-[20px]">
        {/* <button className="border border-primary text-primary py-[8px] px-[16px] text-[12px] font-[400] rounded-[4px]">
          Cancel
        </button> */}
        <button
          className="border border-primary bg-primary text-white py-[8px] px-[16px] text-[12px] font-[400] rounded-[4px]"
          onClick={handleSubmit}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Security;
