import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { NavLink, useNavigate } from "react-router-dom";
import SideBarActiveIcon from "../../../assets/icons/SideBarActive.svg";
import { RiProfileLine } from "react-icons/ri";
import { HiOutlineUserGroup, HiOutlineUserCircle } from "react-icons/hi";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { RiLogoutBoxLine, RiFolderSettingsLine } from "react-icons/ri";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { LuFileKey } from "react-icons/lu";
import { PiBracketsCurlyBold } from "react-icons/pi";
import { logOut } from "@mirats/mirats-auth";
const Sidebar = ({ children }) => {
  const navigate = useNavigate();
  const [collapse, setCollapse] = useState(false);
  const { setSnackbar } = useGlobalContext();
  const SideBarData = [
    {
      name: "My Profile",
      path: "/profile/my-profile",
      subPath: "my-profile",
      icon: <RiProfileLine size={23} />,
    },
    // {
    //   name: "My Account",
    //   path: "/profile/my-account",
    //   subPath: "my-account",
    //   icon: <HiOutlineUserCircle size={23} />,
    // },
    {
      name: "Finance",
      path: "/profile/finance",
      subPath: "finance",
      icon: <LiaFileInvoiceDollarSolid size={23} />,
    },
    {
      name: "Project Settings",
      path: "/profile/project-settings",
      subPath: "project-settings",
      icon: <RiFolderSettingsLine size={23} />,
    },
    {
      name: "Post Back Url",
      path: "/profile/post-back",
      subPath: "post-back",
      icon: <PiBracketsCurlyBold size={23} />,
    },
    {
      name: "Redirects & keys",
      path: "/profile/redirects-and-keys",
      subPath: "redirects-and-keys",
      icon: <LuFileKey size={23} />,
    },
    {
      name: "My Notifications",
      path: "/profile/my-notification",
      subPath: "my-notification",
      icon: <MdOutlineNotificationsActive size={23} />,
    },

    {
      name: "Members",
      path: "/profile/my-member",
      subPath: "my-member",
      icon: <HiOutlineUserGroup size={23} />,
    },
  ];

  // console.log(collapse);
  return (
    <div className="w-full flex" style={{ height: "calc(100vh - 64px)" }}>
      <div
        className={`
        ${collapse ? "w-[5%]" : "w-[18%]"}
        transition-all ease-in duration-500 bg-white z-[20] border-t border-[#E3E1E1] sidebarShadow relative `}
      >
        <h1
          className={` w-[30px] h-[30px] flex items-center justify-center rounded-[50%] absolute top-8 right-[-18px] transition-all origin-center ease-in duration-500 cursor-pointer bg-[#0226BE] ${
            collapse ? "rotate-[180deg]  " : ""
          } `}
          onClick={(e) => setCollapse(!collapse)}
        >
          <IoIosArrowBack color="white" size={22} />
        </h1>
        <div className="w-full flex flex-col gap-4 mt-5 overflow-y-auto containerScroll h-[95%]">
          {SideBarData?.map((data, ind) => (
            <div
              key={ind}
              className={`flex items-center transition-all ease-in duration-500  ${
                collapse ? "gap-[5px]" : "gap-[15px]"
              }   h-[50px] cursor-pointer `}
              onClick={(e) => navigate(data?.path)}
            >
              <img
                src={SideBarActiveIcon}
                alt="sidbarIcon"
                className={`${
                  !window.location.pathname?.includes(data?.subPath)
                    ? "opacity-0"
                    : "opacity-100"
                }`}
              />
              <p
                to={data?.path}
                // className="flex items-center justify-between"
                className={`transition duration-500  cursor-pointer flex items-center text-center gap-[10px]
                ${
                  collapse
                    ? "w-[60%] pl-3 h-[80%] rounded-[50%]"
                    : "w-[75%] px-4"
                }   ${
                  window.location.pathname?.includes(data?.subPath)
                    ? "text-[#0226BE] inter text-[16px] font-[600]  bg-[#B8B6B61A]  h-[100%]  rounded-[9px]"
                    : "text-[#666] inter text-[14px] font-[500]"
                } overflow-hidden `}
              >
                <h1 className=""> {data?.icon ?? data?.icon}</h1>
                <h1
                  key={ind}
                  className={`whitespace-nowrap transition-all ease-in  duration-500  ${
                    collapse ? "opacity-0" : ""
                  }  `}
                >
                  {data?.name}
                </h1>
              </p>
            </div>
          ))}
          <div
            className={`flex items-center absolute bg-white  bottom-1 transition-all ease-in duration-500 w-full border-t-[#0226BE] ${
              collapse ? "gap-[5px]" : "gap-[15px]"
            }   h-[50px] cursor-pointer`}
            onClick={logOut}
          >
            <img src={SideBarActiveIcon} alt="img" className="opacity-0" />
            <p
              // to={data?.path}
              // className="flex items-center justify-between"
              className={`transition duration-500  cursor-pointer flex items-center text-center gap-[10px] text-[#0226BE] inter text-[18px] font-[600] 
                ${
                  collapse
                    ? "w-[60%] pl-3 h-[80%] rounded-[50%]"
                    : "w-[75%] px-4"
                }    overflow-hidden `}
            >
              <h1 className="">
                {" "}
                <RiLogoutBoxLine size={23} />
              </h1>
              <h1
                className={`whitespace-nowrap  transition-all ease-in  duration-500  ${
                  collapse ? "opacity-0" : ""
                }  `}
              >
                Logout
              </h1>
            </p>
          </div>
        </div>
      </div>
      <div
        className=" px-6 pt-3  transition-all duration-500 ease-in flex items-center justify-center overflow-y-auto containerScroll"
        style={{
          width: collapse ? "95%" : "82%",
          height: "calc(100vh - 64px)",
        }}
      >
        <div className="w-[95%] h-full pt-8">{children}</div>
      </div>
    </div>
  );
};

export default Sidebar;
