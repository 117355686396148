import React, { useEffect, useState } from "react";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import axios from "axios";
import { BASE_URL } from "../../../Utlils";
const endpointData = {
  complete: 10,
  terminate: 20,
  "Quality Terminate": 30,
  "Quota Full": 40,
};
const RedirectsAndKey = ({ user, setUser }) => {
  const { setSnackbar, organization, getOrganization } = useGlobalContext();
  const [inputData, setInputData] = useState("");
  const [showKey, setShowKey] = useState(false);
  const handleEye = () => {
    setShowKey(!showKey);
  };
  function generateRandomString() {
    const length = 30;
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }

    setInputData(result);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (inputData?.length <= 35) {
        let data = await axios.put(
          `${BASE_URL}/company/${user?.organizationId}`,
          { key: inputData }
        );
        await getOrganization(user?.organizationId);
        setSnackbar({
          open: true,
          msg: "Secret key generated successfully!",
          severity: "success",
        });
        console.log("data.data=>", data.data);
      } else {
        setSnackbar({
          open: true,
          msg: "Key is too long...",
          severity: "error",
        });
      }
    } catch (error) {
      setSnackbar({
        open: true,
        msg: "Something went wrong",
        severity: "error",
      });
    }
  };
  useEffect(() => {
    if (Object.keys(organization || {})?.length) {
      setInputData(organization?.key);
    }
  }, [Object.keys(organization || {})?.length]);
  //   console.log("user=>", user);
  //   console.log(inputData?.length);
  return (
    <div className="w-full flex flex-col gap-[15px] h-full py-5">
      <div className="pb-10 flex items-center justify-between  border-b border-[#E0E0E0] ">
        <div className="flex flex-col gap-[15px] justify-center">
          <h1 className="text-[#333333] text-[28px] font-[500]">
            Redirects & Secret key
          </h1>
          <p>This information will be used for redirect setup</p>
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="flex w-full py-3 items-end gap-[20px]"
      >
        <div className="flex flex-col gap-[15px] w-[50%]">
          <h1 className="flex items-center gap-[20px] text-[16px] font-[600]">
            {organization?.key ? "Your Secret Key" : "Generate Secret Key"}
          </h1>
          <div className="flex items-center relative">
            <input
              type={showKey ? "text" : "password"}
              placeholder="Enter Secret Key"
              disabled={organization?.key || user?.role !== "admin"}
              style={
                user?.role == "admin"
                  ? { cursor: "auto" }
                  : { cursor: "not-allowed" }
              }
              value={inputData}
              onChange={(e) => {
                const newValue = e.target.value;
                if (/^[A-Za-z0-9]{0,35}$/.test(newValue)) {
                  setInputData(newValue);
                }
              }}
              name="secretKey"
              required
              className="border py-2 px-4 rounded-[8px] text-[#666] text-[15px] w-full"
            />
            <div
              className="absolute right-2 bg-white px-1 cursor-pointer"
              onClick={handleEye}
            >
              {showKey ? (
                <BsFillEyeFill size={20} />
              ) : (
                <BsFillEyeSlashFill size={20} />
              )}
            </div>
          </div>
        </div>
        {!organization?.key ? (
          <button
            type="button"
            disabled={user?.role !== "admin"}
            onClick={generateRandomString}
            style={
              user?.role == "admin"
                ? { cursor: "pointer" }
                : { cursor: "not-allowed" }
            }
            className="border text-primary text-[14px]  border-primary py-2 px-4 rounded-[8px]"
          >
            Auto Generate
          </button>
        ) : (
          ""
        )}
        {!organization?.key ? (
          <button
            type="submit"
            disabled={user?.role !== "admin"}
            style={
              user?.role == "admin"
                ? { cursor: "pointer" }
                : { cursor: "not-allowed" }
            }
            className="border text-white text-[14px] bg-primary py-2 px-4 rounded-[8px]"
          >
            Save
          </button>
        ) : (
          ""
        )}
        {organization?.key ? (
          <h1 className="text-[14px] mb-3">
            To edit your secret key{" "}
            <a
              href="mailto:diysupport@miratsinsights.com"
              className="text-blue-400 capitalize underline underline-offset-[3px]"
            >
              {" "}
              contact support team{" "}
            </a>
          </h1>
        ) : (
          ""
        )}
      </form>

      {organization?.key ? (
        <div className="py-3 space-y-3">
          {" "}
          <h1 className="flex items-center gap-[20px] text-[16px] font-[600]">
            Redirects
          </h1>
          <div className="tableBorder min-h-[100px] w-full">
            <div className="w-full overflow-x-auto containerScroll">
              <table className="whitespace-nowrap text-left w-full">
                <thead className=" h-[45px] ">
                  <tr className="py-3">
                    <th className="text-[#666666] text-[14px] font-[600] px-3">
                      Type
                    </th>
                    <th className="text-[#666666] text-[14px] font-[600] px-3">
                      Endpoint
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object?.keys(endpointData)?.map((d, i) => (
                    <tr className=" tableBorder transition-all cursor-pointer hover:bg-[#f6f9ff]">
                      <td className="px-3 py-5 capitalize  text-[#667085] font-[400] text-[14px] w-[300px]">
                        {d}
                      </td>
                      <td className="px-3 py-5  text-[#667085] font-[400] text-[14px]">
                        {window.location.origin}
                        /21df0c3543ff3bd23cecdaf921ff4a704a113d06dc1ec21045a186ad0f2f9a42?h=
                        {organization?.key}
                        &id=[ref_id]&s={endpointData?.[d]}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default RedirectsAndKey;
