import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
const LoaderBTN = ({ msg }) => {
  return (
    <button
      className="w-[118px] text-center   px-4 py-3 text-[#666] text-[12px] font-[600] rounded border border-primary ipad:w-[90px] ipad:py-2 cursor-not-allowed flex items-center gap-[5px]"
      type="button"
    >
      <CircularProgress
        color="primary"
        className=" items-center text-center w-[100%] text-[12px]"
        size={20}
      />
      {msg}
    </button>
  );
};

export default LoaderBTN;
