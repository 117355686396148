import React, { useEffect } from "react";
import AccessRemovedSVG from "../assets/accessRemoved.svg";
import { useGlobalContext } from "../Contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
const AccountDisabled = () => {
  const { user } = useGlobalContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (user?.accountStatus === "active") {
      navigate("/");
    }
  }, [user?.accountStatus]);
  return (
    <div className=" flex  items-center justify-center h-screen w-full ">
      <div className="w-[40%]">
        <img src={AccessRemovedSVG} alt="AccessRemovedSVG" />
        <div className="text-center space-y-2">
          <h1 className="font-[600] text-[28px] text-[#000000]">
            Access Denied
          </h1>
          <p className="font-[400] text-[18px] text-[#000000]">
            Your account has been deactivated please contact your manager
          </p>
        </div>
      </div>
    </div>
  );
};

export default AccountDisabled;
