import axios from "axios";
import { BASE_URL } from "../../Utlils";

export const getSurveysForSurveyGroup = async (id, searchNumber) => {
  try {
    let data = await axios(
      `${BASE_URL}/survey/survey-group-surveys/${id}?searchText=${searchNumber}`
    );
    let newData = data.data;
    return newData;
  } catch (error) {
    // console.log(error);
    console.log(
      "something went wrong while fetching the surveys for survey groups"
    );
    throw error;
  }
};
export const getSurveyGroups = async (id, page, rows, searchText) => {
  try {
    let data = await axios.get(
      `${BASE_URL}/survey-group/organization/${id}?searchText=${searchText}&page=${
        Number(page) + 1
      }&rows=${rows}`
    );
    return data.data;
  } catch (error) {
    // console.log(error);
    console.log("something went wrong while fetching the survey groups");
    throw error;
  }
};
export const getSingleSurveyGroup = async (id) => {
  try {
    let data = await axios.get(`${BASE_URL}/survey-group/one?id=${id}`);
    return data.data;
  } catch (error) {
    console.log("something went wrong while fetching the single survey group");
    throw error;
  }
};
