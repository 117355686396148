import React, { useEffect, useState } from "react";
import PaymentSuccessIcon from "../../assets/paymentSuccess.svg";
import MiratsLogo from "../../assets/MiratsLogo.svg";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../Utlils";
import ReactConfetti from "react-confetti";

const Success = () => {
  const { id } = useParams();
  const [transactionDetails, setTransactionDetails] = useState({
    loading: true,
    error: false,
  });
  const getTransactionDetails = async () => {
    try {
      setTransactionDetails((prev) => ({
        ...prev,
        loading: true,
        error: false,
      }));
      let data = await axios.get(`${BASE_URL}/transaction/${id}`);
      setTransactionDetails((prev) => ({ ...prev, data: data.data }));
    } catch (error) {
      console.log(error);
      setTransactionDetails((prev) => ({ ...prev, error: true }));
    } finally {
      setTransactionDetails((prev) => ({ ...prev, loading: false }));
    }
  };
  useEffect(() => {
    if (id) {
      getTransactionDetails();
    }
  }, [id]);
  return (
    <div className="min-h-screen flex items-center justify-center ">
      {transactionDetails?.loading ? (
        <div
          className="w-[50px] h-[50px] bg-transparent border-[5px] border-blue-700 border-t-transparent App-logo rounded-full"
          style={{ backgroundColor: "transparent !important" }}
        ></div>
      ) : transactionDetails?.error ? (
        <div className="space-y-5 flex flex-col items-center justify-center">
          <h1 className="text-[20px] font-[500]">
            Unable to find the invoice details
          </h1>
          <button
            className="p-[9.3px_15.5px] border border-[#12B76A] bg-[#12B76A] rounded-[6px] text-[#FFFFFF] font-[500] w-[200px]"
            onClick={(e) => {
              window.location.replace(window.location.origin);
            }}
          >
            Back to Dashboard
          </button>
        </div>
      ) : (
        <>
          <ReactConfetti
            style={{
              width: "100%",
              height: "100vh",
              position: "absolute",
              zIndex: -100,
            }}
          />
          <div className="w-[40%] shadow-sm border rounded-[24px] p-[32px] flex flex-col bg-white items-center justify-center gap-[20px]   ">
            <div className="flex items-center flex-col justify-center space-y-[20px]">
              <img
                src={PaymentSuccessIcon}
                alt="PaymentSuccessIcon"
                className="w-[120px] h-[120px] object-contain rounded-full "
              />
              <div className="space-y-[10px]">
                <h1 className="text-[#12B76A] font-[700] text-[26px] text-center">
                  Payment successfully
                </h1>
                <p className="text-[#475467] font-[400] text-[14px]">
                  We are pleased to inform that your payment has been
                  successfully executed
                </p>
              </div>
            </div>
            <div className=" w-full border border-[#D0D5DD] rounded-[6px] p-[12px] space-y-[5px]">
              <h1 className="text-[#101828] font-[500] text-[14px]">
                Order Review
              </h1>
              <div className="flex items-center justify-between">
                <h1 className="font-[400] text-[12px] text-[#101828]">
                  Amount
                </h1>
                <p className="text-[#667085] font-[700] text-[12px]">
                  $ {transactionDetails?.data?.amount || 0}
                </p>
              </div>
              <div className="flex items-center justify-between">
                <h1 className="font-[400] text-[12px] text-[#101828]">Name</h1>
                <p className="text-[#667085] font-[400] text-[12px]">
                  {transactionDetails?.data?.name || "-"}
                </p>
              </div>
              <div className="flex items-center justify-between">
                <h1 className="font-[400] text-[12px] text-[#101828]">Email</h1>
                <p className="text-[#667085] font-[400] text-[12px]">
                  {transactionDetails?.data?.email || "-"}
                </p>
              </div>
            </div>
            <div className="mt-[12px] w-full flex items-center justify-between gap-[18px]">
              <a
                href={transactionDetails?.data?.receiptURL}
                target="_blank"
                className="p-[9.3px_15.5px] text-center border border-[#101828] rounded-[6px] text-[#101828] font-[500] w-1/2"
              >
                Download Receipt
              </a>
              <button
                className="p-[9.3px_15.5px] border border-[#12B76A] bg-[#12B76A] rounded-[6px] text-[#FFFFFF] font-[500] w-1/2"
                onClick={(e) => {
                  window.location.replace(window.location.origin);
                }}
              >
                Back to Dashboard
              </button>
            </div>
            <h1 className="text-[#00000080] text-[14px]">
              Questions?{" "}
              <a
                href="mailto:diysupport@miratsinsights.com"
                className="text-[#1570EF] font-[400] underline decoration-[#1570EF] underline-offset-4 cursor-pointer"
              >
                {" "}
                Send us an email.
              </a>
            </h1>
          </div>
        </>
      )}
      <footer className="fixed bottom-0 w-full  p-[14px_32px] flex items-center justify-between">
        <div className="space-y-[8px]">
          <img
            src={MiratsLogo}
            alt="Logo"
            className=" w-[100px] h-[20px] object-contain"
          />
          <p className="text-[#98A2B3] text-[12px] font-[500]">
            Copyright 2024 Mirats United Pvt Ltd all rights reserved.
          </p>
        </div>
        <div className="flex items-center gap-[25px]">
          <FaFacebookF />
          <FaTwitter />
          <FaLinkedinIn />
          <FaInstagram />
        </div>
      </footer>
    </div>
  );
};

export default Success;
