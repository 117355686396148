import React from "react";
import { getDashboardTableData } from "../../../Actions/DashboardActions";
import { useQuery } from "@tanstack/react-query";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import NoData from "../../../components/NoData";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";

const MySurveys = ({ user }) => {
  const navigate = useNavigate();
  const {
    data: dashboardTableData,
    isLoading: dashboardTableDataLoading,
    isError: dashboardTableDataError,
  } = useQuery({
    queryFn: () => getDashboardTableData(user?.organizationId),
    queryKey: ["dashboardSurveyTable", user?.organizationId],
    enabled: !!user?.organizationId,
    staleTime: 10000,
    cacheTime: 15000,
  });
  // console.log({
  //   dashboardTableDataLoading,
  //   dashboardTableData,
  //   dashboardTableDataError,
  // });
  return (
    <div className="w-[60%] dashboardShadow h-[360px]">
      <div className="mx-5 py-6  flex items-center justify-between">
        <h1 className="text-[#333] text-[16px] font-[500] inter">My Surveys</h1>
        <p
          className="text-[#ACACAC] text-[14px] font-[400] cursor-pointer hover:text-primary hover:font-[500] hover:underline underline-offset-[3px] hover:transition-all duration-500 ease-linear decoration-2 overflow-hidden"
          onClick={(e) => navigate("/surveys")}
        >
          See all
        </p>
      </div>
      <div className=" mx-5 w-[95%]  max-h-[340px] overflow-y-auto containerScroll ">
        <table className=" w-full  ">
          <thead className="">
            <tr className=" border-b border-[#E0E0E0]">
              {[
                "Surveys",
                "Progress",
                "Completes",
                "AVG. LOI",
                "Conv. Rate",
                "I. R",
                "Avg. CPI",
                "Drop-off",
                "EPC",
              ]?.map((data, ind) => (
                <td
                  className="text-[#999999] pb-3 text-[12px] font-[500]"
                  key={ind}
                >
                  {data}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {dashboardTableDataLoading ? (
              <tr>
                <td colSpan={9}>
                  <div className="w-full text-center py-6 text-[#666] font-[500] text-[18px] flex items-center justify-center flex-col space-y-5 h-[250px]">
                    <h1 className="text-[#666666] text-[14px] ml-8 font-[300] tracking-wide flex gap-[10px] items-center">
                      Loading...
                    </h1>
                  </div>
                </td>
              </tr>
            ) : dashboardTableDataError ? (
              <tr>
                <td colSpan={9}>
                  <div className="w-full text-center py-6 text-[#666] font-[500] text-[18px] flex items-center justify-center flex-col space-y-5 h-[250px]">
                    <h1 className="text-[#e12121] text-[14px] ml-8 font-[300] tracking-wide flex gap-[10px] items-center">
                      Something went wrong !
                    </h1>
                  </div>
                </td>
              </tr>
            ) : dashboardTableData?.length ? (
              dashboardTableData?.map((stats, key) => (
                <tr className="" key={key}>
                  <td
                    className="text-[#333333] font-[400] text-[12px] py-4 cursor-pointer"
                    onClick={(e) => navigate(`/surveys/${stats?._id}/details`)}
                  >
                    <Tooltip title={stats?.surveyName}>
                      <h1 className="text-[#333333] font-[400] text-[12px] text-ellipsis w-[100px] whitespace-nowrap hover:text-primary hover:font-[500] hover:underline underline-offset-[3px] hover:transition-all duration-500 ease-linear decoration-2 overflow-hidden">
                        {stats?.surveyName || "-"}
                      </h1>
                    </Tooltip>
                  </td>
                  <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                    {" "}
                    {stats?.completes || 0} /{" "}
                    {stats?.expectedMatrix?.expectedCompletes || 0}
                  </td>
                  <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                    {" "}
                    {stats?.completes || 0}
                  </td>
                  <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                    {" "}
                    {stats?.avgLoi
                      ? `${Number(stats?.avgLoi)?.toFixed(2)} min`
                      : "0 min"}
                  </td>
                  <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                    {" "}
                    {stats?.conversion
                      ? `${Number(stats?.conversion)?.toFixed(2)} %`
                      : "0 %"}
                  </td>
                  <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                    {" "}
                    {stats?.IR ? `${Number(stats?.IR)?.toFixed(2)} %` : `0 %`}
                  </td>
                  <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                    {" "}
                    {stats?.avgCPI
                      ? `$ ${Number(stats?.avgCPI)?.toFixed(2)}`
                      : `$ 0`}
                  </td>
                  <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                    {" "}
                    {stats?.dropRate
                      ? `${Number(stats?.dropRate)?.toFixed(2)} %`
                      : `0 %`}
                  </td>
                  <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                    {" "}
                    {stats?.epc ? Number(stats?.epc)?.toFixed(2) : 0}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9}>
                  <NoData />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MySurveys;
