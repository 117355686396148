import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL, PUBLISHER_URL } from "../Utlils";
import CryptoJS from "crypto-js";
import jsSHA from "jssha";
import ResponseRecordedErrorPage from "./ResponseRecordedErrorPage";
import surveyCompleted from "../assets/surveyComplete.svg";
import surveyInterrupted from "../assets/surveyInterrupted.svg";
import maxResponse from "../assets/maxResponse.svg";
import terminated from "../assets/terminated.svg";
import TooManyRequest from "../assets/TooManyRequest.svg";
import Hashids from "hashids";
function msToTime(milliseconds) {
  // Convert milliseconds to seconds
  let seconds = Math.floor(milliseconds / 1000);

  // Calculate hours, minutes, and remaining seconds
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  // Format into "hh:mm:ss"
  const formattedTime = `${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

  return formattedTime;
}
function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
const EndPoint = () => {
  const [errorPage, setErrorPage] = useState(false);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const { id, s, h } = Object.fromEntries(urlSearchParams.entries());
  const hashids = new Hashids("My Project");
  const decodedID = hashids.decode(id.includes("-") ? id.split("-")[1] : id);
  const renderEndScreen = () => {
    switch (s) {
      case "10":
        return (
          <>
            <div className="flex justify-center items-center ml-[150px] tablet: tablet:flex-col-reverse tablet:mx-[88px] ipad:mx-6">
              <section className="tablet:mt-[80px] ipad:mt-[30px]">
                <h1 className="text-[32px] font-[700] mb-7 ipad:text-[24px]">
                  You’ve successfully completed the survey.
                </h1>
                <p className="text-[19px] text-[#646464] leading-relaxed ipad:text-[16px]">
                  Congratulations! You’ve successfully completed the survey,
                  your rewards will be credited in your panel account. Thank you
                  very much ;)
                </p>
                <button className="mt-[62px] bg-primary hover:bg-[#1e64fb] px-4 py-3 text-white font-semibold rounded w-[151px] text-[14px]">
                  Contact support
                </button>
              </section>
              <img
                src={surveyCompleted}
                className="tablet:w-[304px] tablet:h-[230px]"
                alt="status illustration"
              />
            </div>
            <footer className="p-[17px_16px_17px_16px] mt-5 flex items-center justify-between bg-[#F3F3F3] w-[100%] text-[#333] absolute bottom-0">
              <p className="text-[13px] ipad:text-[6px]">
                ©2023 Mirats Quanto Private Limited.
              </p>
              <ul className="text-[12px] ipad:text-[6px] list-none font-[300] flex items-center gap-[32px] ipad:gap-1">
                <li>
                  <a href="https://consumerinsights.miratsoneservices.com/privacy-policy">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="https://consumerinsights.miratsoneservices.com/gdpr">
                    GDPR Policy
                  </a>
                </li>
                <li>
                  <a href="https://consumerinsights.miratsoneservices.com/ccpa">
                    CCPA Policy
                  </a>
                </li>
                <li>
                  <a href="https://consumerinsights.miratsoneservices.com/terms-of-service">
                    Terms of Use
                  </a>
                </li>
              </ul>
            </footer>
          </>
        );
      case "20":
        return (
          <>
            <div className="flex justify-center items-center ml-[150px] tablet: tablet:flex-col-reverse tablet:mx-[88px] ipad:mx-6">
              <section className="tablet:mt-[80px] ipad:mt-[30px]">
                <h1 className="text-[32px] font-[700] mb-7 ipad:text-[24px]">
                  You’re terminated
                </h1>
                <p className="text-[19px] text-[#646464] leading-relaxed ipad:text-[16px]">
                  Unfortunately, you’re not able to qualify the survey, we
                  appreciate your willingness to take the survey. Thank you very
                  much for your precious time.
                </p>
                <button className="mt-[62px] bg-primary hover:bg-[#1e64fb] px-4 py-3 text-white font-semibold rounded w-[151px] text-[14px]">
                  Contact support
                </button>
              </section>
              <img
                src={terminated}
                className="tablet:w-[304px] tablet:h-[230px]"
                alt="status illustration"
              />
            </div>
            <footer className="p-[17px_16px_17px_16px] mt-5 flex items-center justify-between bg-[#F3F3F3] w-[100%] text-[#333] absolute bottom-0">
              <p className="text-[13px] ipad:text-[6px]">
                ©2023 Mirats Quanto Private Limited.
              </p>
              <ul className="text-[12px] ipad:text-[6px] list-none font-[300] flex items-center gap-[32px] ipad:gap-1">
                <li>
                  <a href="https://consumerinsights.miratsoneservices.com/privacy-policy">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="https://consumerinsights.miratsoneservices.com/gdpr">
                    GDPR Policy
                  </a>
                </li>
                <li>
                  <a href="https://consumerinsights.miratsoneservices.com/ccpa">
                    CCPA Policy
                  </a>
                </li>
                <li>
                  <a href="https://consumerinsights.miratsoneservices.com/terms-of-service">
                    Terms of Use
                  </a>
                </li>
              </ul>
            </footer>
          </>
        );
      case "40":
        return (
          <>
            <div className="flex justify-center items-center ml-[150px] tablet: tablet:flex-col-reverse tablet:mx-[88px] ipad:mx-6">
              <section className="tablet:mt-[80px] ipad:mt-[30px]">
                <h1 className="text-[32px] font-[700] mb-7 ipad:text-[24px]">
                  We received maximum responses.
                </h1>
                <p className="text-[19px] text-[#646464] leading-relaxed ipad:text-[16px]">
                  Unfortunately, we are not able to let you proceed with this
                  survey as we’ve already reached maximum number of completes.
                  Thank you very much for your participation.
                </p>
                <button
                  //   onClick={() => setIndex(index + 1)}
                  className="mt-[62px] bg-primary hover:bg-[#1e64fb] px-4 py-3 text-white font-semibold rounded w-[151px] text-[14px]"
                >
                  Contact support
                </button>
              </section>
              <img
                src={maxResponse}
                className="tablet:w-[304px] tablet:h-[230px]"
                alt="status illustration"
              />
            </div>
            <footer className="p-[17px_16px_17px_16px] mt-5 flex items-center justify-between bg-[#F3F3F3] w-[100%] text-[#333] absolute bottom-0">
              <p className="text-[13px] ipad:text-[6px]">
                ©2023 Mirats Quanto Private Limited.
              </p>
              <ul className="text-[12px] ipad:text-[6px] list-none font-[300] flex items-center gap-[32px] ipad:gap-1">
                <li>
                  <a href="https://consumerinsights.miratsoneservices.com/privacy-policy">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="https://consumerinsights.miratsoneservices.com/gdpr">
                    GDPR Policy
                  </a>
                </li>
                <li>
                  <a href="https://consumerinsights.miratsoneservices.com/ccpa">
                    CCPA Policy
                  </a>
                </li>
                <li>
                  <a href="https://consumerinsights.miratsoneservices.com/terms-of-service">
                    Terms of Use
                  </a>
                </li>
              </ul>
            </footer>
          </>
        );
      case "30":
        return (
          <>
            <div className="flex justify-center items-center ml-[150px] tablet: tablet:flex-col-reverse tablet:mx-[88px] ipad:mx-6">
              <section className="tablet:mt-[80px] ipad:mt-[30px]">
                <h1 className="text-[32px] font-[700] mb-7 ipad:text-[24px]">
                  We detected something unusual.
                </h1>
                <p className="text-[19px] text-[#646464] leading-relaxed ipad:text-[16px]">
                  Unfortunately, we won’t let you proceed here as our system
                  detected some unusual activity on our survey pages.
                </p>
                <button className="mt-[62px] bg-primary hover:bg-[#1e64fb] px-4 py-3 text-white font-semibold rounded w-[151px] text-[14px]">
                  Contact support
                </button>
              </section>
              <img
                src={surveyInterrupted}
                className="tablet:w-[304px] tablet:h-[230px]"
                alt="status illustration"
              />
            </div>
            <footer className="p-[17px_16px_17px_16px] mt-5 flex items-center justify-between bg-[#F3F3F3] w-[100%] text-[#333] absolute bottom-0">
              <p className="text-[13px] ipad:text-[6px]">
                ©2023 Mirats Quanto Private Limited.
              </p>
              <ul className="text-[12px] ipad:text-[6px] list-none font-[300] flex items-center gap-[32px] ipad:gap-1">
                <li>
                  <a href="https://consumerinsights.miratsoneservices.com/privacy-policy">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="https://consumerinsights.miratsoneservices.com/gdpr">
                    GDPR Policy
                  </a>
                </li>
                <li>
                  <a href="https://consumerinsights.miratsoneservices.com/ccpa">
                    CCPA Policy
                  </a>
                </li>
                <li>
                  <a href="https://consumerinsights.miratsoneservices.com/terms-of-service">
                    Terms of Use
                  </a>
                </li>
              </ul>
            </footer>
          </>
        );
      case "429":
        return (
          <>
            <div className="flex justify-center items-center ml-[150px] tablet: tablet:flex-col-reverse tablet:mx-[88px] ipad:mx-6 gap-[40px] ">
              <section className="tablet:mt-[80px] ipad:mt-[30px] ">
                <h1 className="text-[32px] font-[700] mb-7 ipad:text-[24px]">
                  Oops, we hit a snag!
                </h1>
                <p className="text-[19px] text-[#646464] leading-relaxed ipad:text-[16px]">
                  We're really sorry, but it seems like we have an unusually
                  high number of users accessing our website right now.
                  Unfortunately, we've reached our maximum number of sessions
                  limit.
                </p>
                <p className="text-[16px] font-[600] text-[#292929] leading-relaxed ipad:text-[16px] mt-4">
                  Why am I seeing this?
                </p>
                <p className="text-[19px] text-[#646464] leading-relaxed ipad:text-[16px] ">
                  Our website can only handle a certain number of users at once
                  to ensure everyone gets the smoothest and fastest experience
                  possible. It looks like we've hit that limit, but don't worry
                  - spaces open up fast!
                </p>
                <p className="text-[16px] font-[600] text-[#292929] leading-relaxed ipad:text-[16px] mt-4">
                  What can I do?
                </p>
                <p className="text-[19px] text-[#646464] leading-relaxed ipad:text-[16px] ">
                  Please wait for a few minutes before trying again. We'll be
                  ready to welcome you back as soon as some space frees up!
                </p>
                {/* <button className="mt-[62px] bg-primary hover:bg-[#1e64fb] px-4 py-3 text-white font-semibold rounded w-[151px] text-[14px]">
                      Contact support
                    </button> */}
              </section>
              <img
                src={TooManyRequest}
                className="tablet:w-[304px] tablet:h-[130px] w-[450px] ipad:w-[200px] tablet:mt-[50px] "
                alt="status illustration"
              />
            </div>
            <footer className="p-[17px_16px_17px_16px] mt-5 flex items-center justify-between bg-[#F3F3F3] w-[100%] text-[#333] absolute bottom-0">
              <p className="text-[13px] ipad:text-[6px]">
                ©2023 Mirats Quanto Private Limited.
              </p>
              <ul className="text-[12px] ipad:text-[6px] list-none font-[300] flex items-center gap-[32px] ipad:gap-1">
                <li>
                  <a href="https://consumerinsights.miratsoneservices.com/privacy-policy">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="https://consumerinsights.miratsoneservices.com/gdpr">
                    GDPR Policy
                  </a>
                </li>
                <li>
                  <a href="https://consumerinsights.miratsoneservices.com/ccpa">
                    CCPA Policy
                  </a>
                </li>
                <li>
                  <a href="https://consumerinsights.miratsoneservices.com/terms-of-service">
                    Terms of Use
                  </a>
                </li>
              </ul>
            </footer>
          </>
        );
      default:
        return <h1>Something Went Wrong</h1>;
    }
  };
  const getSuppier = async (supplierId) => {
    let data = await axios.get(
      `${BASE_URL}/supplier/one?id=${String(supplierId)}`
    );
    return data.data;
  };
  useEffect(() => {
    if (!id) {
      return;
    }

    let gamma = localStorage.getItem("gamma"); // gamma = alpha means test session

    let order_id;
    let response = getCookie(`response-${id}`);

    if (!response) {
      console.log("inside if");
      axios
        .get(`${BASE_URL}/session/get-session-ref?refID=${id}`)
        .then((data) => {
          setCookie(`response-${id}`, true, 10);

          let sessions = data.data;

          const sd = data.data;
          // console.log(data.data);
          let survey_end_time = new Date();
          const total_survey_time = msToTime(
            new Date().getTime() - new Date(sd?.survey_start_time).getTime()
          );

          // console.log(total_survey_time);
          let body = {
            survey_end_time,
            total_survey_time,
            // client_status: parseInt(status),
            client_status: [30, 31, 35, 36, 37, 38, 23, 40]?.includes(
              parseInt(sd?.mirats_status)
            )
              ? -1
              : parseInt(s),

            is_reconciled: false,
          };
          let surveyNumber;
          axios.get(`${BASE_URL}/survey/one?id=${sd?.survey}`).then((sdata) => {
            let res = sdata.data;
            order_id = res?.order_id;
            surveyNumber = res?.surveyNumber;
            if (
              parseInt(s) === 10 &&
              parseInt(total_survey_time.split(":")[1]) <= res?.speeder_loi
            ) {
              body.client_status = 12;
            }
            axios
              .put(`${BASE_URL}/session?id=${sessions._id}`, body)
              .then((data) => {
                // console.log(
                //   "session updated with client status and total survey time"
                // );
                if (data.data == false) {
                  setErrorPage(true);
                } else {
                  res?.allocation?.map(async (supp) => {
                    // console.log(decodedID[1]);
                    if (supp?.supplierAccount == sd?.supplierAccountId) {
                      if (!supp?.global_redirect) {
                        console.log("redirecting with static redirects");
                        // special redirection for lucid
                        if (sd?.supplierAccountId === 1234567831) {
                          lucidRedirectFunction(
                            supp?.static_redirects,
                            body?.client_status === 12 ? 30 : s,
                            sd?.rid
                          );
                        } else
                          RedirectFunction(
                            body?.client_status === 12 ? 30 : s,
                            supp?.static_redirects,
                            sd?.rid,
                            sd?.survey,
                            order_id
                          );
                      } else {
                        console.log("redirecting with global redirects");
                        await getSuppier(sd?.supplierAccountId).then(
                          (supdata) => {
                            if (sd?.supplierAccountId === 1234567831) {
                              lucidRedirectFunction(
                                supdata?.global_redirects,
                                body?.client_status === 12 ? 30 : s,
                                sd?.rid
                              );
                            } else if (sd?.supplierAccountId === 1234567926) {
                              TCtaskFunction(
                                supdata?.global_redirects,
                                body?.client_status === 12 ? 30 : s,
                                sd?.rid,
                                decodedID[0],
                                order_id
                              ); // special redirection for Chengdu
                            } else if (sd?.supplierAccountId === 1234567924) {
                              ChengduTaskFunction(
                                supdata?.global_redirects,
                                body?.client_status === 12 ? 30 : s,
                                sd?.rid,
                                decodedID[0]
                              );
                              // special redirection for ChengduTaskFunction
                            } else {
                              console.log("nredirect");
                              RedirectFunction(
                                body?.client_status === 12 ? 30 : s,
                                supdata?.global_redirects,
                                sd?.rid,
                                sd?.survey,
                                order_id
                              );
                            }
                          }
                        );
                      }
                    }
                  });
                }
              })
              .catch((err) => console.log(err.message));
          });
          // console.log("=>", sessions?.publisherProjectId);
          if (sessions?.publisherProjectId) {
            axios
              .get(`${PUBLISHER_URL}/project/${sessions?.publisherProjectId}`)
              .then((response) => {
                const pubData = response?.data?.data;
                // console.log("PubData=>", pubData);
                axios
                  .put(
                    `${PUBLISHER_URL}/session/update-by-ref/${sessions?.publisherProjectId}/${sessions?.rid}`,
                    {
                      startTime: sessions?.survey_start_time,
                      endTime: survey_end_time,
                      totalTime: total_survey_time,
                      status: s,
                    }
                  )
                  .then((updPubSess) => {
                    const pubRedirectMap = {
                      10: "completes",
                      30: "qualityTerminate",
                      40: "quotaFull",
                      20: "terminate",
                    };
                    const replaceData = {
                      "[%rid%]": sessions?.rid || "defaultRid",
                      "[%pub_refid%]": sessions?.rid || "defaultPubRefId",
                      "[%survey_id%]":
                        surveyNumber || sessions?.rid || "defaultSurveyId",
                      "[%surveyNumber%]":
                        surveyNumber || sessions?.rid || "defaultSurveyId",
                    };
                    const redirectKey = pubRedirectMap[s];
                    const redirectUrl = pubData?.redirects?.[redirectKey];
                    if (Object.keys(pubData?.callbackURLs || {})?.length) {
                      let modifiedURL;
                      if (s == 10) {
                        const successCallBack = pubData?.callbackURLs?.success;
                        if (successCallBack) {
                          modifiedURL = successCallBack
                            ?.replace("[%rid%]", sd.rid)
                            ?.replace("[%pub_refid%]", sd.rid)
                            ?.replace("[%survey_id%]", surveyNumber || sd.rid);
                          modifiedURL = new URL(modifiedURL);
                          let myUrlParams = new URLSearchParams(
                            modifiedURL.search
                          );
                          if (myUrlParams.has("source_id") && data?.source_id) {
                            myUrlParams.set("source_id", data.source_id);
                          }
                          if (myUrlParams.has("utm_id") && data?.utm_id) {
                            myUrlParams.set("utm_id", data.utm_id);
                          }
                          modifiedURL.search = myUrlParams.toString();
                        } else {
                          console.log(
                            "Publisher success callback url is not set"
                          );
                        }
                      } else {
                        const failureCallBack = pubData?.callbackURLs?.failure;
                        if (failureCallBack) {
                          modifiedURL = failureCallBack
                            ?.replace("[%rid%]", sd.rid)
                            ?.replace("[%pub_refid%]", sd.rid)
                            ?.replace("[%survey_id%]", surveyNumber || sd.rid);
                          modifiedURL = new URL(modifiedURL);
                          let myUrlParams = new URLSearchParams(
                            modifiedURL.search
                          );
                          if (myUrlParams.has("source_id") && data?.source_id) {
                            myUrlParams.set("source_id", data.source_id);
                          }
                          if (myUrlParams.has("utm_id") && data?.utm_id) {
                            myUrlParams.set("utm_id", data.utm_id);
                          }
                          modifiedURL.search = myUrlParams.toString();
                        } else {
                          console.log(
                            "Publisher failure callback url is not set"
                          );
                        }
                      }
                      if (
                        modifiedURL ||
                        modifiedURL !== undefined ||
                        modifiedURL !== null
                      ) {
                        if (pubData?.callbackURLs?.requestType?.value) {
                          fetch(modifiedURL.toString(), {
                            method: pubData?.callbackURLs?.requestType?.label,
                            headers: {
                              "Content-Type": "application/json",
                            },
                          })
                            .then((res) => {
                              if (!res.ok) {
                                console.log(
                                  `Error calling ${pubData?.callbackURLs?.requestType?.label} publisher callback URL`
                                );
                              } else {
                                console.log(
                                  `${pubData?.callbackURLs?.requestType?.label} Publisher callback URL called successfully!`
                                );
                              }
                            })
                            .catch((er) => {
                              console.log(
                                `Error calling ${pubData?.callbackURLs?.requestType?.label} publisher callback URL`
                              );
                            });
                          return;
                        } else {
                          axios
                            .get(modifiedURL.toString())
                            .then((res) => {
                              console.log(
                                "GET Publisher callback URL called successfully!"
                              );
                            })
                            .catch((er) => {
                              console.log(
                                "Error calling GET publisher callback URL"
                              );
                            });
                          axios
                            .get(modifiedURL.toString())
                            .then((res) => {
                              console.log(
                                "POST Publisher callback URL called successfully!"
                              );
                            })
                            .catch((er) => {
                              console.log(
                                "Error calling POST publisher callback URL"
                              );
                            });
                        }
                      }
                    } else {
                      console.log(
                        "Callback URL(server side url) not set in publisher's project."
                      );
                    }
                    if (redirectUrl) {
                      let modifiedUrl = redirectUrl;

                      modifiedUrl = modifiedUrl.replace(
                        /\[%[a-zA-Z_]+\%\]/g,
                        (match) => {
                          return replaceData[match] || match;
                        }
                      );
                      let url = new URL(modifiedUrl);
                      let myUrlParams = new URLSearchParams(url.search);
                      if (myUrlParams.has("source_id")) {
                        myUrlParams.set("source_id", data?.source_id);
                      }
                      if (myUrlParams.has("utm_id")) {
                        myUrlParams.set("utm_id", data?.utm_id);
                      }
                      url.search = myUrlParams.toString();
                      const newURL = url.toString();
                      if (
                        sd?.publisherOrganizationId ==
                        process.env.REACT_APP_THEOREM_REACH_ID
                      ) {
                        axios
                          .post(
                            `${BASE_URL}/redirect/get-theorem-reach-redirect`,
                            { baseUrl: newURL }
                          )
                          .then((res) => {
                            let redirectData = res.data;
                            let hashedURL = redirectData?.signedUrl;
                            console.log(
                              `Redirecting theorem publisher to hash URL ${hashedURL}`
                            );
                            window.location.href = hashedURL;
                            return;
                          })
                          .catch((er) => {
                            console.log(
                              "Error in hashing theorem publisher redirects"
                            );
                          });
                      } else if (
                        sd?.publisherOrganizationId ==
                        process.env.REACT_APP_Tcsurvey_ID
                      ) {
                        axios
                          .post(`${BASE_URL}/redirect/get-Tcsurvey-redirect`, {
                            baseUrl: newURL,
                          })
                          .then((res) => {
                            let redirectData = res.data;
                            let hashedURL = redirectData?.signedUrl;
                            console.log(
                              `Redirecting Tcsurvey publisher to hash URL ${hashedURL}`
                            );
                            window.location.href = hashedURL;
                            return;
                          })
                          .catch((er) => {
                            console.log("Error in hashing publisher redirects");
                          });
                      } else {
                        console.log("URL=>", newURL);
                        window.location.href = newURL;
                      }
                    } else {
                      console.log(
                        `Publisher's ${redirectKey} redirect not set or invalid.`
                      );
                    }
                  })
                  .catch((er) => {
                    console.log(er);
                    console.log("unable to update publisher data");
                  });
              })
              .catch((er) => console.log("error in getting publisher data"));
          } else {
            console.log("no redirects");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      console.log("insdie else");
      setErrorPage(true);
    }
  }, [decodedID]);
  const lucidRedirectFunction = (redirects, status, rid) => {
    let finalLink;
    switch (status) {
      case "10":
        finalLink = redirects?.complete + "&hash=" + calcHMAC(finalLink + "&");
        break;
      case "20":
        finalLink = redirects?.terminate + "&hash=" + calcHMAC(finalLink + "&");
        break;
      case "30":
        finalLink =
          redirects?.quality_terminate + "&hash=" + calcHMAC(finalLink + "&");
        break;
      case "40":
        finalLink =
          redirects?.quota_full + "&hash=" + calcHMAC(finalLink + "&");
        break;
      default:
        return;
    }
    finalLink =
      finalLink.split("[%rid%]")[0] +
      rid +
      (finalLink.split("[%rid%]")[1] ? finalLink.split("[%rid%]")[1] : "");
    window.location.href = finalLink;
  };
  function hashing_SHA1(key, url) {
    const encoded_key = CryptoJS.enc.Utf8.parse(key);
    const encoded_URL = CryptoJS.enc.Utf8.parse(url);
    const hashed = CryptoJS.HmacSHA1(encoded_URL, encoded_key);
    const digested_hash = CryptoJS.enc.Hex.parse(hashed.toString());
    const base64_encoded_result = CryptoJS.enc.Base64.stringify(digested_hash);
    const final_result = base64_encoded_result
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "");

    return final_result;
  }
  function calcHMAC(text) {
    try {
      var hmacText = text;
      var hmacTextType = "TEXT";
      var hmacKeyInput = "036740c861d448a1aa8d12b1550eba";
      var hmacKeyInputType = "TEXT";
      var hmacVariant = "SHA-1";
      var hmacOutputType = "B64";
      //var hmacOutput = document.getElementById("hmacOutputText");

      var hmacObj = new jsSHA(hmacVariant, hmacTextType);
      hmacObj.setHMACKey(hmacKeyInput, hmacKeyInputType);
      hmacObj.update(hmacText);
      let output = hmacObj
        .getHMAC(hmacOutputType)
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/\=/g, "");
      return output;
    } catch (e) {
      //hmacOutput.value = e.message;

      console.log(e.message);
    }
  }
  function hsMD5(rid, status_m, key) {
    // const encoded_key = CryptoJS.enc.Utf8.parse(key);
    // const encoded_URL = CryptoJS.enc.Utf8.parse(url);
    const __h1 = CryptoJS.MD5(rid + status_m + key);
    const final_hashed_key = __h1.toString(CryptoJS.enc.Hex);
    // const digested_hash = CryptoJS.enc.Hex.parse(hashed.toString());
    // const base64_encoded_result = CryptoJS.enc.Base64.stringify(digested_hash);
    // const final_result = base64_encoded_result.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');

    return final_hashed_key;
  }
  const RedirectFunction = (status, redirects, rid, surveyID, order_id) => {
    let x, y;

    if (!redirects) {
      // console.log("redirects not found...");
      return;
    }

    switch (String(status)) {
      case "10":
        x = redirects?.complete;
        if (redirects?.complete.includes("surveyNumber")) {
          x =
            x.split("[%surveyNumber%]")[0] +
            surveyID +
            x.split("[%surveyNumber%]")[1];
        }

        if (redirects?.complete.includes("orderId")) {
          x = x.split("[%orderId%]")[0] + order_id + x.split("[%orderId%]")[1];
        }

        y =
          x.split("[%rid%]")[0] +
          rid +
          (x.split("[%rid%]")[1] ? x.split("[%rid%]")[1] : "");
        break;
      case "20":
        x = redirects?.terminate;
        if (redirects?.terminate.includes("surveyNumber")) {
          x =
            x.split("[%surveyNumber%]")[0] +
            surveyID +
            x.split("[%surveyNumber%]")[1];
        }

        if (redirects?.terminate.includes("orderId")) {
          x = x.split("[%orderId%]")[0] + order_id + x.split("[%orderId%]")[1];
        }

        y =
          x.split("[%rid%]")[0] +
          rid +
          (x.split("[%rid%]")[1] ? x.split("[%rid%]")[1] : "");
        break;
      case "30":
        if (redirects?.hasOwnProperty("quality_terminate")) {
          x = redirects?.quality_terminate;
          if (redirects?.quality_terminate.includes("surveyNumber")) {
            x =
              x.split("[%surveyNumber%]")[0] +
              surveyID +
              x.split("[%surveyNumber%]")[1];
          }
          if (redirects?.quality_terminate.includes("orderId")) {
            x =
              x.split("[%orderId%]")[0] + order_id + x.split("[%orderId%]")[1];
          }
          y =
            x.split("[%rid%]")[0] +
            rid +
            (x.split("[%rid%]")[1] ? x.split("[%rid%]")[1] : "");
        } else {
          x = redirects?.terminate;
          if (redirects?.terminate.includes("surveyNumber")) {
            x =
              x.split("[%surveyNumber%]")[0] +
              surveyID +
              x.split("[%surveyNumber%]")[1];
          }
          if (redirects?.terminate.includes("orderId")) {
            x =
              x.split("[%orderId%]")[0] + order_id + x.split("[%orderId%]")[1];
          }
          y =
            x.split("[%rid%]")[0] +
            rid +
            (x.split("[%rid%]")[1] ? x.split("[%rid%]")[1] : "");
        }
        break;
      case "40":
        x = redirects?.quota_full;
        if (redirects?.quota_full.includes("surveyNumber")) {
          x =
            x.split("[%surveyNumber%]")[0] +
            surveyID +
            x.split("[%surveyNumber%]")[1];
        }

        if (redirects?.quota_full.includes("orderId")) {
          x = x.split("[%orderId%]")[0] + order_id + x.split("[%orderId%]")[1];
        }

        y = x.split("[%rid%]")[0] + rid + x.split("[%rid%]")[1];
        break;
      default:
        return;
    }
    window.location.href = y;
  };
  const TCtaskFunction = (redirects, status, rid, surveyID, order_id) => {
    let finalLink, encrypt, x, y;
    let key = "7e08091a73b14e034889265e41ba796f91c766ad";
    switch (status) {
      case "10":
        x = redirects?.complete;
        if (redirects?.complete.includes("surveyNumber")) {
          x =
            x.split("[%surveyNumber%]")[0] +
            surveyID +
            x.split("[%surveyNumber%]")[1];
        }

        if (redirects?.complete.includes("orderId")) {
          x = x.split("[%orderId%]")[0] + order_id + x.split("[%orderId%]")[1];
        }

        y =
          x.split("[%rid%]")[0] +
          rid +
          (x.split("[%rid%]")[1] ? x.split("[%rid%]")[1] : "");

        let hash22112 = hashing_SHA1(key, y);
        finalLink = y + "&hash=" + hash22112;
        break;
      case "20":
        x = redirects?.terminate;
        if (redirects?.terminate.includes("surveyNumber")) {
          x =
            x.split("[%surveyNumber%]")[0] +
            surveyID +
            x.split("[%surveyNumber%]")[1];
        }

        if (redirects?.terminate.includes("orderId")) {
          x = x.split("[%orderId%]")[0] + order_id + x.split("[%orderId%]")[1];
        }

        y =
          x.split("[%rid%]")[0] +
          rid +
          (x.split("[%rid%]")[1] ? x.split("[%rid%]")[1] : "");

        let hash221 = hashing_SHA1(key, y);
        finalLink = y + "&hash=" + hash221;

        break;
      case "30":
        if (redirects?.hasOwnProperty("quality_terminate")) {
          x = redirects?.quality_terminate;
          if (redirects?.quality_terminate.includes("surveyNumber")) {
            x =
              x.split("[%surveyNumber%]")[0] +
              surveyID +
              x.split("[%surveyNumber%]")[1];
          }

          if (redirects?.quality_terminate.includes("orderId")) {
            x =
              x.split("[%orderId%]")[0] + order_id + x.split("[%orderId%]")[1];
          }

          y =
            x.split("[%rid%]")[0] +
            rid +
            (x.split("[%rid%]")[1] ? x.split("[%rid%]")[1] : "");
        } else {
          x = redirects?.terminate;
          if (redirects?.terminate.includes("surveyNumber")) {
            x =
              x.split("[%surveyNumber%]")[0] +
              surveyID +
              x.split("[%surveyNumber%]")[1];
          }

          if (redirects?.terminate.includes("orderId")) {
            x =
              x.split("[%orderId%]")[0] + order_id + x.split("[%orderId%]")[1];
          }

          y =
            x.split("[%rid%]")[0] +
            rid +
            (x.split("[%rid%]")[1] ? x.split("[%rid%]")[1] : "");
        }
        let hash2211 = hashing_SHA1(key, y);
        finalLink = y + "&hash=" + hash2211;
        break;
      case "40":
        x = redirects?.quota_full;
        if (redirects?.quota_full.includes("surveyNumber")) {
          x =
            x.split("[%surveyNumber%]")[0] +
            surveyID +
            x.split("[%surveyNumber%]")[1];
        }

        if (redirects?.quota_full.includes("orderId")) {
          x = x.split("[%orderId%]")[0] + order_id + x.split("[%orderId%]")[1];
        }

        y = x.split("[%rid%]")[0] + rid + x.split("[%rid%]")[1];
        let hash22 = hashing_SHA1(key, y);
        finalLink = y + "&hash=" + hash22;
        break;
      default:
        return;
    }
    window.location.href = finalLink;
  };
  const ChengduTaskFunction = (redirects, status, rid, surveyID) => {
    let finalLink, encrypt, x, y;
    let skey = "SBy0GxAS4CbqqvnWOtW6KTks6VMvKTaX";
    switch (status) {
      case "10":
        x = redirects?.complete;
        if (redirects?.complete.includes("surveyNumber")) {
          x =
            x.split("[%surveyNumber%]")[0] +
            surveyID +
            x.split("[%surveyNumber%]")[1];
        }
        y =
          x.split("[%rid%]")[0] +
          rid +
          (x.split("[%rid%]")[1] ? x.split("[%rid%]")[1] : "");

        let hashing = hsMD5(rid, "c", skey);
        finalLink = y + "&sign=" + hashing;
        break;
      case "20":
        x = redirects?.terminate;
        if (redirects?.terminate.includes("surveyNumber")) {
          x =
            x.split("[%surveyNumber%]")[0] +
            surveyID +
            x.split("[%surveyNumber%]")[1];
        }

        y =
          x.split("[%rid%]")[0] +
          rid +
          (x.split("[%rid%]")[1] ? x.split("[%rid%]")[1] : "");

        let hashing2 = hsMD5(rid, "s", skey);
        finalLink = y + "&sign=" + hashing2;

        break;
      case "30":
        if (redirects?.hasOwnProperty("quality_terminate")) {
          x = redirects?.quality_terminate;
          if (redirects?.quality_terminate.includes("surveyNumber")) {
            x =
              x.split("[%surveyNumber%]")[0] +
              surveyID +
              x.split("[%surveyNumber%]")[1];
          }
          y =
            x.split("[%rid%]")[0] +
            rid +
            (x.split("[%rid%]")[1] ? x.split("[%rid%]")[1] : "");
        } else {
          x = redirects?.terminate;
          if (redirects?.terminate.includes("surveyNumber")) {
            x =
              x.split("[%surveyNumber%]")[0] +
              surveyID +
              x.split("[%surveyNumber%]")[1];
          }
          y =
            x.split("[%rid%]")[0] +
            rid +
            (x.split("[%rid%]")[1] ? x.split("[%rid%]")[1] : "");
        }
        let hashing3 = hsMD5(rid, "x", skey);
        finalLink = y + "&sign=" + hashing3;
        break;
      case "40":
        x = redirects?.quota_full;
        if (redirects?.quota_full.includes("surveyNumber")) {
          x =
            x.split("[%surveyNumber%]")[0] +
            surveyID +
            x.split("[%surveyNumber%]")[1];
        }
        y = x.split("[%rid%]")[0] + rid + x.split("[%rid%]")[1];
        let hashing4 = hsMD5(rid, "q", skey);
        finalLink = y + "&sign=" + hashing4;
        break;
      default:
        return;
    }
    window.location.href = finalLink;
  };
  return (
    <>
      {!errorPage ? (
        <>
          <div className="w-full pt-5 h-[100dvh] bg-[#F6F5F4]">
            <img
              src="https://esomar.mirats.in/unnamed.png"
              alt="logo"
              className="w-[160px] mx-[150px] ipad:ml-6"
            />
            <div className="flex justify-center items-center h-[85%]">
              {renderEndScreen()}
            </div>
          </div>
        </>
      ) : (
        <ResponseRecordedErrorPage />
      )}
    </>
  );
};

export default EndPoint;
