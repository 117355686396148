import React, { useEffect, useState } from "react";
import UserProfilePicture from "../../../assets/dummy.png";
import axios from "axios";
import { BASE_URL } from "../../../Utlils";
import { useGlobalContext } from "../../../Contexts/GlobalContext";
import { TiCamera } from "react-icons/ti";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import { RxCross2 } from "react-icons/rx";
import Required from "../../../components/Required";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 361,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
};

const MyProfile = ({ user }) => {
  const { setSnackbar, organization, getCountries, getAllCountryStates } =
    useGlobalContext();
  const [inputData, setInputData] = useState({});
  const [editProfileModal, setEditProfileModal] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [countryState, setCountryState] = useState({
    country: [],
    countryLoading: true,
    states: [],
    statsLoading: false,
  });
  const getCountry = async () => {
    try {
      let data = await getCountries();
      setCountryState((prev) => ({
        ...prev,
        country: data,
        countryLoading: false,
      }));
      if (inputData?.personalDetails?.countryCode) {
        let states = await getAllCountryStates(
          inputData?.personalDetails?.countryCode
        );
        setCountryState((prev) => ({
          ...prev,
          states: states,
          statsLoading: false,
        }));
      }
    } catch (error) {
      setCountryState((prev) => ({ ...prev, countryLoading: false }));
    }
  };
  useEffect(() => {
    setInputData(user);
  }, [Object.keys(user)?.length]);
  useEffect(() => {
    getCountry();
  }, [inputData?.personalDetails?.countryCode]);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    setInputData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${BASE_URL}/users?id=${user?._id}`, {
        ...inputData,
      });

      if (organization?.name !== inputData?.organizationName) {
        await axios.put(`${BASE_URL}/company/${user?.organizationId}`, {
          name: inputData?.organizationName,
        });
      }
      setSnackbar((prev) => ({
        msg: "Profile Updated Successfully !",
        open: true,
        severity: "success",
      }));
    } catch (error) {
      console.log(error);
      setSnackbar({
        msg: `Something went wrong !`,
        open: true,
        severity: "error",
      });
    }
  };
  const uploadProfile = async (e) => {
    e.preventDefault();
    try {
      if (!selectedFile) {
        setSnackbar({
          open: true,
          msg: "Please select a file",
          severity: "error",
        });

        return;
      }
      const fileSizeInBytes = selectedFile.size;
      const maxSizeInBytes = 3 * 1024 * 1024; // 3 MB

      if (fileSizeInBytes > maxSizeInBytes) {
        setSnackbar({
          open: true,
          msg: `File size exceeds 3 MB limit`,
          severity: "error",
        });
        setProfileImage("");
        return;
      }
      setEditProfileModal(false);
      const formData = new FormData();
      formData.append("file", selectedFile);
      const uploadResponse = await axios.post(
        `${BASE_URL}/upload/file?id=${user?._id}&path=profile-pictures/${user?.organizationName}/${user?._id}.jpeg`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const avatarUrl = uploadResponse.data.url;
      await axios.put(`${BASE_URL}/users?id=${user?._id}`, {
        avatar: avatarUrl,
      });
      setSnackbar({
        open: true,
        msg: "File uploaded successfully",
        severity: "success",
      });
      window.location.reload();
    } catch (error) {
      console.error("Error uploading file:", error);

      setSnackbar({
        open: true,
        msg: "Error uploading file! Please try again.",
        severity: "error",
      });
    }
  };

  // console.log(selectedFile);
  // console.log("inputData=>", inputData);
  // console.log("user=>", user);
  // console.log(countryState);
  return (
    <div className="w-full flex flex-col gap-[30px] h-full   overflow-auto hideScroll py-5">
      <div className="pb-5 border-b border-[#E0E0E0]  bg-white z-[10]">
        <h1 className="text-[#333333] text-[28px] font-[500]">My Profile</h1>
      </div>
      <div className="flex items-center gap-[25px]">
        <div className="w-[180px] h-[180px] bg-[#0226BE]  rounded-[50%] flex items-center justify-center relative">
          <div className="w-[168px] h-[168px]  bg-white rounded-[50%]  flex items-center justify-center">
            <img
              src={user?.avatar ? user?.avatar : UserProfilePicture}
              alt=""
              className="w-[165px] h-[165px]  rounded-[50%] flex items-center justify-center"
            />
          </div>
          <div
            className=" w-[40px] h-[40px] rounded-[50%] absolute flex items-center justify-center  bg-[#0226BE] bottom-[10%] right-[0%] cursor-pointer"
            // onClick={(e) => {
            //   setEditProfileModal(true);
            // }}
          >
            <TiCamera color="white" size={25} />
          </div>
        </div>
        <div className="flex flex-col gap-[18px]">
          <h1 className="text-[#333] text-[24px] font-[700] nuni">
            {user?.personalDetails?.fullName}
          </h1>
          <p className="text-[#333] text-[20px] font-[500] nuni">
            Your account is ready, you can now apply for advice.
          </p>
        </div>
      </div>
      <form
        className="flex flex-col gap-[30px] w-[95%] pb-5"
        onSubmit={handleSubmit}
      >
        <div className="flex w-full items-center">
          <h1 className="w-[20%] text-[#333] font-[500] text-[16px]">
            Organization Name <Required />
          </h1>
          <input
            type="text"
            required
            name="organizationName"
            value={inputData?.organizationName}
            onChange={handleChange}
            className={`bg-[#FBFBFB] w-4/5 border border-[#C2C2C5] h-[40px] text-[#12112766] pl-3 focus:outline-none rounded-[4px] ${
              user?.role === "admin" ? "pointer" : "cursor-not-allowed"
            }`}
            disabled={user?.role === "admin" ? false : true}
          />
        </div>
        <div className="flex w-full items-center">
          <h1 className="w-[20%] text-[#333] font-[500] text-[16px]">
            Full Name <Required />
          </h1>
          <input
            type="text"
            required
            value={inputData?.personalDetails?.fullName}
            onChange={(e) => {
              if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                setInputData((prev) => ({
                  ...prev,
                  personalDetails: {
                    ...prev?.personalDetails,
                    fullName: e.target.value,
                  },
                }));
              }
            }}
            className="bg-[#FBFBFB] w-4/5 border border-[#C2C2C5] h-[40px] text-[#12112766] pl-3 focus:outline-none rounded-[4px]"
          />
        </div>
        <div className="flex w-full items-center">
          <h1 className="w-[20%] text-[#333] font-[500] text-[16px]">DOB</h1>
          <input
            type="date"
            value={inputData?.personalDetails?.DOB}
            onChange={(e) =>
              setInputData((prev) => ({
                ...prev,
                personalDetails: {
                  ...prev?.personalDetails,
                  DOB: new Date(e.target.value)?.toLocaleDateString("en-ca"),
                },
              }))
            }
            className="bg-[#FBFBFB] w-4/5 border border-[#C2C2C5] h-[40px] text-[#12112766] pl-3 focus:outline-none rounded-[4px]"
          />
        </div>
        <div className="flex w-full items-center">
          <h1 className="w-[20%] text-[#333] font-[500] text-[16px]">
            Contact No
          </h1>
          <input
            type="number"
            value={inputData?.personalDetails?.contactNo || ""}
            // min={12}
            // max={15}
            required
            // pattern="d{9,16}"
            title="Phone Number must 10 to 15 digit"
            onChange={(e) =>
              setInputData((prev) => ({
                ...prev,
                personalDetails: {
                  ...prev?.personalDetails,
                  contactNo: e.target.value,
                },
              }))
            }
            className="bg-[#FBFBFB] w-4/5 border border-[#C2C2C5] h-[40px] text-[#12112766] pl-3 focus:outline-none rounded-[4px]"
          />
        </div>
        <div className="flex w-full items-center">
          <h1 className="w-[20%] text-[#333] font-[500] text-[16px]">
            Location
          </h1>
          <div className="w-[80%] flex items-center justify-between gap-[20px]">
            <select
              name="country"
              id="country"
              className="bg-[#FBFBFB] w-1/2 border border-[#C2C2C5] h-[40px] text-[#12112766] pl-3 focus:outline-none rounded-[4px]"
              value={inputData?.personalDetails?.countryCode}
              onChange={async (e) => {
                setInputData((prev) => ({
                  ...prev,
                  personalDetails: {
                    ...prev?.personalDetails,
                    country: e.target.options[e.target.selectedIndex].text,
                    countryCode: e.target.value,
                    state: "",
                  },
                }));
                let states = await getAllCountryStates(e.target.value);
                setCountryState((prev) => ({ ...prev, states: states }));
              }}
            >
              <option value="">Select Country</option>
              {countryState?.countryLoading ? (
                <option value="" disabled>
                  Loading Country..
                </option>
              ) : (
                countryState?.country?.map((d, i) => (
                  <option value={d?.iso2} key={i}>
                    {d?.name}
                  </option>
                ))
              )}
            </select>

            <select
              name="state"
              id="state"
              value={inputData?.personalDetails?.state}
              className="bg-[#FBFBFB] w-1/2 border border-[#C2C2C5] h-[40px] text-[#12112766] pl-3 focus:outline-none rounded-[4px]"
              onChange={(e) => {
                if (e.target.value !== "") {
                  setInputData((prev) => ({
                    ...prev,
                    personalDetails: {
                      ...prev?.personalDetails,
                      state: e.target.value,
                    },
                  }));
                }
              }}
            >
              <option value="">Select State</option>
              {countryState?.statsLoading ? (
                <option value="" disabled>
                  Loading States..
                </option>
              ) : countryState?.states?.length ? (
                countryState?.states?.map((d, i) => (
                  <option value={d?.name} key={i}>
                    {d?.name}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No States found try changing the country
                </option>
              )}
            </select>
          </div>
        </div>
        <div className="w-full flex items-center justify-center gap-[20px]">
          <button
            className="text-[#0226BE] border border-[#0226BE] rounded-[4px] px-3 py-2"
            type="button"
          >
            Cancel
          </button>
          <button
            className="bg-[#0226BE] text-[#fff] px-3 py-2 rounded-[4px]"
            type="submit"
          >
            Save Changes
          </button>
        </div>
      </form>

      {/* EDIT PROFILE MODAL */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={editProfileModal}
        onClose={(e) => {
          setEditProfileModal(false);
          setProfileImage("");
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={editProfileModal}>
          <Box sx={style}>
            <div className="w-full flex items-center flex-col">
              <div className="w-full py-6  border-b border-[#E0E0E0]  text-center relative">
                <h1 className="text-[#333333] text-[20px] font-[600]">
                  Change Profile
                </h1>
                <p
                  className=" bg-[#F1F1F1] py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={(e) => {
                    setEditProfileModal(false);
                    setProfileImage("");
                  }}
                >
                  <RxCross2 size={16} />
                </p>
              </div>

              <div className="flex flex-col my-[24px] gap-[20px] justify-center items-center ">
                <div className="flex flex-col  h-[244px] w-[313px] justify-center items-center border border-[#E0E0E0] rounded-[4px]">
                  <div className="flex  w-[200px] h-[200px] rounded-[200px] justify-center items-center">
                    <img
                      src={
                        profileImage === ""
                          ? user?.avatar
                            ? user?.avatar
                            : UserProfilePicture
                          : profileImage
                      }
                      className="flex w-[200px] h-[200px] rounded-[200px] justify-center items-center object-cover"
                    />
                  </div>
                </div>
                <div className="w-full flex flex-col gap-[16px]  ">
                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    id="file"
                    name="file"
                    onChange={(e) => {
                      handleFileChange(e);
                      setProfileImage(URL.createObjectURL(e.target.files[0]));
                    }}
                  />
                  {!profileImage ? (
                    <label
                      htmlFor="file"
                      className="w-[100%] cursor-pointer h-[40px] px-[8px] py-[16px] bg-[#0226BE] rounded-[4px] flex justify-center items-center text-[white]"
                    >
                      Change Profile
                    </label>
                  ) : (
                    ""
                  )}
                  {profileImage ? (
                    <button
                      className="w-[100%] cursor-pointer h-[40px] px-[8px] py-[16px] bg-[#0226BE] rounded-[4px] flex justify-center items-center text-[white]"
                      onClick={(e) => uploadProfile(e)}
                      // onClick={(e) => {
                      //   console.log("delete image");
                      // }}
                    >
                      Upload
                    </button>
                  ) : (
                    ""
                  )}
                  <button
                    className="w-[100%] h-[40px] px-[8px] py-[16px] border border-[#0226BE] rounded-[4px] flex justify-center items-center text-[#0226BE] cursor-pointer"
                    onClick={(e) => {
                      // console.log("delete image");
                      setEditProfileModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>

              {/* <form
                className="w-full py-4 px-5  flex flex-col gap-[19px]"
                // onSubmit={(e) => {
                //   e.preventDefault();
                //   setModals((prev) => ({
                //     ...prev,
                //     changeEmail: false,
                //     openEmail: true,
                //   }));
                //   console.log(
                //     `${window.location.origin}/verify-change-email?oe=${user?.email}&ne=${changeEmail}&id=${user?._id}`
                //   );
                // }}
              >
                <div className="flex flex-col gap-[10px]">
                  <p className="text-[#333] text-[16px] font-[400]">
                    If you want to change your e-mail, verify it here{" "}
                  </p>
                  <p className="text-[#333] text-[14px] font-[500]">
                    Previous Email :
                  </p>
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label
                    htmlFor="email"
                    className="text-[#333] text-[14px] font-[500]"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    required
                    placeholder="johndoe@gmail.com"
                    className="border border-[#E2E2E5] rounded-[4px] pl-3 h-[40px] focus:outline-none text-[#666] text-[14px] cursor-pointer"
                    // onChange={(e) => {
                    //   setChangeEmail(e.target.value);
                    // }}
                  />
                </div>

                <button
                  type="submit"
                  className="text-[#fff] inter text-[16px] font-[400] bg-[#0226BE] rounded-[4px] py-2 "
                >
                  Change Email
                </button>
              </form> */}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default MyProfile;
