import React, { useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import reconciliationDone from "../../../../assets/reconciliationDone2.svg";
import { read, utils } from "xlsx";
import Papa from "papaparse";
import { useEffect } from "react";
import axios from "axios";
import { BASE_URL, CustomTablePagination } from "../../../../Utlils";
import { useParams } from "react-router-dom";
import { useGlobalContext } from "../../../../Contexts/GlobalContext";
import { RiFileExcel2Line } from "react-icons/ri";
import { TbFileTypeCsv } from "react-icons/tb";
import NoData from "../../../../components/NoData";
import { useQuery } from "@tanstack/react-query";
import { FcExpired } from "react-icons/fc";
const Reconciliation = ({ singleSurveyStats, getSingleSurvey }) => {
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const {
    data: isReconciliationTimeExpired,
    isLoading: isReconciliationTimeExpiredLoading,
    isError: isReconciliationTimeExpiredError,
  } = useQuery({
    queryFn: () => getLastCompleteDate(id),
    queryKey: ["lastComplete", id],
    staleTime: 10000,
    cacheTime: 15000,
  });
  const [reconciledTable, setReconciledTable] = useState({
    loading: true,
    data: [],
    error: false,
  });
  const [reconcilationChange, setReconcilationChange] = useState({});
  const [reconcilationStats, setReconcilationStats] = useState({
    completedIDs: 0,
    approvedIDs: 0,
    rejectedIDs: 0,
    nonReconciledIDs: 0,
    uploadedIDs: 0,
    matchedIDs: 0,
    totalApprovedIDs: 0,
    totalRejectedIDs: 0,
  });
  const { setSnackbar } = useGlobalContext();
  const [error, setError] = useState({
    type: "",
    file: "",
  });
  const [paginationPage, setPaginationPagePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // pagination function
  const handleChangePage = (event, newPage) => {
    setPaginationPagePage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPaginationPagePage(0);
  };

  //READ EXCEL FILE:
  const readExcelFile = async (data) => {
    const workbook = read(data, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const jsonData = utils.sheet_to_json(sheet);
    return jsonData;
  };

  //READ CSV:
  const readCSVFile = async (data) => {
    return new Promise((resolve, reject) => {
      Papa.parse(data, {
        header: true,
        dynamicTyping: true,
        complete: (result) => {
          resolve(result.data);
        },
        error: (error) => {
          console.error("Error parsing CSV:", error.message);
          reject(error);
        },
      });
    });
  };

  //HANDLE RECONCILATION FILE:
  const [fileData, setFileData] = useState([]);
  const handleReconciliation = async () => {
    const file = reconcilationChange?.reconcilationFile;
    if (!reconcilationChange?.type) {
      setError((prev) => ({ ...prev, type: "Please Select Type!" }));
      return;
    }
    if (!reconcilationChange?.reconcilationFile) {
      setError((prev) => ({ ...prev, file: "Please Upload File!" }));
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        let readData = [];

        if (file.name.endsWith(".xlsx")) {
          readData = await readExcelFile(data);
        } else {
          readData = await readCSVFile(data);
        }
        // Detect the column with data
        const columnWithData = Object.keys(readData[0] || {}).find(
          (key) => readData[0][key] !== null && readData[0][key] !== undefined
        );
        if (columnWithData) {
          const processedData = readData
            .map((obj) => obj[columnWithData])
            .filter((value) => value !== null);
          setFileData(processedData);
          setPage(1);
        } else {
          setError((prev) => ({
            ...prev,
            file: "No valid data found in the file!",
          }));
        }
      };

      reader.readAsBinaryString(file);
    }
  };

  // GET SESSIONS:
  const [sessions, setSessions] = useState([]);
  const getSessions = async () => {
    try {
      const sessions = await axios.get(
        `${BASE_URL}/session/reconcilation-sessions?surveyID=${id}`
      );
      // console.log(sessions?.data);
      let newArr = [];
      let ExpArr = [];
      sessions?.data?.forEach((d) => {
        if (checkReconciliationTimeExpired(d?.createdAt)) {
          ExpArr.push(d?.ref_id);
        } else {
          newArr.push(d);
        }
      });
      setSessions(newArr);
      setReconcilationStats((prev) => ({ ...prev, expiredIds: ExpArr }));
    } catch (error) {
      // console.log(error);
    }
  };
  async function getSurveyReconciledData(id) {
    try {
      setReconciledTable((prev) => ({ ...prev, loading: true }));
      let data = await axios.get(`${BASE_URL}/session/reconciliation/${id}`);
      setReconciledTable((prev) => ({
        ...prev,
        loading: false,
        data: data.data,
      }));
    } catch (error) {
      setReconciledTable((prev) => ({ ...prev, loading: false, error: true }));
      console.log("error in getSurveyReconciledData", error?.message);
    }
  }
  function checkReconciliationTimeExpired(dateString) {
    const inputDate = new Date(dateString);
    const currentDate = new Date();
    const timeDifference = currentDate - inputDate;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    return daysDifference > 55;
  }
  const getLastCompleteDate = async (id) => {
    try {
      let data = await axios.get(
        `${BASE_URL}/session/last-complete-date/${id}`
      );
      let { date } = data?.data;
      if (date) {
        return checkReconciliationTimeExpired(date);
      } else {
        return false;
      }
    } catch (error) {
      console.log("Unable to get last complete date");
      throw error;
    }
  };
  useEffect(() => {
    if (page == 1) getSessions();
    getSurveyReconciledData(id);
  }, [page, id]);

  //GET RECONCILATION STATS:
  const getReconcilationStats = async () => {
    let matchedIDs = [];
    let totalApprovedIDs = [];
    let totalRejectedIDs = [];
    let unmatchedIDs = [...sessions.map((session) => session.ref_id)];

    sessions?.forEach((data) => {
      if (fileData?.includes(data?.ref_id)) {
        matchedIDs.push(data?.ref_id);
        unmatchedIDs = unmatchedIDs.filter((id) => id !== data.ref_id);
        if (
          reconcilationChange?.type === "approved" &&
          !data?.reconciled?.isReconciled
        ) {
          totalApprovedIDs.push(data?.ref_id);
        } else if (
          reconcilationChange?.type === "rejected" &&
          !data?.reconciled?.isReconciled
        ) {
          totalRejectedIDs.push(data?.ref_id);
        }
      }
    });

    if (reconcilationChange?.restIDs) {
      if (reconcilationChange?.type === "approved") {
        totalRejectedIDs = unmatchedIDs;
      } else if (reconcilationChange?.type === "rejected") {
        totalApprovedIDs = unmatchedIDs;
      }
    }

    setReconcilationStats((prev) => ({
      ...prev,
      completedIDs: sessions?.filter((data) => data?.client_status === 10),
      approvedIDs: sessions?.filter(
        (data) => data?.reconciled?.reconciliationStatus === "approved"
      ),
      rejectedIDs: sessions?.filter(
        (data) => data?.reconciled?.reconciliationStatus === "rejected"
      ),
      nonReconciledIDs: sessions?.filter(
        (data) => data?.reconciled?.isReconciled === false
      ),
      uploadedIDs: fileData,
      matchedIDs: matchedIDs,
      totalApprovedIDs: totalApprovedIDs,
      totalRejectedIDs: totalRejectedIDs,
      totalCompletes: sessions?.length,
    }));
  };

  useEffect(() => {
    getReconcilationStats();
  }, [sessions?.length]);

  const approveReconcilation = async (e) => {
    try {
      const updateSessions = async (ids, isReconciled, status) => {
        return Promise.all(
          ids.map(async (id) => {
            await axios.put(`${BASE_URL}/session/update-session?refID=${id}`, {
              reconciled: {
                isReconciled: true,
                reconciliationDate: new Date(),
                reconciliationStatus: status,
              },
            });
          })
        );
      };

      if (reconcilationChange?.type === "approved") {
        await updateSessions(
          reconcilationStats?.totalApprovedIDs,
          true,
          "approved"
        );
        if (reconcilationChange?.restIDs) {
          const remainingIDs = sessions
            .filter(
              (se) =>
                !reconcilationStats?.totalApprovedIDs.includes(se?.ref_id) &&
                se?.reconciled?.isReconciled === false
            )
            .map((se) => se?.ref_id);
          await updateSessions(remainingIDs, false, "rejected");
        }
      } else if (reconcilationChange?.type === "rejected") {
        await updateSessions(
          reconcilationStats?.totalRejectedIDs,
          false,
          "rejected"
        );

        if (reconcilationChange?.restIDs) {
          const remainingIDs = sessions
            .filter(
              (se) =>
                !reconcilationStats?.totalRejectedIDs.includes(se?.ref_id) &&
                se?.reconciled?.isReconciled === false
            )
            .map((se) => se?.ref_id);
          await updateSessions(remainingIDs, true, "approved");
        }
      }

      if (
        reconcilationStats?.completedIDs?.length ==
        Number(reconcilationStats?.totalApprovedIDs?.length || 0) +
          Number(reconcilationStats?.totalRejectedIDs?.length || 0)
      ) {
        await axios.put(`${BASE_URL}/survey?id=${id}`, {
          surveyStatus: "billed",
        });
        await getSingleSurvey(id);
      }
      setSnackbar({
        msg: "Uploaded Reconcilation!",
        open: true,
        severity: "success",
      });
      setPage(3);
    } catch (error) {
      console.log(error);
    }
  };
  // console.log("reconciledTable=>", reconciledTable);
  // console.log("sessions=>", sessions);
  // console.log("reconcilationStats=>", reconcilationStats);
  return (
    <>
      {isReconciliationTimeExpiredLoading ? (
        <div className=" reconciliationShadow  space-y-5 min-h-[70vh] flex items-center justify-center">
          <h1 className="text-[14px] font-[500] text-[#9d9c9c]">Loading...</h1>
        </div>
      ) : page === 0 ? (
        <div className=" reconciliationShadow pb-10 space-y-5">
          <div className="w-full  flex flex-col  items-center justify-center px-8  pt-5 ">
            <h1 className="text-[#666] font-[600] text-[16px] w-full pb-5">
              Overview
            </h1>
            <div className="w-full  grid grid-cols-3 ">
              <div className="border-l-[3px] rounded-t-sm rounded-b-sm border-[#0226BE] pl-2 space-y-1">
                <h1 className="text-[18px] text-[#333] font-[500]">
                  Total Completes
                </h1>
                <p className="text-[#666666] font-[600] text-[16px]">
                  {singleSurveyStats?.completed || 0}
                </p>
              </div>
              <div className="border-l-[3px] rounded-t-sm rounded-b-sm border-[#0226BE] pl-2 space-y-1">
                <h1 className="text-[18px] text-[#333] font-[500]">
                  Total Approved
                </h1>
                <p className="text-[#666666] font-[600] text-[16px]">
                  {reconciledTable?.data?.filter(
                    (d) =>
                      d?.reconciled?.reconciliationStatus?.toLowerCase() ==
                      "approved"
                  )?.length || 0}
                </p>
              </div>
              <div className="border-l-[3px] rounded-t-sm rounded-b-sm border-[#0226BE] pl-2 space-y-1">
                <h1 className="text-[18px] text-[#333] font-[500]">
                  Total Rejected
                </h1>
                <p className="text-[#666666] font-[600] text-[16px]">
                  {reconciledTable?.data?.filter(
                    (d) =>
                      d?.reconciled?.reconciliationStatus?.toLowerCase() ==
                      "rejected"
                  )?.length || 0}
                </p>
              </div>
            </div>
            <div className="h-[10px] w-full border-b border-gray-200 mt-5"></div>
          </div>
          {isReconciliationTimeExpired ? (
            <div className="   space-y-5 min-h-[50vh] flex items-center justify-center">
              <div className="w-[50%] space-y-3 text-center">
                <div className="text-center flex items-center justify-center flex-col space-y-3">
                  <FcExpired size={100} />
                  <h1 className="text-center text-[24px] text-[#333]">
                    Reconciliation Window Closed
                  </h1>
                </div>

                <p className="text-[#666] text-[14px]">
                  The deadline for reconciling completes for this survey has
                  passed. Unfortunately, you are no longer able to reconcile
                  completes for this survey.
                </p>
                <p className="text-[#666] text-[14px]">
                  If you have any questions or need assistance, please contact
                  our{" "}
                  <a
                    href="mailto:diysupport@miratsinsights.com"
                    className="text-blue-400 "
                  >
                    support team.
                  </a>
                </p>
              </div>
            </div>
          ) : singleSurveyStats?.completed > reconciledTable?.data?.length ||
            reconciledTable?.data?.length == 0 ? (
            <div className="w-full  flex flex-col gap-[18px] items-center justify-center ">
              <div className="w-[93%]    my-3">
                <div className="w-4/6  flex flex-col gap-[30px]">
                  <h1 className="text-[#666] font-[600] text-[16px]">
                    Setup Reconciliation
                  </h1>
                  <div className="w-full flex flex-col ">
                    <label
                      htmlFor="reconcilationType"
                      className="text-[#666] font-[600] text-[14px]"
                    >
                      Reconciliation File Example{" "}
                    </label>
                    <div className=" rounded-[4px] flex  gap-[18px]   py-3 mt-2">
                      <a
                        href="https://firebasestorage.googleapis.com/v0/b/diyprojectsample.appspot.com/o/reconciliation-example-files%2FReconcilationDemoXLSX.xlsx?alt=media&token=bdb87370-2ebe-482c-9224-3a5f8ed829bb"
                        className="p-3 border-2 border-green-700 w-1/4   rounded-md flex text-center justify-center gap-[5px] hover:bg-green-50 transition-all duration-150"
                        download
                      >
                        <RiFileExcel2Line color="green" size={35} />
                      </a>
                      <a
                        href="https://firebasestorage.googleapis.com/v0/b/diyprojectsample.appspot.com/o/reconciliation-example-files%2FReconcilationDemoCSV.csv?alt=media&token=0e853484-1171-4e55-8ad3-ac5db311964d"
                        className="p-3 border-2 border-blue-700 w-1/4   rounded-md flex text-center justify-center gap-[5px] hover:bg-blue-50 transition-all duration-150"
                        download
                      >
                        <TbFileTypeCsv color="blue" size={35} />
                      </a>
                    </div>
                  </div>

                  <div className="w-full flex flex-col gap-[38px]">
                    <div className="flex flex-col gap-[8px]">
                      <label
                        htmlFor="reconcilationType"
                        className="text-[#666] font-[600] text-[14px]"
                      >
                        Reconciliation Type{" "}
                        <span className="text-[12px] text-[red]">*</span>
                      </label>
                      <select
                        name="reconcilationType"
                        id="reconcilationType"
                        className="text-[#12112766] text-[14px] font-[400] border border-[#E0E0E0] rounded-[4px] h-[40px] pl-3"
                        onChange={(e) => {
                          setReconcilationChange((prev) => ({
                            ...prev,
                            type: e.target.value,
                          }));
                          setError((prev) => ({ ...prev, type: "" }));
                        }}
                      >
                        <option value="">Select Reconciliation Type</option>
                        <option value="approved">Approved Completed IDs</option>
                        <option value="rejected">Rejected Completed IDs</option>
                      </select>
                      {error?.type ? (
                        <p className="text-[red] text-[12px]">{error?.type}</p>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="flex flex-col gap-[11px]">
                      <label
                        htmlFor="reconcilationType"
                        className="text-[#666] font-[600] text-[14px]"
                      >
                        Upload File{" "}
                        <span className="text-[12px] text-[red]">*</span>
                      </label>
                      <div className="border-dashed border-[1px]  border-primary rounded-[4px] flex  px-6 py-3">
                        <div className="flex w-full items-center justify-between py-2  ">
                          <div className="flex items-center gap-[18px]">
                            <MdOutlineFileUpload size={30} color="#0226BE" />
                            <p className="text-[#12112766] font-[400] text-[12px]">
                              Supported File type (.xlsx) (.csv) (
                              <span className="text-primary font-[600] text-[12px] capitalize">
                                {reconcilationChange?.type} Data
                              </span>
                              )
                            </p>
                          </div>
                          <input
                            type="file"
                            name="uploadBTN"
                            id="uploadBTN"
                            className="hidden"
                            accept=".xlsx, .csv"
                            onChange={(e) => {
                              setReconcilationChange((prev) => ({
                                ...prev,
                                reconcilationFile: e.target.files[0],
                              }));
                              setError((prev) => ({ ...prev, file: "" }));
                            }}
                          />
                          <label
                            htmlFor="uploadBTN"
                            className="bg-primary text-white cursor-pointer text-[10px] font-[500] py-2 px-4 rounded-[4px] btnShadow"
                          >
                            Browse File
                          </label>
                        </div>
                      </div>
                      {error?.file ? (
                        <p className="text-[red] text-[12px]">{error?.file}</p>
                      ) : (
                        <></>
                      )}
                      <div className="border border-[#E0E0E0] rounded-[4px] flex flex-col gap-[8px]   px-6 py-3 mt-6">
                        <h1 className="text-[#888] font-[400] text-[12px]">
                          Instructions :
                        </h1>

                        <ul className="list-disc pl-5 text-[#888] font-[400] text-[12px] flex flex-col gap-[5px]">
                          <li>Keep the first row empty.</li>
                          <li>
                            Place each ID on one line. Empty lines will be
                            ignored.
                          </li>
                          <li>
                            The maximum size of the file should be 1MB or less.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="flex flex-col gap-[11px]">
                      <label
                        htmlFor="check"
                        className="text-[#666] font-[600] text-[14px]"
                      >
                        Check Box
                      </label>
                      <div className="flex items-center gap-[30px]">
                        <div className="flex items-center gap-[16px]">
                          <input
                            type="checkbox"
                            name="reject"
                            id="reject"
                            className="w-[16px] h-[16px] bg-[#999999]"
                            onChange={(e) => {
                              setReconcilationChange((prev) => ({
                                ...prev,
                                restIDs: e.target.checked,
                              }));
                            }}
                          />
                          <label
                            htmlFor="reject"
                            className="text-[#000] cursor-pointer text-[13px] font-[400]"
                          >
                            Mark rest completed IDs as{" "}
                            {reconcilationChange?.type == "approved"
                              ? "Rejected"
                              : "Approved"}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="bg-primary text-[#fff] py-[8px] px-[16px] rounded-[4px] text-[14px]"
                onClick={(e) => {
                  handleReconciliation();
                }}
              >
                Continue
              </button>
            </div>
          ) : (
            ""
          )}
          <div className="w-full  px-8 space-y-3 mt-5">
            {" "}
            <h1 className="text-[#666] font-[600] text-[16px]">
              Reconciled Data
            </h1>
            <div className="tableBorder min-h-[200px] w-full">
              <div className="w-full overflow-x-auto containerScroll">
                <table
                  className="whitespace-nowrap text-left w-full"
                  // id="table-to-xls-DATA"
                >
                  <thead className=" h-[45px] ">
                    <tr className="py-3">
                      <th className="text-[#666666] text-[12px] font-[600] px-3">
                        Ref ID
                      </th>
                      <th className="text-[#666666] text-[12px] font-[600] px-3">
                        Reconciliation Date
                      </th>
                      <th className="text-[#666666] text-[12px] font-[600] px-3">
                        Reconciliation Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {reconciledTable?.loading ? (
                      <tr>
                        <td
                          colSpan="3"
                          className="text-center text-[14px] text-[#666] h-[155px]"
                        >
                          {" "}
                          Loading...
                        </td>
                      </tr>
                    ) : reconciledTable?.error ? (
                      <tr>
                        <td
                          colSpan="3"
                          className="text-center text-[14px] text-[#f00] h-[155px]"
                        >
                          {" "}
                          Something went wrong while fetching the data...
                        </td>
                      </tr>
                    ) : reconciledTable?.data?.length ? (
                      (rowsPerPage > 0
                        ? reconciledTable?.data?.slice(
                            paginationPage * rowsPerPage,
                            paginationPage * rowsPerPage + rowsPerPage
                          )
                        : reconciledTable?.data
                      )?.map((d, key) => (
                        <tr
                          key={key}
                          className=" tableBorder transition-all cursor-pointer hover:bg-[#f6f9ff]"
                        >
                          <td className="px-3 py-5  text-[#667085] font-[400] text-[14px]">
                            {d?.ref_id}
                          </td>
                          <td className="px-3 py-5  text-[#667085] font-[400] text-[14px]">
                            {new Date(
                              d?.reconciled?.reconciliationDate
                            )?.toLocaleDateString("en-in", {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            })}
                          </td>
                          <td className="px-3 py-5  text-[#667085] font-[400] text-[14px] capitalize">
                            {d?.reconciled?.reconciliationStatus}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <td colSpan={3}>
                        <div className="py-10">
                          <NoData />
                        </div>
                      </td>
                    )}
                  </tbody>
                  <CustomTablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      35,
                      { label: "All", value: -1 },
                    ]}
                    sx={{ fontSize: "10px" }}
                    count={reconciledTable?.data.length}
                    rowsPerPage={rowsPerPage}
                    page={paginationPage}
                    componentsProps={{
                      select: {
                        "aria-label": "rows per page",
                      },
                      actions: {
                        showFirstButton: true,
                        showLastButton: true,
                      },
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : page === 1 ? (
        <div className="w-full reconciliationShadow flex flex-col gap-[18px] items-center justify-center pb-10">
          <div className="w-[93%]    my-6">
            <div className="w-full  flex flex-col gap-[44px]">
              <h1 className="text-[#666] font-[600] text-[16px]">
                Review Reconciliation
              </h1>
              <div className="w-full flex flex-col gap-[20px]">
                <div className=" flex flex-col gap-[30px]">
                  <table className="w-full border border-[#EAECF0] text-left">
                    <thead>
                      <tr className="border border-b-[#EAECF0]">
                        <th
                          style={{
                            width: "30%",
                            color: "#666666",
                            fontWeight: 600,
                            fontSize: "14px",
                            padding: "11px 8px",
                          }}
                        >
                          Total
                        </th>
                        <th
                          style={{
                            width: "70%",
                            color: "#666666",
                            fontWeight: 600,
                            fontSize: "14px",
                          }}
                        >
                          Number Of IDs
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border border-b-[#EAECF0]">
                        <td className="py-[23px] px-[8px] text-[#333] text-[14px] font-[400]">
                          Total IDs Completed
                        </td>
                        <td className="text-[#333] font-[600] text-[14px]">
                          {reconcilationStats?.completedIDs?.length}
                        </td>
                      </tr>
                      <tr className="border border-b-[#EAECF0]">
                        <td className="py-[23px] px-[8px] text-[#333] text-[14px] font-[400]">
                          Total IDs Approved
                        </td>
                        <td className="text-[#333] font-[600] text-[14px]">
                          {reconcilationStats?.approvedIDs?.length}
                        </td>
                      </tr>
                      <tr className="border border-b-[#EAECF0]">
                        <td className="py-[23px] px-[8px] text-[#333] text-[14px] font-[400]">
                          Total IDs Rejected
                        </td>
                        <td className="text-[#333] font-[600] text-[14px]">
                          {reconcilationStats?.rejectedIDs?.length}
                        </td>
                      </tr>
                      <tr className="border border-b-[#EAECF0]">
                        <td className="py-[23px] px-[8px] text-[#333] text-[14px] font-[400]">
                          Total IDs Not-Reconcilied
                        </td>
                        <td className="text-[#333] font-[600] text-[14px]">
                          {reconcilationStats?.nonReconciledIDs?.length}
                        </td>
                      </tr>
                      {reconcilationStats?.expiredIds?.length ? (
                        <tr className="border border-b-[#EAECF0]">
                          <td className="py-[23px] px-[8px] text-[#333] text-[14px] font-[400]">
                            Expired Ids
                          </td>
                          <td className="text-[#333] font-[600] text-[14px]">
                            {reconcilationStats?.expiredIds?.length}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                  <table className="w-full border border-[#EAECF0] text-left">
                    <thead>
                      <tr className="border border-b-[#EAECF0]">
                        <th
                          style={{
                            width: "30%",
                            color: "#666666",
                            fontWeight: 600,
                            fontSize: "14px",
                            padding: "11px 8px",
                          }}
                        >
                          Total
                        </th>
                        <th
                          style={{
                            width: "70%",
                            color: "#666666",
                            fontWeight: 600,
                            fontSize: "14px",
                          }}
                        >
                          Number Of IDs
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border border-b-[#EAECF0]">
                        <td className="py-[23px] px-[8px] text-[#333] text-[14px] font-[400]">
                          Total IDs Uploaded
                        </td>
                        <td className="text-[#333] font-[600] text-[14px]">
                          {reconcilationStats?.uploadedIDs?.length}
                        </td>
                      </tr>
                      <tr className="border border-b-[#EAECF0]">
                        <td className="py-[23px] px-[8px] text-[#333] text-[14px] font-[400]">
                          Total IDs Matched with the Data
                        </td>
                        <td className="text-[#333] font-[600] text-[14px]">
                          {reconcilationStats?.matchedIDs?.length}
                        </td>
                      </tr>
                      <tr className="border border-b-[#EAECF0]">
                        <td className="py-[23px] px-[8px] text-[#333] text-[14px] font-[400]">
                          Total IDs Total Approved
                        </td>
                        <td className="text-[#333] font-[600] text-[14px]">
                          {reconcilationStats?.totalApprovedIDs?.length}
                        </td>
                      </tr>

                      <tr className="border border-b-[#EAECF0]">
                        <td className="py-[23px] px-[8px] text-[#333] text-[14px] font-[400]">
                          Total IDs Rejected
                        </td>
                        <td className="text-[#333] font-[600] text-[14px]">
                          {reconcilationStats?.totalRejectedIDs?.length}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="text-[14px] text-[#666] font-[400]">
                  By approving this reconciliation, you agree to our{" "}
                  <span className="text-primary">
                    reconciliation policies(link){" "}
                  </span>
                  . In case of any help, please reach out to us at
                  <a
                    href="mailto:reconciliation_support@miratsquanto.com"
                    className="font-[500]"
                  >
                    {" "}
                    reconciliation_support@miratsquanto.com
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-[20px]">
            <button
              className="border border-primary text-primary py-[8px] px-[16px] text-[12px] font-[400] rounded-[4px]"
              onClick={(e) => {
                setReconcilationChange({});
                setReconcilationStats({
                  completedIDs: 0,
                  approvedIDs: 0,
                  rejectedIDs: 0,
                  nonReconciledIDs: 0,
                  uploadedIDs: 0,
                  matchedIDs: 0,
                  totalApprovedIDs: 0,
                  totalRejectedIDs: 0,
                });
                setFileData([]);
                setSessions([]);
                setPage(0);
              }}
            >
              Upload New File
            </button>
            <button
              className="border border-primary bg-primary text-white py-[8px] px-[16px] text-[12px] font-[400] rounded-[4px]"
              onClick={(e) => {
                approveReconcilation(e);
              }}
            >
              Approve Reconciliation
            </button>
          </div>
        </div>
      ) : (
        <div className="w-full reconciliationShadow flex flex-col gap-[18px] items-center justify-center pb-10">
          <div className="w-[93%]    my-6">
            <div className="w-full  flex flex-col gap-[44px] items-center">
              <h1 className="text-[#000] font-[500] text-[16px] self-start">
                Thank You
              </h1>

              <img
                src={reconciliationDone}
                alt="reconciliationDone"
                className="w-[330px] h-[330px]"
              />
              <div className="flex flex-col gap-[30px] w-[85%]">
                <p className="text-[#000] text-[16px]">
                  Thank you for submitting the data with us. Our team will
                  review the data and will accommodate your request, in case of
                  any queries, we will reach out to you at your registered email
                  ID.
                </p>
                <p className="text-[#000] text-[16px]">
                  This will usually take 1-3 business days.
                </p>
                <p className="text-primary">~ Mirats Team</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Reconciliation;
