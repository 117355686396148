import axios from "axios";
import { BASE_URL } from "../Utlils";

export const addDiyUser = async (body) => {
  try {
    // console.log("body=>", body);
    let {
      email,
      firstName,
      lastName,
      phone,
      DOB,
      gender,
      avatar,
      country,
      _id,
    } = body;
    let userBody = {
      email,
      permission: ["PB", "GB"],
      role: "admin",
      accountStatus: "active",
      personalDetails: {
        fullName: `${firstName} ${lastName}`,
        DOB: DOB,
        contactNo: phone,
        country: country,
      },
      authorize: true,
      avatar,
      _id,
    };
    const { data } = await axios.post(`${BASE_URL}/add-diy-user`, userBody);
    return data;
  } catch (error) {
    console.log("Unable to add diy user");
    throw error;
  }
};
export const checkOrgExists = async (id) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/company/exists/${id}`);
    return data?.organization;
  } catch (error) {
    console.log("Unable to get organization");
    throw error;
  }
};
