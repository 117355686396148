import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import SideBarActiveIcon from "../../../../assets/icons/SideBarActive.svg";
import { CgTemplate } from "react-icons/cg";
import { TbReportAnalytics } from "react-icons/tb";
import { MdOutlineBallot, MdOutlineSecurity } from "react-icons/md";
import { AiOutlineReconciliation } from "react-icons/ai";
import { IoDocumentsOutline } from "react-icons/io5";
import { RiQuestionAnswerLine } from "react-icons/ri";
import { BsDatabaseSlash } from "react-icons/bs";
import { TbAdjustmentsCheck } from "react-icons/tb";
import {
  MdOutlineNotificationsActive,
  MdOutlineHelpCenter,
} from "react-icons/md";
//
const SurveySidebar = ({ children }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [collapse, setCollapse] = useState(false);

  const SideBarData = [
    {
      name: "Details",
      path: `/surveys/${id}/details`,
      subPath: "details",
      icon: <CgTemplate size={23} />,
    },
    {
      name: "Qualification",
      path: `/surveys/${id}/qualification`,
      subPath: "qualification",
      icon: <MdOutlineHelpCenter size={23} />,
    },
    {
      name: "Quotas",
      path: `/surveys/${id}/quotas`,
      subPath: "quotas",
      icon: <TbAdjustmentsCheck size={23} />,
    },
    {
      name: "Allocations",
      path: `/surveys/${id}/allocations`,
      subPath: "allocations",
      icon: <MdOutlineBallot size={23} />,
    },
    {
      name: "Reports",
      path: `/surveys/${id}/reports`,
      subPath: "reports",
      icon: <TbReportAnalytics size={23} />,
    },
    {
      name: "Reconciliation",
      path: `/surveys/${id}/reconciliation`,
      subPath: "reconciliation",
      icon: <AiOutlineReconciliation size={23} />,
    },
    {
      name: "Security",
      path: `/surveys/${id}/security`,
      subPath: "security",
      icon: <MdOutlineSecurity size={23} />,
    },
    // {
    //   name: "Documents",
    //   path: `/surveys/${id}/documents`,
    //   subPath: "documents",
    //   icon: <IoDocumentsOutline size={23} />,
    // },
    {
      name: "Resp. Answers",
      path: `/surveys/${id}/respondent-answers`,
      subPath: "respondent-answers",
      icon: <RiQuestionAnswerLine size={23} />,
    },
    {
      name: "Termination Data",
      path: `/surveys/${id}/termination-data`,
      subPath: "termination-data",
      icon: <BsDatabaseSlash size={23} />,
    },
  ];

  return (
    <div className="w-full flex " style={{ height: "calc(100vh - 64px)" }}>
      <div
        className={`
        ${collapse ? "w-[5%]" : "w-[18%]"}
        transition-all ease-in duration-500 bg-white z-[20] border-t border-[#E3E1E1] sidebarShadow relative `}
      >
        <h1
          className={` w-[30px] h-[30px] flex items-center justify-center rounded-[50%] absolute top-8 right-[-18px] transition-all origin-center ease-in duration-500 cursor-pointer bg-[#0226BE] ${
            collapse ? "rotate-[180deg]  " : ""
          } `}
          onClick={(e) => setCollapse(!collapse)}
        >
          <IoIosArrowBack color="white" size={22} />
        </h1>
        <div className="w-full flex flex-col gap-4 mt-5  overflow-y-auto containerScroll h-[95%]">
          {SideBarData?.map((data, ind) => (
            <div
              key={ind}
              className={` flex items-center transition-all ease-in duration-500  ${
                collapse ? "gap-[5px]" : "gap-[15px]"
              }   h-[50px] cursor-pointer`}
              onClick={(e) => navigate(data?.path)}
            >
              <img
                src={SideBarActiveIcon}
                alt=""
                className={`${
                  !window.location.pathname?.includes(data?.subPath)
                    ? "opacity-0"
                    : "opacity-100"
                }`}
              />
              <p
                to={data?.path}
                // className="flex items-center justify-between"
                className={`transition duration-500  cursor-pointer flex items-center text-center gap-[10px]
                ${
                  collapse
                    ? "w-[60%] pl-3 h-[80%] rounded-[50%]"
                    : "w-[75%] px-4"
                }   ${
                  window.location.pathname?.includes(data?.subPath)
                    ? "text-[#0226BE] inter text-[16px] font-[600]  bg-[#B8B6B61A]  h-[100%]  rounded-[9px]"
                    : "text-[#666] inter text-[14px] font-[500]"
                } overflow-hidden `}
              >
                <h1 className=""> {data?.icon ?? data?.icon}</h1>
                <h1
                  key={ind}
                  className={`whitespace-nowrap transition-all ease-in  duration-500  ${
                    collapse ? "opacity-0" : ""
                  }  `}
                >
                  {data?.name}
                </h1>
              </p>
            </div>
          ))}
        </div>
      </div>
      <div
        className=" px-6 pt-3  transition-all duration-500 ease-in flex items-center justify-center overflow-y-auto containerScroll max-h-[100vh]"
        style={{
          width: collapse ? "95%" : "82%",
          height: "calc(100vh - 64px)",
        }}
      >
        <div className="w-[95%] h-full py-8  ">{children}</div>
      </div>
    </div>
  );
};

export default SurveySidebar;
