import axios from "axios";
import { BASE_URL } from "../Utlils";
import {
  getGasIds,
  getGasId,
  isValidEmail,
  sendUserInvite,
  sendUserInvites,
} from "@mirats/mirats-auth";

export const addTeamMember = async (body) => {
  try {
    let { email, organizationName, invitedBy, personalDetails } = body;
    if (!isValidEmail(email || "")) {
      throw new Error("Invalid Email !");
    }
    let { data: userExists } = await axios.get(
      `${BASE_URL}/users/get-user/by-email?email=${email}`
    );
    if (userExists.success) {
      throw new Error("Email already exists");
    }
    let { gasId } = await getGasId(email);
    await sendUserInvite({
      name: body?.name,
      email,
      id: gasId,
      organizationName,
      invitedBy,
    });
    let data = await axios.post(`${BASE_URL}/users/add-team-member`, {
      ...body,
      gasId,
    });
    console.log(data.data);
    return data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
// export const addTeamMembers = async (bodies) => {
//   try {
//     if (!Array.isArray(bodies) || bodies.length === 0) {
//       throw new Error("No team members provided");
//     }
//     if (bodies.length > 10) {
//       throw new Error("Add team members  limit exceed (max limit:10)");
//     }

//     const validBodies = bodies.filter((body) => isValidEmail(body.email || ""));
//     if (validBodies.length === 0) {
//       throw new Error("No valid emails provided");
//     }
//     const emailChecks = await Promise.all(
//       validBodies.map(async (body) => {
//         const { data: userExists } = await axios.get(
//           `${BASE_URL}/users/get-user/by-email?email=${body.email}`
//         );
//         return { email: body.email, exists: userExists.success };
//       })
//     );

//     const filteredBodies = validBodies.filter(
//       (body) => !emailChecks.find((check) => check.email === body.email)?.exists
//     );

//     if (filteredBodies.length === 0) {
//       throw new Error("All provided emails already exist");
//     }
//     const gasIds = await getGasIds(filteredBodies.map((body) => body.email));

//     const invites = filteredBodies.map((body) => {
//       const gasIdObj = gasIds.find((idObj) => idObj.email === body.email);
//       return {
//         name: body?.name,
//         email: body.email,
//         id: gasIdObj?.gasId || "",
//         organizationName: body.organizationName,
//         invitedBy: body.invitedBy,
//       };
//     });

//     const inviteResults = await sendUserInvites(invites);

//     const teamMembers = invites
//       .filter((invite) =>
//         inviteResults.some(
//           (result) => result.email === invite.email && result.success
//         )
//       )
//       .map((invite) => {
//         const matchingBody = bodies.find((b) => b.email === invite.email);
//         return {
//           ...matchingBody,
//           gasId: invite.id,
//         };
//       });

//     const addResults = await Promise.all(
//       teamMembers.map((member) =>
//         axios.post(`${BASE_URL}/users/add-team-member`, member)
//       )
//     );

//     console.log(
//       "Added team members:",
//       addResults.map((res) => res.data)
//     );
//     return addResults.map((res) => res.data);
//   } catch (error) {
//     console.error("Error adding team members:", error);
//     throw error;
//   }
// };
