import React from "react";
import { getDashboardInvoiceTableData } from "../../../Actions/DashboardActions";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import NoData from "../../../components/NoData";
const MyInvoices = ({ user }) => {
  const navigate = useNavigate();
  // invoice table data
  const {
    data: invoicesData,
    isLoading: invoicesDataLoading,
    isError: invoicesDataError,
  } = useQuery({
    queryFn: () => getDashboardInvoiceTableData(user?.organizationId),
    queryKey: ["dashboardInvoices", user?.organizationId],
    enabled: !!user?.organizationId,
    staleTime: 10000,
    cacheTime: 15000,
  });
  // console.log({ invoicesData, invoicesDataLoading, invoicesDataError });
  return (
    <div className="w-[40%] dashboardShadow h-[360px]">
      <div className="mx-5 py-6  flex items-center justify-between">
        <h1 className="text-[#333] text-[16px] font-[500] inter">
          My Invoices
        </h1>
        <p
          className="text-[#ACACAC] text-[14px] font-[400] cursor-pointer hover:text-primary hover:font-[500] hover:underline underline-offset-[3px] hover:transition-all duration-500 ease-linear decoration-2 overflow-hidden"
          onClick={(e) => navigate("/invoice-billing")}
        >
          See all
        </p>
      </div>
      <div className=" mx-5 w-[95%]  max-h-[340px] overflow-y-auto containerScroll ">
        <table className=" w-full  ">
          <thead className="">
            <tr className=" border-b border-[#E0E0E0]">
              {[
                "Invoice No.",
                "Completes",
                "Avg CPI",
                "Amount",
                "Status",
                "Date",
              ]?.map((data, ind) => (
                <td
                  className="text-[#999999] pb-3 text-[10px] font-[500]"
                  key={ind}
                >
                  {data}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {invoicesDataLoading ? (
              <tr>
                <td colSpan={6}>
                  <div className="w-full text-center py-6 text-[#666] font-[500] text-[18px] flex items-center justify-center flex-col space-y-5 h-[250px]">
                    <h1 className="text-[#666666] text-[14px] ml-8 font-[300] tracking-wide flex gap-[10px] items-center">
                      Loading...
                    </h1>
                  </div>
                </td>
              </tr>
            ) : invoicesDataError ? (
              <tr>
                <td colSpan={6}>
                  <div className="w-full text-center py-6 text-[#666] font-[500] text-[18px] flex items-center justify-center flex-col space-y-5 h-[250px]">
                    <h1 className="text-[#e12121] text-[14px] ml-8 font-[300] tracking-wide flex gap-[10px] items-center">
                      Something went wrong !
                    </h1>
                  </div>
                </td>
              </tr>
            ) : invoicesData?.length ? (
              invoicesData?.map((data) => (
                <tr className="">
                  <td
                    className="text-[#333333] font-[400] text-[12px] py-4 cursor-pointer hover:text-primary hover:font-[500] hover:underline underline-offset-[3px] hover:transition-all duration-500 ease-linear decoration-2 overflow-hidden"
                    onClick={(e) => navigate(`/invoice-billing/${data?._id}`)}
                  >
                    {data?.invoiceNumber || "-"}
                  </td>
                  <td className="text-[#333333] font-[400] text-[12px] py-4 text-left">
                    {data?.completes || 0}
                  </td>
                  <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                    $ {data?.avgCPI || 0}
                  </td>
                  <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                    $ {data?.amount}
                  </td>

                  <td className="text-[#333333] font-[400] text-[12px] py-4 ">
                    {data?.paymentStats === "paid" ? (
                      <p className="bg-[#EDFBF4] rounded-[11px] w-[51px] h-[20px] flex items-center justify-center text-[#2FBB85] inter text-[10px] capitalize">
                        Paid
                      </p>
                    ) : (
                      <p className="bg-[#bbad2f37] rounded-[11px] w-[51px] h-[20px] flex items-center justify-center text-[#bbad2f] inter text-[10px] capitalize">
                        Unpaid
                      </p>
                    )}
                  </td>
                  <td className="text-[#333333] font-[400] text-[10px] py-4 ">
                    {new Date(data?.invoiceDate)?.toLocaleDateString("en-in", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>
                  <div className="w-full text-center py-6 text-[#666] font-[500] text-[18px] flex items-center justify-center flex-col space-y-5 h-[250px]">
                    <NoData />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MyInvoices;
