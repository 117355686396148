import React, { useCallback, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import Hashids from "hashids";
import axios from "axios";
import LoaderBTN from "../components/LoaderBTN";
import { BASE_URL, getCurrentSurvey } from "../Utlils";
import Skeleton from "../components/Skeleton";
const hashids = new Hashids("My Project");
var id = hashids.encodeHex("507f1f77bcf86cd799439011");
var hex = hashids.decodeHex(id);
const SurveyQualification = () => {
  const [qualificationConfig, setQualificationConfig] = useState({
    loading: true,
    error: false,
  });
  const [survey, setSurvey] = useState({});
  const { sId, supId, orgId } = useParams();
  const surveyID = sId;
  const lucidId = process.env.REACT_APP_LUCID_ID;
  const getSurvey = async () => {
    try {
      let data = await axios.get(`${BASE_URL}/survey/one?id=${surveyID}`);
      setSurvey(data.data);
    } catch (error) {
      console.log("Unable to get Survey details");
      setSurvey({});
    }
  };
  const gamma = localStorage.getItem("gamma");
  let sessionId = localStorage.getItem("session_id");
  let src = localStorage.getItem("srcID");
  let ref_id = localStorage.getItem("ref_id");
  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const [presecreenQuestions, setPresecreenQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  let [questionsData, setQuestionsData] = useState([]);
  const [multiResponse, setMultiResponse] = useState([]);
  const [hiddenPrescreeningQuestions, setHiddenPrescreeningQuestions] =
    useState([]);
  const [lucidQuestions, setLucidQuestions] = useState([]);
  // !----------functions---------------------
  const handleQuestionData = (qId, name, e) => {
    let newArr = [...questionsData]?.filter(
      (data) => data?.question_id !== qId
    );
    newArr.push({
      question_id: qId,
      question_name: name,
      user_response: e.target.value,
    });
    setQuestionsData(newArr);
  };
  const handlePrescreenEnter = (e) => {
    e.preventDefault();
    // console.log("enter called");
    // if (presecreenQuestions?.length - 1 !== currentIndex) {
    //   checkIsAnswerCorrect(e, null, "plusOne");
    // } else {
    //   checkIsAnswerCorrect(e, "lastQuestion");
    // }
  };
  const handleMultiResposnseChange = (e, id, option, index) => {
    if (e.target.checked) {
      let singArr = questionsData?.filter((data) => data.questionId === id);
      // console.log("singleArr", singArr);
      // console.log("includes", singArr[0].response.includes(index));
      if (singArr?.length) {
        if (!singArr[0].response.includes(index)) {
          singArr[0]?.response?.push(index);
        }
      } else {
        singArr = [{ questionId: id, response: [index] }];
      }
      let newArr = [...questionsData, ...singArr];
      setQuestionsData(
        [...new Set(newArr?.map(JSON.stringify))].map(JSON.parse)
      );
    } else {
      let singArr = questionsData?.filter((data) => data.questionId === id);
      let filtereRes = singArr[0]?.response?.filter((data) => data !== index);
      let filteredArray = questionsData?.filter(
        (data) => data.questionId !== id
      );
      let finalArray = [
        ...filteredArray,
        { questionId: id, response: filtereRes },
      ];
      setQuestionsData(
        [...new Set(finalArray?.map(JSON.stringify))].map(JSON.parse)
      );
    }
  };
  let hideQuestions = ["MS_is_tablet", "MS_is_mobile"];
  const getLucidQuestions = async () => {
    try {
      let { data: lucidData } = await axios.get(
        `${BASE_URL}/session/${surveyID}/lucid-qualifications`
      );
      setLucidQuestions(lucidData?.data);
    } catch (error) {
      setLucidQuestions([]);
    }
  };
  const getTableQualification = async (e) => {
    try {
      setQualificationConfig((prev) => ({ ...prev, loading: true }));
      setPresecreenQuestions([]);
      let data = (
        await axios.get(`${BASE_URL}/session/${survey?._id}/qualifications`)
      ).data;
      setPresecreenQuestions(
        data?.filter(
          (d) =>
            !hideQuestions?.includes(d?.question?.questionName?.trim()) &&
            d?.status
        )
      );

      setHiddenPrescreeningQuestions(
        data?.filter(
          (d) =>
            hideQuestions?.includes(d?.question?.questionName?.trim()) &&
            d?.status
        )
      );
      setQualificationConfig((prev) => ({ ...prev, loading: false }));
    } catch (error) {
      setQualificationConfig({ error: true, loading: false });
    }
  };

  //   !-----------------------UseEffects-----------------
  const calcProg = presecreenQuestions?.length
    ? ((currentIndex + 1) / presecreenQuestions?.length) * 100
    : 20;

  useEffect(() => {
    if (survey?._id) {
      getTableQualification();
      if (survey?.createdBy === lucidId) {
        getLucidQuestions();
      }
    } else {
      getSurvey();
    }
  }, [orgId, survey?._id]);

  const submitResponse = async () => {
    setTimeout(async () => {
      console.log("submitting");
      await axios
        .put(
          `${BASE_URL}/session?id=${sessionId}`,

          {
            responses: questionsData,
            client_status: 1,
            mirats_status: 3,
            src: src,
          }
        )
        .then(async (data) => {
          // console.log(data);
          let survey = await getCurrentSurvey(surveyID);
          let url = survey?.setup?.liveUrl;
          let testURL = survey?.setup?.testUrl;
          // console.log(url, survey);
          if (gamma !== "alpha") {
            if (url) {
              // console.log("inside if ");
              var modifiedUrl = url.replace("[%rid%]", data?.data?.ref_id);
              if (survey?.createdBy === lucidId) {
                let newLucidUrl = await handleLucidRedirect(modifiedUrl);
                if (newLucidUrl?.success) {
                  window.location.href = newLucidUrl?.redirectURL;
                } else {
                  console.log("Unable to redirect");
                }
              } else {
                window.location.href = modifiedUrl;
              }
            } else {
              // alert("Live Url is not set");
              console.log("live url is not set");
            }
          } else {
            if (testURL) {
              // console.log("inside if ");
              var modifiedUrl = testURL.replace("[%rid%]", data?.data?.ref_id);
              if (survey?.createdBy === lucidId) {
                let newLucidUrl = await handleLucidRedirect(modifiedUrl);
                if (newLucidUrl?.success) {
                  window.location.href = newLucidUrl?.redirectURL;
                } else {
                  console.log("Unable to redirect");
                }
              } else {
                window.location.href = modifiedUrl;
              }
            } else {
              // alert("Live Url is not set");
              console.log("Test url is not set");
            }
          }
        })
        .catch((er) => {
          console.log(er);
          setSpinnerLoader(false);
        });
    }, 0);
  };

  const userAgent = navigator.userAgent;
  const getPlatform = () => {
    if (/Android/i.test(userAgent)) return "Android";
    if (/iPhone|iPod/i.test(userAgent)) return "iOS";
    if (/iPad/i.test(userAgent)) return "iPad";
    return "Unknown";
  };
  const deviceType = () => {
    const platform = getPlatform();
    if (platform === "Android" || platform === "iOS" || platform === "iPad") {
      if (platform === "iPad" || userAgent.includes("Tablet")) {
        return "Tablet";
      }
      return "Mobile";
    }
    return "PC";
  };
  const handleLucidRedirect = async (url) => {
    try {
      let questionResStr = "";
      for (let question of presecreenQuestions) {
        let response = questionsData?.find(
          (d) => d?.question_id == question?._id
        );
        let lucidQuestionData = lucidQuestions?.find(
          (d) =>
            d?.QuestionID == Number(question?.question?.clientQuestionID) &&
            d?.countryCode == Number(question?.question?.countryCode)
        );
        if (response && lucidQuestionData) {
          if (question?.question?.questionType?.endsWith("end")) {
            questionResStr += `&${question?.question?.clientQuestionID}=${response?.user_response}`;
          } else {
            if (
              question?.question?.questionType === "Single Punch" ||
              question?.question?.questionType === "Dummy"
            ) {
              let singlePunchRes = lucidQuestionData?.QuestionOptions?.filter(
                (d) => d?.OptionText?.trim() == response?.user_response?.trim()
              );
              if (singlePunchRes?.length) {
                questionResStr += `&${question?.question?.clientQuestionID}=${singlePunchRes?.[0]?.Precode}`;
              }
            } else {
              response?.user_response?.forEach((d) => {
                let multiLucidQuestion =
                  lucidQuestionData?.QuestionOptions?.filter(
                    (op) => op?.OptionText?.trim() === d?.trim()
                  );

                if (multiLucidQuestion?.length) {
                  questionResStr += `&${question?.question?.clientQuestionID}=${multiLucidQuestion?.[0]?.Precode}`;
                }
              });
            }
          }
        }
      }
      if (hiddenPrescreeningQuestions?.length) {
        let device = deviceType();
        for (let hiddenQuestion of hiddenPrescreeningQuestions) {
          if (hiddenQuestion?.question?.questionName === "MS_is_mobile") {
            if (device === "Mobile") {
              questionResStr += `&${hiddenQuestion?.question?.clientQuestionID}=true`;
            } else {
              questionResStr += `&${hiddenQuestion?.question?.clientQuestionID}=false`;
            }
          } else if (
            hiddenQuestion?.question?.questionName === "MS_is_tablet"
          ) {
            if (device === "Tablet") {
              questionResStr += `&${hiddenQuestion?.question?.clientQuestionID}=true`;
            } else {
              questionResStr += `&${hiddenQuestion?.question?.clientQuestionID}=false`;
            }
          }
        }
      }
      let urlBeforeHashing = url + questionResStr;
      let { data: lucidRedirects } = await axios.post(
        `${BASE_URL}/redirect/get-lucid-redirect`,
        {
          baseUrl: urlBeforeHashing,
        }
      );
      return {
        success: true,
        profilingParams: urlBeforeHashing,
        redirectURL: lucidRedirects?.signedUrl,
      };
    } catch (error) {
      console.log("Unable to perform lucid redirects", error);
      return { success: false };
    }
  };
  // console.log({
  //   lastData: presecreenQuestions[currentIndex],
  //   currentData: questionsData[questionsData?.length - 1],
  // });
  const checkIsAnswerCorrect = async (e, lastQuestion, updateState) => {
    // console.log("checkIsAnswerCorrect called ");
    setSpinnerLoader(true);
    let lastData = presecreenQuestions[currentIndex];
    let currentData = questionsData[questionsData?.length - 1];
    // console.log("lastData", lastData, "currentData", currentData);

    if (lastData?.question?.questionType === "Multi Punch") {
      if (currentData) {
        let allMatched = true;
        for (let i = 0; i < currentData?.user_response?.length; i++) {
          allMatched = lastData?.validOptions?.some(
            (d) => d == currentData?.user_response[i]
          );
          if (allMatched) {
            break;
          }
        }
        if (allMatched) {
          // console.log("All Answer is correct");
          let qualificationQuotaFull = false;
          for (let i = 0; i < currentData?.user_response?.length; i++) {
            const isQuestionOverQuota = await axios.post(
              `${BASE_URL}/session/qualification-quota/${surveyID}/${lastData?._id}`,
              { userRes: currentData?.user_response[i] }
            );
            if (isQuestionOverQuota.data) {
              qualificationQuotaFull = true;
              break;
            }
          }
          if (!qualificationQuotaFull) {
            lastQuestion && submitResponse();
            updateState && setCurrentIndex((prev) => prev + 1);
          } else {
            await axios
              .put(`${BASE_URL}/session?id=${sessionId}`, {
                responses: questionsData,
                client_status: -1,
                mirats_status: 40,
              })
              .then((data) => {
                // console.log(data);
                window.location.href = `${
                  window.location.origin
                }/7e08091a73b14e034889265e41ba796f91c766ad/${encodeURIComponent(
                  ref_id
                )}/40`;
              })
              .catch((er) => console.log(er));
          }
        } else {
          // alert("You have been terminated !!");
          // console.log("Answer is wrong");
          await axios
            .put(`${BASE_URL}/session?id=${sessionId}`, {
              responses: questionsData,
              client_status: -1,
              mirats_status: 23,
            })
            .then((data) => {
              // console.log(data);

              window.location.href = `${
                window.location.origin
              }/7e08091a73b14e034889265e41ba796f91c766ad/${encodeURIComponent(
                ref_id
              )}/20`;
            })
            .catch((er) => console.log(er));
        }
      } else {
        // console.log("No answers in prescreen");
      }
    } else if (lastData?.question?.questionType === "Single Punch") {
      if (
        lastData?.validOptions?.includes(currentData?.user_response?.trim())
      ) {
        const isQuestionOverQuota = await axios.post(
          `${BASE_URL}/session/qualification-quota/${surveyID}/${lastData?._id}`,
          { userRes: currentData?.user_response }
        );
        if (!isQuestionOverQuota.data) {
          lastQuestion && submitResponse();
          updateState && setCurrentIndex((prev) => prev + 1);
        } else {
          // console.log("isQuestionOverQuota=>", isQuestionOverQuota);
          await axios
            .put(`${BASE_URL}/session?id=${sessionId}`, {
              responses: questionsData,
              client_status: -1,
              mirats_status: 40,
            })
            .then((data) => {
              // console.log(data);
              window.location.href = `${
                window.location.origin
              }/7e08091a73b14e034889265e41ba796f91c766ad/${encodeURIComponent(
                ref_id
              )}/40`;
            })
            .catch((er) => console.log(er));
        }
      } else {
        // alert("You have been terminated !!");
        // console.log("Answer is wrong");
        await axios
          .put(`${BASE_URL}/session?id=${sessionId}`, {
            responses: questionsData,
            client_status: -1,
            mirats_status: 23,
          })
          .then((data) => {
            // console.log(data);
            window.location.href = `${
              window.location.origin
            }/7e08091a73b14e034889265e41ba796f91c766ad/${encodeURIComponent(
              ref_id
            )}/20`;
          })
          .catch((er) => console.log(er));
      }
    } else if (lastData?.question?.questionType === "Numeric - Open-end") {
      // console.log("inside numeric condition");
      if (survey?.createdBy === lucidId && lastData?.preCodes?.length) {
        // console.log("inside lucid condition");
        if (
          lastData?.preCodes?.includes(
            String(currentData?.user_response)?.trim()
          )
        ) {
          // console.log("inside lucid correct condition");
          if (lastQuestion) {
            submitResponse();
          }
          if (updateState) {
            setCurrentIndex((prev) => prev + 1);
            setSpinnerLoader(false);
          }
          return;
        }
      } else {
        if (
          Number(currentData?.user_response) >=
            Number(lastData?.validOptions?.[0]?.from) &&
          Number(currentData?.user_response) <= lastData?.validOptions?.[0]?.to
        ) {
          // console.log(" answer is correct");
          // console.log(lastQuestion, updateState);
          if (lastQuestion) {
            submitResponse();
          }
          if (updateState) {
            setCurrentIndex((prev) => prev + 1);
            setSpinnerLoader(false);
          }

          return;
        }
      }
      // alert("You have been terminated !!");
      // console.log("Answer is wrong");
      try {
        // console.log("Answer is wrong terminating user");
        await axios.put(`${BASE_URL}/session?id=${sessionId}`, {
          responses: questionsData,
          client_status: -1,
          mirats_status: 23,
        });
        window.location.href = `${
          window.location.origin
        }/7e08091a73b14e034889265e41ba796f91c766ad/${encodeURIComponent(
          ref_id
        )}/20`;
      } catch (error) {
        console.error("Error updating session:", error);
      }
    } else {
      lastQuestion && submitResponse();
      updateState && setCurrentIndex((prev) => prev + 1);
    }
    if (presecreenQuestions?.length - 1 !== currentIndex) {
      setSpinnerLoader(false);
    }
  };
  // console.log("presecreenQuestions=>", presecreenQuestions);
  // console.log("lucidQuestions=>", lucidQuestions);
  // console.log(survey);
  // console.log("questionsData=>", questionsData);
  // console.log("hiddenPrescreeningQuestions=>", hiddenPrescreeningQuestions);
  return (
    <div className="h-[100dvh] relative">
      <div className="w-full h-[10px] bg-[#EAECF2]  rounded-[24px] ">
        <div
          style={{ width: `${calcProg}%` }}
          className={` bg-primary h-[10px]  rounded-[24px] myTransition`}
        >
          {/* <span>{calcProg}%</span> */}
        </div>
      </div>
      <div className="pt-[70px] h-full ipad:py-[44px]">
        <img
          src="https://esomar.mirats.in/unnamed.png"
          alt="logo"
          className="w-[160px] mx-[150px] ipad:ml-6"
        />
        <section className="mt-[80px] ipad:mt-[50px] pb-5">
          <form
            action=""
            onSubmit={(e) => {
              // console.log("qweoiuwqeo");
              e.preventDefault();
              // console.log("onSubmit");
              if (presecreenQuestions?.length - 1 !== currentIndex) {
                checkIsAnswerCorrect(e, null, "plusOne");
              } else {
                checkIsAnswerCorrect(e, "lastQuestion");
              }
            }}
          >
            {qualificationConfig?.loading ? (
              <div className="mx-[150px] mt-[20px] tablet:mt-[50px] tablet:mx-[30px] ipad:mx-[24px] space-y-4">
                <Skeleton className="w-[450px] h-[40px]" />
                <div className="space-y-3">
                  {[1, 2, 3, 4, 5]?.map((d) => (
                    <div key={d} className="flex items-center gap-4">
                      <Skeleton className="w-[30px] h-[30px] rounded-full" />
                      <Skeleton className="w-[140px] h-[30px]" />
                    </div>
                  ))}
                </div>
              </div>
            ) : qualificationConfig?.error ? (
              <div className="mx-[150px] mt-[20px] tablet:mt-[50px] tablet:mx-[30px] ipad:mx-[24px] space-y-4">
                <h1 className="text-lg">Something went wrong..</h1>
              </div>
            ) : (
              <div className="">
                {presecreenQuestions?.map((element, index) => {
                  if (index === currentIndex) {
                    if (
                      element?.question?.questionType == "Single Punch" ||
                      element?.question?.questionType == "Dummy"
                    ) {
                      return SinglePunch({
                        element,
                        handleQuestionData,
                        questionsData,
                        setQuestionsData,
                        handlePrescreenEnter,
                      });
                    }
                    if (element?.question?.questionType === "Text - Open-end") {
                      return TextEnd({
                        element,
                        handleQuestionData,
                        handlePrescreenEnter,
                      });
                    }
                    if (
                      element?.question?.questionType === "Numeric - Open-end"
                    ) {
                      return NumericOpenEnd({
                        element,
                        handleQuestionData,
                        handlePrescreenEnter,
                      });
                    }
                    if (element?.question?.questionType === "Multi Punch") {
                      return MultiPunch({
                        element,
                        handleQuestionData,
                        multiResponse,
                        setMultiResponse,
                        handleMultiResposnseChange,
                        handlePrescreenEnter,
                        questionsData,
                        setQuestionsData,
                      });
                    }
                  }
                })}
                <section className=" mx-[150px] mt-[20px] tablet:mt-[50px] tablet:mx-[30px] ipad:mx-[24px] submitQualificationContainer">
                  {spinnerLoader ? (
                    <LoaderBTN />
                  ) : presecreenQuestions?.length - 1 !== currentIndex ? (
                    <button
                      type="submit"
                      disabled={spinnerLoader}
                      className={`  px-4 py-3 text-white font-semibold rounded w-[118px] text-[14px] ${
                        spinnerLoader
                          ? "cursor-not-allowed  bg-primary/40 hover:bg-[#1e64fb]"
                          : "cursor-pointer  bg-primary hover:bg-[#1e64fb]"
                      }`}
                    >
                      Next{" "}
                    </button>
                  ) : (
                    <button
                      type="submit"
                      disabled={spinnerLoader}
                      className="bg-primary hover:bg-[#1e64fb] px-4 py-3 text-white font-semibold rounded w-[118px] text-[14px] "
                    >
                      Submit
                    </button>
                  )}
                </section>
              </div>
            )}
          </form>
        </section>
      </div>
    </div>
  );
};
const TextEnd = ({ element, handleQuestionData, handlePrescreenEnter }) => {
  // console.log("inside text");
  return (
    <div
      className="px-[150px] ipad:px-[24px] h-[65dvh] ipad:h-[55dvh]"
      key={element?._id}
    >
      <section className="flex flex-col gap-y-3 h-[50dvh] ipad:h-[45dvh]  overflow-y-auto ">
        <label htmlFor="age" className="text-[24px] font-[500] capitalize">
          {element?.question?.questionText}
        </label>
        <input
          type="text"
          name="age"
          onChange={(e) =>
            handleQuestionData(element?._id, element?.question?.questionName, e)
          }
          required
          className="border border-[#333] text-[14px] text-[#333] py-3 px-4 outline-none rounded w-[500px] textEnd ipad:w-[100%]"
        />
      </section>
    </div>
  );
};
const NumericOpenEnd = ({
  element,
  handleQuestionData,
  handlePrescreenEnter,
}) => {
  // console.log("inside numeric");
  return (
    <div
      className="px-[150px] ipad:px-[24px] h-[65dvh] ipad:h-[55dvh]"
      key={element?._id}
    >
      <section className="flex flex-col gap-y-3 h-[50dvh] ipad:h-[45dvh]  overflow-y-auto ">
        <label htmlFor="age" className="text-[24px] font-[500] capitalize">
          {element?.question?.questionText}
        </label>
        <input
          type="number"
          name="age"
          required
          className="border border-[#333] text-[14px] text-[#333] py-3 px-4 outline-none rounded w-[500px] textEnd ipad:w-[100%]"
          onChange={(e) =>
            handleQuestionData(element?._id, element?.question?.questionName, e)
          }
        />
      </section>
    </div>
  );
};
const SinglePunch = ({
  element,
  handleQuestionData,
  handlePrescreenEnter,
  questionsData,
  setQuestionsData,
}) => {
  const handleSingleChange = (qId, e, ind, data) => {
    // console.log("QID=>", qId, "Ind", ind);
    let newArr = [...questionsData]?.filter(
      (data) => data?.question_id !== qId
    );
    newArr.push({
      question_id: qId,
      question_name: element?.question?.questionName,
      user_response: data,
    });
    setQuestionsData(newArr);
  };

  return (
    <div
      className="px-[150px] ipad:px-[24px] h-[65dvh] ipad:h-[55dvh]"
      key={element?._id}
    >
      <h1 className="mb-[39px] text-[24px] capitalize ipad:text-[20px] ">
        {element?.question?.questionText}
      </h1>
      <section className="flex flex-col gap-y-3 h-[50dvh] ipad:h-[40dvh]  overflow-y-auto singlePunchContainer">
        {element?.displayOptions?.map((data, index) => (
          <>
            <div class="flex items-center cursor-pointer singlePunchOption">
              <label className="container capitalize">
                {data}
                <input
                  type="radio"
                  // checked="checked"
                  required
                  name="singlePunch"
                  id={data}
                  onChange={(e) => {
                    handleSingleChange(element?._id, e, index, data);
                  }}
                />
                <span className="radio"></span>
              </label>
            </div>
          </>
        ))}
      </section>
    </div>
  );
};

const MultiPunch = ({
  element,
  handleQuestionData,
  multiResponse,
  setMultiResponse,
  handlePrescreenEnter,
  questionsData,
  setQuestionsData,
}) => {
  // console.log("multipunch");
  // console.log(element);
  // console.log(questionsData);
  const handleMultiResposnseChange = (e, id, option, index) => {
    if (e.target.checked) {
      let singArr = questionsData?.filter((data) => data.question_id === id);
      // console.log("singleArr", singArr);
      // console.log("includes", singArr[0]?.user_response?.includes(index));
      if (singArr?.length) {
        if (!singArr[0].user_response.includes(option)) {
          singArr[0]?.user_response?.push(option);
        }
      } else {
        singArr = [
          {
            question_id: id,
            question_name: element?.name,
            user_response: [option],
          },
        ];
      }
      let newArr = [...questionsData, ...singArr];
      setQuestionsData(
        [...new Set(newArr?.map(JSON.stringify))].map(JSON.parse)
      );
    } else {
      let singArr = questionsData?.filter((data) => data.question_id === id);
      let filtereRes = singArr[0]?.user_response?.filter(
        (data) => data !== option
      );
      let filteredArray = questionsData?.filter(
        (data) => data.question_id !== id
      );
      let finalArray = [
        ...filteredArray,
        { question_id: id, user_response: filtereRes },
      ];
      setQuestionsData(
        [...new Set(finalArray?.map(JSON.stringify))].map(JSON.parse)
      );
    }
  };
  let testMulti = 0;

  return (
    <div
      className="px-[150px] ipad:px-[24px] h-[65dvh] ipad:h-[55dvh]"
      key={element?._id}
    >
      <h1 className="mb-[39px] text-[24px] ipad:text-[20px] capitalize">
        {element?.question?.questionText}
      </h1>
      <section className="flex flex-col gap-y-3 h-[45dvh] ipad:h-[40dvh]  overflow-y-auto singlePunchContainer">
        {element?.displayOptions?.map((option, index) => (
          <>
            <div class="flex cursor-pointer multiPunchOption">
              <label className="containerCheck capitalize">
                {option}
                <input
                  type="checkbox"
                  id={index}
                  required={
                    !questionsData?.some(
                      (cData) =>
                        cData?.question_id == element?._id &&
                        cData?.user_response?.length >= 1
                    )
                  }
                  name="multiPunch"
                  value={option}
                  onChange={(e) =>
                    handleMultiResposnseChange(e, element?._id, option, index)
                  }
                />
                <span className="check"></span>
              </label>
            </div>
          </>
        ))}
      </section>
    </div>
  );
};

export default SurveyQualification;
