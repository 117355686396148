import React from "react";
import Sidebar from "./components/Sidebar";
import { useParams } from "react-router-dom";
import MyProfile from "./components/MyProfile";
import MyAccount from "./components/MyAccount";
import MyNotification from "./components/MyNotification";
import MyMembers from "./components/MyMembers";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import GlobalProjectSetting from "./components/GlobalProjectSetting";
import Finance from "./components/Finance";
import RedirectsAndKey from "./components/RedirectsAndKey";
import PostBack from "./components/PostBack";

const Profile = () => {
  const { type } = useParams();
  const { user, setUser } = useGlobalContext();
  const renderComponent = () => {
    switch (type) {
      case "my-profile":
        return <MyProfile user={user} setUser={setUser} />;
      // case "my-account":
      //   return <MyAccount user={user} setUser={setUser} />;
      case "finance":
        return <Finance user={user} setUser={setUser} />;
      case "my-notification":
        return <MyNotification user={user} setUser={setUser} />;
      case "my-member":
        return <MyMembers user={user} setUser={setUser} />;
      case "project-settings":
        return <GlobalProjectSetting user={user} setUser={setUser} />;
      case "redirects-and-keys":
        return <RedirectsAndKey user={user} setUser={setUser} />;
      case "post-back":
        return <PostBack user={user} setUser={setUser} />;
      default:
        return <h1>No Page</h1>;
    }
  };
  // console.log(type);

  return <Sidebar>{renderComponent()}</Sidebar>;
};

export default Profile;
