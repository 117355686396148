import axios from "axios";
import { BASE_URL } from "../Utlils";

export const getDashboardTableData = async (orgId) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/survey/dashboard/table-surveys/${orgId}`
    );
    return data.data;
  } catch (error) {
    console.log("Unable to get dashboard table data");
    throw error;
  }
};
export const getDashboardAnalytics = async (month, year, orgId) => {
  try {
    let data = await axios.get(
      `${BASE_URL}/survey/getDashboardSurveyAnalysis?month=${
        Number(month) + 1
      }&year=${year}&orgId=${orgId}`
    );
    return data.data;
  } catch (error) {
    console.log("Unable to get Dashboard analytics data");
    throw error;
  }
};
export const getDashboardInvoiceTableData = async (id) => {
  try {
    let data = await axios.get(
      `${BASE_URL}/survey/dashboard/invoice-table/${id}`
    );
    return data?.data?.data;
  } catch (error) {
    console.log("Unable to get Dashboard Invoice table data");
    throw error;
  }
};
export const getDashboardInvoiceGraphData = async (id, year) => {
  try {
    let data = await axios.get(
      `${BASE_URL}/survey/dashboard/invoice-graph/${id}?year=${year}`
    );
    return data?.data;
  } catch (error) {
    console.log("Unable to get Dashboard Invoice graph data");
    throw error;
  }
};
export const getDashboardSurveyGroups = async (id) => {
  try {
    let surveyGroups = (
      await axios.get(`${BASE_URL}/survey/dashboard/survey-group/${id}`)
    ).data;
    return surveyGroups.data;
  } catch (error) {
    console.log("Unable to get Dashboard survey group data");
    throw error;
  }
};
export const getDashboardQuestions = async (id) => {
  try {
    let questions = (
      await axios.get(`${BASE_URL}/survey/dashboard/questions/${id}`)
    ).data;
    return questions.data;
  } catch (error) {
    console.log("Unable to get Dashboard questions data");
    throw error;
  }
};
