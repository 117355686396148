import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import { useGlobalContext } from "../../../../Contexts/GlobalContext";
import axios from "axios";
import { BASE_URL } from "../../../../Utlils";
import DeleteModal from "../../../../components/DeleteModal";
import { TextField } from "@mui/material";
import AllocationsLoader from "./AllocationsLoader";

const LinksModalstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
};

const Allocations = ({ data, type, id, getSingleSurvey }) => {
  const { setSnackbar, user } = useGlobalContext();
  const [deleteM, setDeleteM] = useState({ open: false });
  const [linksModal, setLinksModal] = useState(false);
  const [supplierStats, setSupplierStats] = useState({
    loading: true,
    data: [],
    error: false,
  });
  const [linksData, setLinksData] = useState({
    liveLink: "",
    testLink: "",
  });

  const getSupplierStats = async () => {
    try {
      setSupplierStats((prev) => ({ ...prev, loading: true, data: [] }));
      data?.allocation?.forEach((d) => {
        axios
          .get(
            `${BASE_URL}/session/get-supplier-stats?supId=${d?.supplierAccount?._id}&sId=${id}`
          )
          .then((response) =>
            setSupplierStats((prev) => ({
              data: [...prev?.data, response.data],
              loading: false,
              error: false,
            }))
          );
      });
    } catch (error) {
      setSupplierStats((prev) => ({ ...prev, loading: false, error: true }));
      console.log("error in getSupplierStats", error?.message);
    }
  };
  useEffect(() => {
    if (data?._id) {
      getSupplierStats();
    }
  }, [data?._id]);
  const handleCloseModal = (e) => setDeleteM((prev) => ({ open: false }));
  const deleteSupplier = async (e) => {
    e.preventDefault();
    axios
      .put(
        `${BASE_URL}/survey/delete-supplier?sid=${id}&aid=${deleteM?.data?._id}`
      )
      .then((res) => {
        getSingleSurvey();
        setDeleteM({ open: false });
        setSnackbar({
          msg: "Supplier Deleted Successfully !",
          open: true,
          severity: "success",
        });
      })
      .catch((er) => {
        getSingleSurvey();
        setDeleteM({ open: false });
        setSnackbar({
          msg: "Something went wrong !",
          open: false,
          severity: "error",
        });
      });
  };
  // console.log(allSuppliers);
  // console.log(teamMembers);
  // console.log(inputData);
  // console.log(filter);
  // console.log(edit);
  // console.log("supplierStats=>", supplierStats);
  // console.log(data);
  return (
    <div className="w-full flex flex-col gap-[28px] ">
      <div className="w-full tableBorder flex flex-col">
        {supplierStats?.loading ? (
          <AllocationsLoader />
        ) : supplierStats?.error ? (
          <div className=" h-[200px] flex items-center justify-center">
            <h1 className="text-[#666666]">Something went wrong </h1>
          </div>
        ) : (
          <div className="w-full overflow-x-auto containerScroll">
            <table
              className="whitespace-nowrap text-left w-full"
              id="table-to-xls-DATA"
            >
              <thead className=" h-[45px] ">
                <tr className="py-3">
                  <th className="text-[#666666] text-[12px] font-[600] px-3">
                    Supplier Name
                  </th>
                  <th className="text-[#666666] text-[12px] font-[600] px-3">
                    Allocation
                  </th>
                  <th className="text-[#666666] text-[12px] font-[600] px-3">
                    Hits
                  </th>
                  <th className="text-[#666666] text-[12px] font-[600] px-3">
                    Prescreens
                  </th>
                  <th className="text-[#666666] text-[12px] font-[600] px-3 ">
                    Completes
                  </th>
                  <th className="text-[#666666] text-[12px] font-[600] px-3 ">
                    Terminate
                  </th>
                  <th className="text-[#666] text-[12px] font-[600] px-3">
                    Allocation Remaining
                  </th>
                  <th className="text-[#666] text-[12px] font-[600] px-3">
                    Conversion
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.allocation?.map((allocation, ind) => (
                  <>
                    {supplierStats?.data?.map((sd, key) => {
                      if (sd?.supplier === allocation?.supplierAccount?._id) {
                        return (
                          <tr
                            key={key}
                            className=" tableBorder transition-all cursor-pointer hover:bg-[#f6f9ff]"
                            onClick={(e) => {
                              // !disable this entire onclick to remove model
                              e.stopPropagation();
                              setLinksModal(true);
                              setLinksData({
                                liveLink: `${window.location.origin}/DIY/v2/${id}/${allocation?.supplierAccount?._id}/${user?.organizationId}/start?t=0&r=xxxxx`,
                                testLink: `${window.location.origin}/DIY/v2/${id}/${allocation?.supplierAccount?._id}/${user?.organizationId}/start?t=1&r=xxxxx`,
                              });
                            }}
                          >
                            <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                              {allocation?.supplierAccount?.supplierName || "-"}
                            </td>
                            <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                              {allocation?.allocation?.number || 0}
                            </td>
                            <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                              {sd?.hits || 0}
                            </td>
                            <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                              {sd?.prescreen || 0}
                            </td>
                            <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                              {sd?.completed || 0}
                            </td>
                            <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                              {sd?.term || 0}
                            </td>
                            <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                              {Number(
                                allocation?.allocation?.number - sd?.completed
                              ) > 0
                                ? allocation?.allocation?.number - sd?.completed
                                : 0}
                            </td>
                            <td className="px-3 py-5 text-[#667085] font-[400] text-[14px] ">
                              {sd?.conversion?.toFixed(2) || 0} %
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <DeleteModal
        open={deleteM?.open}
        handleClose={handleCloseModal}
        text={deleteM?.data?.supplierAccount?.supplierName}
        handleClick={deleteSupplier}
      />

      {/* LINKS MODAL */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={linksModal}
        onClose={() => {
          setLinksModal(false);
          setLinksData({
            liveLink: "",
            testLink: "",
          });
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={linksModal}>
          <Box sx={LinksModalstyle}>
            <div className="w-full py-8 flex items-center flex-col">
              <div className=" w-[93%]  gap-[50px] flex flex-col">
                <div className="flex  justify-between items-center">
                  <span className="text-[23px] font-[600]">View Links</span>
                  <button
                    className="px-[16px] py-[8px] text-[#fff] rounded-[4px]  bg-[#0226BE] text-[16px] font-[400]"
                    onClick={(e) => {
                      navigator.clipboard.writeText(
                        `Live Link = ${linksData?.liveLink} \nTest Link = ${linksData?.testLink}`
                      );
                      setSnackbar({
                        msg: "Both Links Copied to Clipboard!",
                        open: true,
                        severity: "success",
                      });
                    }}
                  >
                    Copy Both Links
                  </button>
                </div>
                <div className="flex w-full flex-col justify-between  items-center gap-[25px]">
                  <div className="flex w-full justify-between gap-[15px] ">
                    <TextField
                      id="outlined-required"
                      label="Live Links"
                      className="border border-[#c7c7c7] w-[82%] rounded-[5px]"
                      value={linksData?.liveLink}
                      disabled
                    />
                    <button
                      className="px-[16px] py-[6px] text-[#fff] rounded-[4px]  bg-[#0226BE] text-[15px] font-[400]"
                      onClick={() => {
                        navigator.clipboard.writeText(linksData?.liveLink);
                        setSnackbar({
                          msg: "Live Link Copied to Clipboard!",
                          open: true,
                          severity: "success",
                        });
                      }}
                    >
                      Copy Link
                    </button>
                  </div>
                  <div className="flex w-full justify-between gap-[15px] ">
                    <TextField
                      id="outlined-required"
                      label="Test Links"
                      className="border border-[#c7c7c7] w-[82%]  rounded-[5px]"
                      value={linksData?.testLink}
                      disabled
                    />
                    <button
                      className="px-[16px] py-[8px] text-[#fff] rounded-[4px]  bg-[#0226BE] text-[15px] font-[400]"
                      onClick={() => {
                        navigator.clipboard.writeText(linksData?.testLink);
                        setSnackbar({
                          msg: "Test Link Copied to Clipboard!",
                          open: true,
                          severity: "success",
                        });
                      }}
                    >
                      Copy Link
                    </button>
                  </div>
                </div>
              </div>

              {/* <div className="w-full py-6 border-b border-[#E0E0E0]  text-center relative">
                <h1 className="text-[#333333] text-[20px] font-[600]">
                  {edit?.isEdit ? "Edit" : "Add"} Supplier
                </h1>
                <p
                  className=" bg-[#F1F1F1] py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={handleClose}
                >
                  <RxCross2 size={16} />
                </p>
              </div>

              <form
                className="w-full py-4 px-5  flex flex-col gap-[19px]"
                onSubmit={handleSubmit}
              >
                <div className="flex flex-col gap-[10px]">
                  <label className="text-[#333] text-[14px] font-[500]">
                    Supplier Account
                  </label>
                  <select
                    className="border border-[#E2E2E5] rounded-[4px] pl-3 h-[40px] focus:outline-none text-[#12112766] text-[14px]"
                    name="supplierAccount"
                    required
                    onChange={handleChange}
                    value={inputData?.supplierAccount}
                  >
                    <option value="" selected disabled>
                      Select Supplier Account
                    </option>
                    {allSuppliers
                      ?.filter((d) =>
                        !edit?.isEdit
                          ? d?._id !==
                            data?.allocation?.find(
                              (f) => f?.supplierAccount?._id === d?._id
                            )?.supplierAccount?._id
                          : d
                      )
                      ?.map((supplier, index) => (
                        <option
                          value={supplier?._id}
                          key={supplier?.supplierName}
                        >
                          {supplier?.supplierName}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label className="text-[#333] text-[14px] font-[500]">
                    Supplier Manager
                  </label>
                  <select
                    className="border border-[#E2E2E5] rounded-[4px] pl-3 h-[40px] focus:outline-none text-[#12112766] text-[14px]"
                    name="supplierManager"
                    required
                    value={inputData?.supplierManager}
                    onChange={handleChange}
                  >
                    <option value="">Select Supplier Manager</option>
                    {teamMembers?.map((member) => (
                      <option
                        value={member?._id}
                        key={member?.personalDetails?.fullName}
                      >
                        {member?.personalDetails?.fullName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label className="text-[#333] text-[14px] font-[500]">
                    CPI
                  </label>
                  <input
                    className="border border-[#E2E2E5] rounded-[4px] pl-3 h-[40px] focus:outline-none text-[#12112766] text-[14px]"
                    placeholder="Enter CPI"
                    name="cpi"
                    required
                    value={inputData?.cpi}
                    onChange={(e) =>
                      setInputData((prev) => ({
                        ...prev,
                        cpi: Number(e.target.value),
                      }))
                    }
                  />
                </div>
                <div className="w-full flex gap-[17px] items-center justify-between">
                  <div className="w-1/2 flex flex-col gap-[10px]">
                    <label className="text-[#333] text-[14px] font-[500]">
                      Allocation
                    </label>
                    <input
                      className="border border-[#E2E2E5] rounded-[4px] pl-3 h-[40px] focus:outline-none text-[#12112766] text-[14px]"
                      placeholder="Enter Allocation"
                      name="allocation"
                      required
                      value={inputData?.allocation?.number}
                      onChange={(e) =>
                        setInputData((prev) => ({
                          ...prev,
                          allocation: {
                            ...prev.allocation,
                            number: Number(e.target.value),
                          },
                        }))
                      }
                    />
                  </div>
                  <div className="w-1/2 flex flex-col gap-[10px]">
                    <label className="text-[#333] text-[14px] font-[500]">
                      To
                    </label>
                    <input
                      className="border border-[#E2E2E5] rounded-[4px] pl-3 h-[40px] focus:outline-none text-[#12112766] text-[14px]"
                      placeholder="%"
                      name="percentage"
                      required
                      value={inputData?.allocation?.percentage}
                      onChange={(e) =>
                        setInputData((prev) => ({
                          ...prev,
                          allocation: {
                            ...prev.allocation,
                            percentage: Number(e.target.value),
                          },
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label
                    htmlFor="SurveyName"
                    className="text-[#333] text-[14px] font-[500]"
                  >
                    Descriptions
                  </label>
                  <div className="flex w-full items-center relative">
                    <textarea
                      className="w-full border border-[#E2E2E5] rounded-[4px] pl-3 pt-2  placeholder:text-[14px] placeholder:text-[#12112766] focus:outline-none resize-none text-[#666] text-[14px]"
                      placeholder="Type here"
                      cols="30"
                      name="description"
                      value={inputData?.description}
                      onChange={handleChange}
                      rows="4"
                    ></textarea>
                  </div>
                </div>

                <button
                  type="submit"
                  className="text-[#fff] inter text-[16px] font-[400] bg-[#0226BE] rounded-[4px] py-2 "
                >
                  Save
                </button>
              </form> */}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Allocations;
