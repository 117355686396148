import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { getDashboardAnalytics } from "../../../Actions/DashboardActions";
import NoData from "../../../components/NoData";
import { Bar } from "react-chartjs-2";
const SurveyAnalytics = ({ allMonthsAndYear, user }) => {
  const [surveyOverviewFilters, setSurveyOverviewFilters] = useState({
    month: new Date().getMonth(),
    year: new Date()?.getFullYear(),
  });
  const {
    data: dashboardAnalyticsData,
    isLoading: dashboardAnalyticsDataLoading,
    isError: dashboardAnalyticsDataError,
  } = useQuery({
    queryFn: () =>
      getDashboardAnalytics(
        surveyOverviewFilters?.month,
        surveyOverviewFilters?.year,
        user?.organizationId
      ),
    queryKey: [
      "dashboardAnalytics",
      surveyOverviewFilters?.month,
      surveyOverviewFilters?.year,
      user?.organizationId,
    ],
    enabled: !!user?.organizationId,
    staleTime: 10000,
    cacheTime: 15000,
  });
  const BarChart = () => {
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            drawOnChartArea: false,
            display: false,
          },
          ticks: {
            font: {
              size: 8,
            },
            color: "#ACACAC",
          },
          border: {
            display: false,
          },
        },
        y: {
          stacked: true,
          grid: {
            drawOnChartArea: false,
            display: false,
          },
          ticks: {
            font: {
              size: 10,
            },
            color: "#ACACAC",
          },
          border: {
            display: false,
          },
        },
      },
    };
    let obj = { label: [], data1: [], data2: [] };

    for (
      let i = 1;
      i <= Object?.keys(dashboardAnalyticsData || {}).length;
      i++
    ) {
      let suffix = "th";

      if (i % 10 === 1 && i !== 11) {
        suffix = "st";
      } else if (i % 10 === 2 && i !== 12) {
        suffix = "nd";
      } else if (i % 10 === 3 && i !== 13) {
        suffix = "rd";
      }
      obj.label.push(i + suffix);
    }
    Object?.entries(dashboardAnalyticsData || {})?.map(([key, value]) => {
      obj.data1.push(value?.paused);
      obj.data2.push(value?.live);
    });
    const data = {
      labels: obj.label,

      datasets: [
        {
          label: "Paused",
          backgroundColor: "#F6CF7D",
          data: obj.data1,
          barThickness: 11,
          borderRadius: 10,
        },
        {
          label: "Live",
          backgroundColor: "#B6B0FB",
          data: obj.data2,
          barThickness: 11,
          borderRadius: 3,
        },
      ],
    };
    return <Bar options={options} data={data} height={80} />;
  };
  const handleFilterChange = (e, name) => {
    setSurveyOverviewFilters((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  // console.log(
  //   dashboardAnalyticsData,
  //   dashboardAnalyticsDataLoading,
  //   dashboardAnalyticsDataError
  // );
  return (
    <div className="w-[65%] dashboardShadow h-[360px] ">
      <div className="mx-5 py-5 border-b border-[#E0E0E0] flex items-center justify-between">
        <h1 className="text-[#333] text-[16px] font-[500] inter">
          Survey Analysis
        </h1>
        <div className="flex items-center gap-[33px]">
          <select
            className="text-[#0F2552] text-[12px] font-[600] px-3"
            value={surveyOverviewFilters?.year}
            name="year"
            onChange={(e) => handleFilterChange(e, "survey")}
          >
            <option value="">Year</option>
            {allMonthsAndYear?.years?.map((d) => (
              <option value={d}>{d}</option>
            ))}
          </select>
          <select
            className="text-[#0F2552] text-[12px] font-[600] px-3"
            value={surveyOverviewFilters?.month}
            name="month"
            onChange={(e) => handleFilterChange(e, "survey")}
          >
            <option value="">Month</option>
            {allMonthsAndYear?.months?.map((d) => (
              <option value={d?.value}>{d?.name}</option>
            ))}
          </select>
        </div>
      </div>
      {dashboardAnalyticsDataLoading ? (
        <div className="w-full text-center py-6 text-[#666] font-[500] text-[18px] flex items-center justify-center flex-col space-y-5 h-[250px]">
          <h1 className="text-[#666666] text-[14px] ml-8 font-[300] tracking-wide flex gap-[10px] items-center">
            Loading...
          </h1>
        </div>
      ) : dashboardAnalyticsDataError ? (
        <div className="w-full text-center py-6 text-[#666] font-[500] text-[18px] flex items-center justify-center flex-col space-y-5 h-[250px]">
          <h1 className="text-[#e12121] text-[14px] ml-8 font-[300] tracking-wide flex gap-[10px] items-center">
            Something went wrong !
          </h1>
        </div>
      ) : Object?.values(dashboardAnalyticsData || {})?.filter(
          (d) => d?.paused || d?.live
        )?.length ? (
        <>
          <div className="mx-5 my-2 flex items-center gap-[30px] ">
            <div>
              <h1 className="text-[#242424] text-[14px] font-[500]">
                {Object.values(dashboardAnalyticsData || {})?.reduce(
                  (acc, cur) => (acc += cur?.live),
                  0
                )}
              </h1>
              <div className="flex items-center gap-[7px]">
                <p className="bg-[#B6B0FB] w-[5px] h-[5px] rounded-[50%]"></p>
                <p className="text-[10px] text-[#7A7A7A] font-[600]">
                  Live Survey
                </p>
              </div>
            </div>
            <div>
              <h1 className="text-[#242424] text-[14px] font-[500]">
                {" "}
                {Object.values(dashboardAnalyticsData || {})?.reduce(
                  (acc, cur) => (acc += cur?.paused),
                  0
                )}
              </h1>
              <div className="flex items-center gap-[7px]">
                <p className="bg-[#F6CF7D] w-[5px] h-[5px] rounded-[50%]"></p>
                <p className="text-[10px] text-[#7A7A7A] font-[600]">
                  Pause Survey
                </p>
              </div>
            </div>
          </div>
          <div className="mx-5 my-5 h-[220px] w-[95%] ">
            <div className="w-full h-[100%] ">
              <BarChart />
            </div>
          </div>
        </>
      ) : (
        <div className="h-[80%] flex items-center justify-center">
          <NoData msg={<h1>Try Changing the filters</h1>} />
        </div>
      )}
    </div>
  );
};

export default SurveyAnalytics;
