import { Box, CircularProgress, Fade, Modal, Tooltip } from "@mui/material";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useGlobalContext } from "../../../../Contexts/GlobalContext";
import { BASE_URL, generateRandomName } from "../../../../Utlils";
import Backdrop from "@mui/material/Backdrop";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
};

const DetailHeader = ({ data, getSingleSurvey, singleSurveyStats }) => {
  let isDetailPage = window.location.pathname;
  const queryClient = useQueryClient();
  const [isORGAuthorized, setIsORGAuthorized] = useState(false);
  const [externalNameModal, setExternalNameModal] = useState(false);
  const [surveyName, setSurveyName] = useState("");
  const [loading, setLoading] = useState(false);
  const { setSnackbar, user, countryData } = useGlobalContext();
  const [requiredFieldModal, setRequiredFieldModal] = useState(false);
  const [cloneSurveyModal, setCloneSurveyModal] = useState(false);
  const [confirmStatusChangeModal, setConfirmStatusChangeModal] = useState({
    open: false,
    status: "",
  });
  const [cloneSurveyData, setCloneSurveyData] = useState({});
  const [emptyFields, setEmptyFields] = useState([]);
  const [filteredSessionOption, setFilteredSessionOption] = useState([]);
  const [reconciliationStats, setReconciliationStats] = useState({});
  const [statusesModal, setStatusesModal] = useState({
    confirmStatusChange: false,
    reconciliationReminder: false,
    noCompletesFound: false,
    partialReconciliationDetected: false,
    confirmBilling: false,
    reconciliationReminderVerify: "",
  });
  const navigate = useNavigate();

  const getSurveyStats = async () => {
    try {
      let reconcileData = await axios.get(
        `${BASE_URL}/survey/reconciliation-stats/${data?._id}`
      );
      setReconciliationStats(reconcileData.data);
    } catch (error) {
      console.log(
        "something went wrong while getting the reconciliation stats",
        error
      );
    }
  };
  //CHANGE SURVEY SURVEY NAME:
  const changeSurveyName = async (name) => {
    try {
      setLoading(true);
      const updated = await axios.put(`${BASE_URL}/survey?id=${data?._id}`, {
        surveyName: name,
      });
      setSnackbar({
        msg: "Survey Name Updated!",
        open: true,
        severity: "success",
      });
      setLoading(false);
      await getSingleSurvey();
    } catch (error) {
      console.error(error, error.message);
      setSnackbar({
        msg: "Something went wrong!",
        open: true,
        severity: "success",
      });
      setLoading(false);
      await getSingleSurvey();
    }
  };

  //CHANGE SURVEY STATUS
  const updateSurveyStatus = async (status) => {
    try {
      const updated = await axios.put(
        `${BASE_URL}/survey/change-survey-status/${data?._id}`,
        {
          newStatus: status,
          oldStatus: data?.surveyStatus,
        }
      );
      setSnackbar({
        msg: "Survey Status Updated!",
        open: true,
        severity: "success",
      });
      setLoading(false);
      // console.log("allSurveyStatus", data?.createdBy, "remove", status, 1, 25);
      await getSingleSurvey();
      queryClient.invalidateQueries(["surveyCounts", data?.createdBy]);

      queryClient.invalidateQueries(["allSurveyStatus"]);
    } catch (error) {
      console.log("Error while updating survey status");
      setSnackbar({
        msg: "Something went wrong!",
        open: true,
        severity: "error",
      });
    }
  };
  const changeSurveyStatus = async (status) => {
    try {
      setLoading(true);
      if (
        status?.toLowerCase() === "completed"
        // &&
        // data?.surveyStatus === "paused"
      ) {
        setStatusesModal((prev) => ({
          ...prev,
          confirmStatusChange: true,
          status,
        }));
        return;
      } else if (
        status?.toLowerCase() === "billed" &&
        // data?.surveyStatus?.toLowerCase() === "completed" &&
        singleSurveyStats?.completed > 0 &&
        reconciliationStats?.approvedReconciliation === 0 &&
        reconciliationStats?.rejectedReconciliation === 0
      ) {
        setStatusesModal((prev) => ({
          ...prev,
          reconciliationReminder: true,
          status,
        }));
      } else if (
        status?.toLowerCase() === "billed" &&
        // data?.surveyStatus?.toLowerCase() === "completed" &&
        singleSurveyStats?.completed === 0 &&
        reconciliationStats?.approvedReconciliation === 0 &&
        reconciliationStats?.rejectedReconciliation === 0
      ) {
        setStatusesModal((prev) => ({
          ...prev,
          noCompletesFound: true,
          status,
        }));
      } else if (
        status?.toLowerCase() === "billed" &&
        // data?.surveyStatus?.toLowerCase() === "completed" &&
        singleSurveyStats?.completed > 0 &&
        reconciliationStats?.pendingReconciliation !== 0
      ) {
        setStatusesModal((prev) => ({
          ...prev,
          partialReconciliationDetected: true,
          status,
        }));
      } else if (
        status?.toLowerCase() === "billed" &&
        // data?.surveyStatus?.toLowerCase() === "completed" &&
        singleSurveyStats?.completed > 0 &&
        reconciliationStats?.pendingReconciliation === 0
      ) {
        setStatusesModal((prev) => ({
          ...prev,
          confirmBilling: true,
          status,
        }));
      } else {
        updateSurveyStatus(status);
      }
    } catch (error) {
      console.error(error, error.message);
      setSnackbar({
        msg: "Something went wrong!",
        open: true,
        severity: "error",
      });
      setLoading(false);
      await getSingleSurvey();
    }
  };
  const approveAllIds = async () => {
    try {
      setStatusesModal((prev) => ({
        ...prev,
        reconciliationReminder: false,
        reconciliationReminderVerify: "",
      }));
      let updatedAll = await axios.post(
        `${BASE_URL}/survey/approve-bulk-reconciliation/${data?._id}`
      );
      updateSurveyStatus("billed");
      setSnackbar({
        msg: "Reconciliation approved successfully!",
        open: true,
        severity: "success",
      });
    } catch (error) {
      console.log("Error while approving all Ids");
      // console.log(error);
      setSnackbar({
        msg: "Something went wrong!",
        open: true,
        severity: "error",
      });
    }
  };

  const checkForEmptyFields = (data) => {
    const emptyFields = [];
    if (!data?.setup?.studyType) emptyFields.push("Study Type");
    if (!data?.setup?.industry) emptyFields.push("Industry");
    if (!data?.setup?.liveUrl) emptyFields.push("Live URL");
    if (!data?.setup?.surveyCpi) emptyFields.push("Survey CPI");
    if (!data?.setup?.country) emptyFields.push("Country");
    if (!data?.setup?.language) emptyFields.push("Language");
    if (!data?.setup?.targetAudience) emptyFields.push("Target Audience");
    if (!data?.expectedMatrix?.expectedCompletes)
      emptyFields.push("Expected Completes");
    if (!data?.expectedMatrix?.expectedCompletionLOI)
      emptyFields.push("Expected Completion LOI");
    if (!data?.expectedMatrix?.expectedIncidenceRate)
      emptyFields.push("Expected Incidence Rate");

    if (
      Array.isArray(data?.peoples?.projectManager)
        ? data?.peoples?.projectManager.length === 0
        : !data?.peoples?.projectManager
    ) {
      emptyFields.push("Project Manager");
    }
    if (!data?.qualification || data?.qualification.length === 0)
      emptyFields.push("Qualifications");
    return emptyFields;
  };

  useEffect(() => {
    setSurveyName(data?.surveyName);
  }, [data?.surveyName]);
  const surveyProtection = (status) => {
    let statuses = [];
    statuses.push({
      label: data.surveyStatus,
      value: data.surveyStatus?.toLowerCase(),
    });
    switch (status) {
      case "awarded":
        statuses.push(
          { label: "Live", value: "live" },
          { label: "Archived", value: "archived" }
        );
        break;
      case "live":
        statuses.push(
          { label: "Paused", value: "paused" },
          { label: "Billed", value: "billed" },
          { label: "Completed", value: "completed" }
        );
        if (singleSurveyStats?.completed == 0) {
          statuses.push({ label: "Archived", value: "archived" });
        }
        break;
      case "paused":
        statuses.push(
          { label: "Billed", value: "billed" },
          { label: "Live", value: "live" },
          { label: "Completed", value: "completed" }
        );
        break;
      case "completed":
        statuses.push({ label: "Billed", value: "billed" });
        break;
      case "archived":
        statuses.push(
          { label: "Live", value: "live" },
          { label: "Awarded", value: "awarded" }
        );
        break;
      default:
        // Handle unexpected status, if necessary
        break;
    }

    setFilteredSessionOption(statuses);
  };

  useEffect(() => {
    if (data?.surveyStatus) {
      surveyProtection(data.surveyStatus?.toLowerCase());
    }
    if (data?._id) {
      getSurveyStats();
    }
  }, [data?.surveyStatus, data?._id]);

  // console.log("singleSurveyStats=>", singleSurveyStats);
  // console.log("data=>", data);
  // console.log("reconciliationStats=>", reconciliationStats);
  // CLONE SURVEY:
  const cloneSurvey = async (e, data) => {
    e.preventDefault();
    try {
      setLoading(true);
      const {
        _id,
        surveyNumber,
        projectNumber,
        createdAt,
        updatedAt,
        surveyStatus,
        ...restData
      } = data;
      // console.log(restData);
      const randomName = generateRandomName();

      await axios
        .post(`${BASE_URL}/survey`, restData)
        .then(async (response) => {
          queryClient.invalidateQueries(["surveyCounts", data?.createdBy]);
          queryClient.invalidateQueries(["allSurveyStatus", data?.createdBy]);
          await axios
            .post(`${BASE_URL}/survey-group`, {
              createdBy: user?.organizationId,
              groupName: randomName,
              isActive: true,
              surveys: [response?.data?._id],
              description: randomName,
            })
            .then(() => {
              setSnackbar((prev) => ({
                open: true,
                msg: "Survey cloned successfully!",
                severity: "success",
              }));
              setLoading(false);
              setCloneSurveyData({});
              setCloneSurveyModal(false);
              navigate("/surveys");
              // window.location.reload();
            });
        });
    } catch (error) {
      setSnackbar((prev) => ({
        ...prev,
        open: true,
        msg: "Something went wrong !",
        severity: "error",
      }));
      setLoading(false);
      setCloneSurveyData({});
      setCloneSurveyModal(false);
    }
  };

  return (
    <div className="flex flex-col w-full gap-[70px] ">
      <div className="w-full flex items-center justify-between ">
        <div className="flex items-center gap-[18px] ">
          <Tooltip title={data?.surveyName}>
            <h1 className="text-[#333] text-[24px] font-[500] max-w-[300px]  text-ellipsis overflow-hidden whitespace-nowrap cursor-pointer">
              {data?.surveyName}
            </h1>
          </Tooltip>
          <div className="flex flex-col gap-[4px] px-[24px] border-l border-[#E0E0E0]">
            <p className="text-[#666] text-[12px] font-[400]">Survey No.</p>
            <h2 className="text-[#333] text-[14px] font-[500]">
              SN#{data?.surveyNumber || "-"}
            </h2>
          </div>

          <div
            className={`flex flex-col gap-[4px] px-[24px] border-l border-[#E0E0E0] ${
              isDetailPage?.toLowerCase()?.includes("details") ? "hidden" : ""
            }`}
          >
            <p className="text-[#666] text-[12px] font-[400]">Client</p>
            <h2 className="text-[#0226BE] text-[14px] font-[500]">
              {user?.organizationName || "-"}
            </h2>
          </div>
        </div>

        <div className={`flex items-center gap-[16px] `}>
          <div
            className={`  capitalize px-[24px] py-2 text-[14px] font-[600] rounded-md   ${
              data?.surveyStatus?.toLowerCase() == "live"
                ? "bg-green-100 text-green-400"
                : data?.surveyStatus?.toLowerCase() == "paused"
                ? "bg-yellow-100 text-yellow-600"
                : data?.surveyStatus?.toLowerCase() == "complete"
                ? "bg-blue-100 text-blue-800"
                : "bg-green-200 text-green-900"
            }`}
          >
            {data?.surveyStatus || "-"}
          </div>
          <div
            className={` space-x-[16px] ${
              isDetailPage?.toLowerCase()?.includes("details") ? "" : "hidden"
            }`}
          >
            <select
              name="status"
              className="border border-[#E0E0E0] rounded-[8px] py-[8px] px-[16px] text-myBlack text-[14px] h-[40px] capitalize"
              value={data?.surveyStatus?.toLowerCase()}
              onChange={(e) => {
                if (e.target.value == "live") {
                  const emptyFields = checkForEmptyFields(data);
                  if (emptyFields.length > 0) {
                    setEmptyFields(emptyFields);
                    setRequiredFieldModal(true);
                  } else {
                    axios
                      .get(`${BASE_URL}/company/${user?.organizationId}`)
                      .then((data) => {
                        // console.log("data=>", data);
                        if (data.data?.authorized) {
                          changeSurveyStatus("live");
                        } else {
                          setIsORGAuthorized(true);
                        }
                      })
                      .catch((er) => {
                        setSnackbar({
                          open: true,
                          msg: "something went wrong !",
                          severity: "error",
                        });
                      });
                  }
                } else {
                  changeSurveyStatus(e.target.value);
                }
              }}
            >
              <option value="" disabled>
                Change status
              </option>
              {filteredSessionOption?.map((status, i) => {
                return (
                  <option value={status?.value} key={i} className="capitalize">
                    {status?.label}
                  </option>
                );
              })}
            </select>

            <select
              name="Action"
              value=""
              className="border border-[#E0E0E0] rounded-[8px] py-[8px] px-[16px] text-myBlack text-[14px] h-[40px]"
              onChange={(e) => {
                if (e.target.value == 2) setExternalNameModal(true);
                if (e.target.value == 1) {
                  setCloneSurveyModal(true);
                  setCloneSurveyData((prev) => ({
                    ...prev,
                    ...data,
                    surveyName: `${data?.surveyName}:Clone`,
                    country: data?.setup?.country,
                  }));
                }
              }}
            >
              <option value="">Actions</option>
              <option value={1}>Clone Survey</option>
              <option value={2}>Set External Name</option>
            </select>
          </div>
        </div>
      </div>

      {/* External Name Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={externalNameModal}
        onClose={(e) => {
          setExternalNameModal(false);
          setLoading(false);
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={externalNameModal}>
          <Box sx={style}>
            <div className="w-full gap-[30px] flex items-center flex-col">
              <div className="w-full py-6 border-b border-[#E0E0E0]  text-center relative">
                <h1 className="text-[#333333] text-[20px] font-[600]">
                  Set External Project Name
                </h1>
                <p
                  className=" bg-[#F1F1F1] py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={(e) => {
                    setExternalNameModal(false);
                    setLoading(false);
                  }}
                >
                  <RxCross2 size={16} />
                </p>
              </div>
              <div className="flex flex-col py-[15px] gap-[20px]">
                <input
                  type="text"
                  className="border p-[2px] pl-[10px]  border-[#cbcbcb] text-[#686767]  rounded-[5px] h-[40px] w-[330px]"
                  value={surveyName}
                  onChange={(e) => {
                    setSurveyName(e.target.value);
                  }}
                />
                {loading ? (
                  <div className="flex justify-center gap-[20px]">
                    <CircularProgress className="text-center" size={23} />
                  </div>
                ) : (
                  <div className="flex justify-center gap-[20px]">
                    <button
                      className="px-[20px] py-[6px] text-[#fff] rounded-[4px]  bg-[#0226BE] text-[16px] font-[400]"
                      onClick={(e) => {
                        changeSurveyName(surveyName);
                        setExternalNameModal(false);
                      }}
                    >
                      Change
                    </button>
                    <button
                      className="px-[20px] py-[6px] text-[black] rounded-[4px] border  border-[black] text-[16px] font-[400]"
                      onClick={() => {
                        setExternalNameModal(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* CLONE SURVEY MODAL */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={cloneSurveyModal}
        onClose={(e) => {
          setCloneSurveyModal(false);
          setLoading(false);
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={cloneSurveyModal}>
          <Box sx={style}>
            <div className="w-full space-y-6 flex items-center flex-col">
              <div className="w-full  py-6 border-b border-[#E0E0E0]  text-center relative">
                <h1 className="text-[#333333] text-[20px] font-[600]">
                  Clone Survey
                </h1>
                <p
                  className=" bg-[#F1F1F1] py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={(e) => {
                    setCloneSurveyModal(false);
                    setLoading(false);
                  }}
                >
                  <RxCross2 size={16} />
                </p>
              </div>
              <form
                onSubmit={(e) => cloneSurvey(e, cloneSurveyData)}
                className="flex flex-col px-20 pb-10 gap-[20px]"
              >
                <div className="space-y-2">
                  <label htmlFor="surveyName">Survey Name</label>
                  <input
                    type="text"
                    className="border p-[2px] pl-[10px]  border-[#cbcbcb] text-[#686767]  rounded-[5px] h-[40px] w-[330px]"
                    value={cloneSurveyData?.surveyName}
                    onChange={(e) => {
                      setCloneSurveyData((prev) => ({
                        ...prev,
                        surveyName: e.target.value,
                      }));
                    }}
                  />
                </div>

                <div className="space-y-2">
                  <label htmlFor="country">Country</label>
                  <select
                    className="w-full border border-[#E0E0E0]  rounded-[4px] pl-3 text-myBlack font-[400] text-[14px] h-[40px]"
                    name="country"
                    value={cloneSurveyData?.setup?.countryCode}
                    onChange={(e) => {
                      setCloneSurveyData((prev) => ({
                        ...prev,
                        setup: {
                          ...prev?.setup,
                          country:
                            e.target.options[e.target.selectedIndex].text,
                          countryCode: e.target.value,
                        },
                      }));
                    }}
                  >
                    <option value="">Select Country </option>
                    {countryData?.isLoading ? (
                      <option value="" disabled>
                        Loading Countries...
                      </option>
                    ) : countryData?.error ? (
                      <option value="" disabled>
                        Error while fetching countries try refreshing the page
                      </option>
                    ) : (
                      countryData?.data?.map((data, key) => (
                        <option value={data?.iso2} key={key}>
                          {data?.name}
                        </option>
                      ))
                    )}
                  </select>
                </div>

                {loading ? (
                  <div className="flex justify-center gap-[20px]">
                    <CircularProgress className="text-center" size={23} />
                  </div>
                ) : (
                  <div className="flex justify-center gap-[20px]">
                    <button
                      className="px-[20px] py-[6px] text-[#fff] rounded-[4px]  bg-[#0226BE] text-[16px] font-[400]"
                      type="submit"
                    >
                      Clone
                    </button>
                    <button
                      type="button"
                      className="px-[20px] py-[6px] text-[black] rounded-[4px] border  border-[black] text-[16px] font-[400]"
                      onClick={() => {
                        setCloneSurveyModal(false);
                        setCloneSurveyData({});
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* EMPTY FIELD MODAL */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={requiredFieldModal}
        onClose={(e) => {
          setRequiredFieldModal(false);
          setEmptyFields([]);
          setLoading(false);
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={requiredFieldModal}>
          <Box sx={style}>
            <div className="w-full flex px-4 py-4 flex-col">
              <div className="w-full py-2  text-center relative">
                <p
                  className=" bg-[#F1F1F1] py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={(e) => {
                    setRequiredFieldModal(false);
                    setEmptyFields([]);
                    setLoading(false);
                  }}
                >
                  <RxCross2 size={16} />
                </p>
              </div>

              <div className="flex flex-col gap-[10px] space-y-2">
                <p className="font-[600] w-[78%]">
                  Please add all the details mentioned below to make survey
                  live!
                </p>
                <div className="space-y-2">
                  {emptyFields?.map((d, key) => {
                    return (
                      <div className=" flex items-center gap-2" key={key}>
                        <div className="w-[10px] h-[10px] bg-red-800 rounded-full"></div>
                        <span>{d}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* Organization is not verified modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isORGAuthorized}
        onClose={(e) => {
          setIsORGAuthorized(false);
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isORGAuthorized}>
          <Box sx={style}>
            <div className="w-full flex px-5 py-6 flex-col gap-[10px]">
              <p className="text-[14px]">
                We apologize for any inconvenience caused. Unfortunately, we are
                unable to make this project live at this time because the due
                diligence for your organization is still pending. We are
                actively working on this and will send you an email to your
                registered email address once the process is complete.
              </p>
              <p className="text-[14px]">
                If you do not receive an email from us, please contact us at{" "}
                <a
                  href="mailto:diysupport@miratsinsights.com"
                  className="text-blue-400"
                >
                  diysupport@miratsinsights.com
                </a>{" "}
                using your registered email address for further communication.
              </p>
              <p className="text-[14px] font-[500] text-center">
                Thank you for your understanding and patience.
              </p>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* status change confirmation modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={confirmStatusChangeModal?.open}
        onClose={(e) => {
          setConfirmStatusChangeModal((prev) => ({ ...prev, open: false }));
          setLoading(false);
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={confirmStatusChangeModal?.open}>
          <Box sx={style}>
            <div className="w-full flex px-4 py-4 flex-col gap-[20px]">
              <h1 className="text-lg text-[#333]">
                Are you sure you want to mark this survey as{" "}
                <span className="capitalize text-primary font-[600]">
                  {confirmStatusChangeModal?.status}
                </span>{" "}
                ?
              </h1>

              <div className="flex items-center justify-end gap-[20px]">
                <button
                  className="px-3 py-1 rounded-md hover:bg-gray-100 transition-all"
                  onClick={(e) =>
                    setConfirmStatusChangeModal({ open: false, status: "" })
                  }
                >
                  Cancel
                </button>
                <button
                  className="bg-primary text-[#fff] px-3 py-1 text-[14px] rounded-md"
                  onClick={(e) => {
                    changeSurveyStatus(confirmStatusChangeModal?.status);
                    setConfirmStatusChangeModal({ open: false, status: "" });
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* confirm status change */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={statusesModal?.confirmStatusChange}
        onClose={(e) => {
          setStatusesModal((prev) => ({
            ...prev,
            confirmStatusChange: !false,
          }));
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={statusesModal?.confirmStatusChange}>
          <Box sx={style}>
            <div className="w-full flex px-4 py-4 flex-col gap-[20px]">
              <div className="space-y-2">
                <h1 className="text-lg text-[#333] font-[600]">
                  Confirm Status Change
                </h1>
                <p className="text-[14px] text-[#666]">
                  You are about to change the survey status to Complete. This
                  action is irreversible, and you will no longer be able to
                  change the status back to Live or Paused. <br /> Please
                  confirm to proceed.
                </p>
              </div>
              <div className="flex items-center justify-end gap-[20px]">
                <button
                  className="px-3 py-2 rounded-md hover:bg-gray-100 transition-all"
                  onClick={(e) =>
                    setStatusesModal((prev) => ({
                      ...prev,
                      confirmStatusChange: false,
                    }))
                  }
                >
                  Cancel
                </button>
                <button
                  className="bg-primary text-[#fff] px-3 py-2 text-[14px] rounded-md"
                  onClick={(e) => {
                    updateSurveyStatus(statusesModal?.status);
                    setStatusesModal((prev) => ({
                      ...prev,
                      confirmStatusChange: false,
                    }));
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/*reconciliationReminder  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={statusesModal?.reconciliationReminder}
        onClose={(e) => {
          setStatusesModal((prev) => ({
            ...prev,
            reconciliationReminder: false,
          }));
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={statusesModal?.reconciliationReminder}>
          <Box sx={style}>
            <div className="w-full flex px-4 py-4 flex-col gap-[20px]">
              <div className="space-y-2">
                <h1 className="text-lg text-[#333] font-[600]">
                  Reconciliation Reminder
                </h1>
                <p className="text-[14px] text-[#666]">
                  You are changing the survey status to Billed. Please note, you
                  have not reconciled the survey, meaning you have not uploaded
                  the correct and rejected IDs for this survey.
                  <br />
                  If you wish to approve all sessions, please type “bill my
                  project” in the field below and then submit. Otherwise, please
                  go to the reconciliation page and complete the process.
                </p>
                <input
                  type="text"
                  placeholder='Type "bill my project"'
                  className="border  w-full py-1 px-3 rounded-md text-[14px]"
                  onChange={(e) => {
                    setStatusesModal((prev) => ({
                      ...prev,
                      reconciliationReminderVerify: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="flex items-center justify-end gap-[20px]">
                <button
                  className="px-3 py-2 rounded-md hover:bg-gray-100 transition-all"
                  onClick={(e) =>
                    setStatusesModal((prev) => ({
                      ...prev,
                      reconciliationReminder: false,
                      reconciliationReminderVerify: "",
                    }))
                  }
                >
                  Cancel
                </button>
                <button
                  type="button"
                  disabled={
                    statusesModal?.reconciliationReminderVerify
                      ?.toLowerCase()
                      ?.trim() !== "bill my project"
                  }
                  className={`${
                    statusesModal?.reconciliationReminderVerify
                      ?.toLowerCase()
                      ?.trim() === "bill my project"
                      ? "bg-primary cursor-pointer"
                      : "bg-primary/30 cursor-not-allowed"
                  }  text-[#fff] px-3 py-2 text-[14px] rounded-md`}
                  onClick={approveAllIds}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* noCompletesFound */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={statusesModal?.noCompletesFound}
        onClose={(e) => {
          setStatusesModal((prev) => ({
            ...prev,
            noCompletesFound: false,
          }));
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={statusesModal?.noCompletesFound}>
          <Box sx={style}>
            <div className="w-full flex px-4 py-4 flex-col gap-[20px]">
              <div className="space-y-2">
                <h1 className="text-lg text-[#333] font-[600]">
                  No Completes Found
                </h1>
                <p className="text-[14px] text-[#666]">
                  This survey has no completes recorded in our system. Please
                  upload your approved IDs on the reconciliation page.
                </p>
              </div>
              <div className="flex items-center justify-end gap-[20px]">
                <button
                  className="px-3 py-2 rounded-md hover:bg-gray-100 transition-all"
                  onClick={(e) =>
                    setStatusesModal((prev) => ({
                      ...prev,
                      noCompletesFound: false,
                    }))
                  }
                >
                  Cancel
                </button>
                <button
                  className="bg-primary text-[#fff] px-3 py-2 text-[14px] rounded-md"
                  onClick={(e) => {
                    setStatusesModal((prev) => ({
                      ...prev,
                      noCompletesFound: false,
                    }));
                    navigate(`/surveys/${data?._id}/reconciliation`);
                  }}
                >
                  Take me to Reconciliation
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* partialReconciliationDetected */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={statusesModal?.partialReconciliationDetected}
        onClose={(e) => {
          setStatusesModal((prev) => ({
            ...prev,
            partialReconciliationDetected: false,
          }));
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={statusesModal?.partialReconciliationDetected}>
          <Box sx={style}>
            <div className="w-full flex px-4 py-4 flex-col gap-[20px]">
              <div className="space-y-2">
                <h1 className="text-lg text-[#333] font-[600]">
                  Partial Reconciliation Detected
                </h1>
                <p className="text-[14px] text-[#666]">
                  It seems you have not reconciled all the IDs for this survey;
                  some are missing. If you want to auto-approve the remaining
                  pending sessions, hit the primary action button. Otherwise,
                  upload the remaining IDs using the secondary action button.
                </p>
              </div>
              <div className="flex items-center justify-end gap-[20px]">
                <button
                  className="px-3 py-2 text-[14px] font-[600] rounded-md hover:bg-gray-100 transition-all"
                  onClick={(e) => {
                    setStatusesModal((prev) => ({
                      ...prev,
                      partialReconciliationDetected: false,
                    }));
                    navigate(`/surveys/${data?._id}/reconciliation`);
                  }}
                >
                  Upload Remaining IDs
                </button>
                <button
                  className="bg-primary text-[#fff] px-3 py-2 text-[14px] rounded-md"
                  onClick={approveAllIds}
                >
                  Bill my project
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* confirmBilling */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={statusesModal?.confirmBilling}
        onClose={(e) => {
          setStatusesModal((prev) => ({
            ...prev,
            confirmBilling: false,
          }));
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={statusesModal?.confirmBilling}>
          <Box sx={style}>
            <div className="w-full flex px-4 py-4 flex-col gap-[20px]">
              <div className="space-y-2">
                <h1 className="text-lg text-[#333] font-[600]">
                  Confirm Billing
                </h1>
                <p className="text-[14px] text-[#666]">
                  You are about to bill this project. We just need a final
                  confirmation from you to proceed.
                </p>
              </div>
              <div className="flex items-center justify-end gap-[20px]">
                <button
                  className="px-3 py-2 rounded-md hover:bg-gray-100 transition-all"
                  onClick={(e) =>
                    setStatusesModal((prev) => ({
                      ...prev,
                      confirmBilling: false,
                    }))
                  }
                >
                  Cancel
                </button>
                <button
                  className="bg-primary text-[#fff] px-3 py-2 text-[14px] rounded-md"
                  onClick={(e) => {
                    updateSurveyStatus(statusesModal?.status);
                    setStatusesModal((prev) => ({
                      ...prev,
                      confirmBilling: false,
                    }));
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default DetailHeader;
