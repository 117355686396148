import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { utils, writeFile } from "xlsx";
import { useGlobalContext } from "../../../../Contexts/GlobalContext";
import { BASE_URL, CustomTablePagination } from "../../../../Utlils";
import NoData from "../../../../components/NoData";
import TableLoader from "./TableLoader";
import { getStatsDetail } from "./helperData";
import { useQuery } from "@tanstack/react-query";

const TerminationData = ({ data }) => {
  const { id } = useParams();
  const { setSnackbar } = useGlobalContext();
  const [filters, setFilters] = useState({
    startDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 3,
      new Date().getDate()
    )?.toLocaleDateString("en-CA"),

    endDate: new Date().toLocaleDateString("en-CA"),
  });
  const [tempFilters, setTempFilters] = useState(filters);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const {
    data: terminatedSessionsData,
    isLoading: terminatedSessionsDataLoading,
    isError: terminatedSessionsDataError,
  } = useQuery({
    queryFn: () => getTerminatedSessions(),
    queryKey: [
      `${id}-terminatedSessions-${filters?.startDate}-${filters?.endDate}-${page}-${rowsPerPage}`,
    ],
    staleTime: 10000,
    cacheTime: 15000,
  });
  //DOWNLOAD TO EXCEL:
  const DownloadToExcel = () => {
    var elt = document.getElementById("table-to-xls-DATA");
    var wb = utils.table_to_book(elt, { sheet: "MiratsQuanto" });
    const worksheet = wb.Sheets[wb.SheetNames[0]];
    const jsonData = utils.sheet_to_json(worksheet);
    const fieldsToRemove = ["RID", "Supplier Account", "SRC ID"];
    const filteredData = jsonData.map((row) => {
      fieldsToRemove.forEach((field) => delete row[field]);
      return row;
    });
    const newWorksheet = utils.json_to_sheet(filteredData);
    const newWorkbook = utils.book_new();
    utils.book_append_sheet(newWorkbook, newWorksheet, "MiratsQuanto");
    writeFile(
      newWorkbook,
      `Mirats Quanto - SN#${data?.surveyNumber} - Termination Data.xlsx`
    );
    setSnackbar({
      open: true,
      severity: "success",
      msg: "Excel Sheet Downloaded!",
    });
  };

  //GET TERMINATED SESSIONS:
  const getTerminatedSessions = async () => {
    try {
      const sessionData = await axios.get(
        `${BASE_URL}/session/terminated-sessions?surveyID=${id}&startDate=${
          filters.startDate
        }&endDate=${filters.endDate}&page=${
          Number(page) + 1
        }&rows=${rowsPerPage}`
      );

      return sessionData?.data;
    } catch (error) {
      return error;
    }
  };
  const refetchSurveyTerminatedSessionsData = (start, end) => {
    setFilters({ startDate: start, endDate: end });
  };
  // pagination function
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // console.log(data);
  // console.log({
  //   terminatedSessionsData,
  //   terminatedSessionsDataLoading,
  //   terminatedSessionsDataError,
  // });
  return (
    <div className="w-full flex flex-col gap-[30px] py-6">
      <div className="w-full flex items-center justify-between ">
        <div className="flex flex-col gap-[26px]">
          <h1 className="text-[#000] text-[18px] font-[500] inter">
            Field Date
          </h1>
          <div className="flex items-center gap-[28px]">
            <input
              type="date"
              className="border h-[40px] px-4 rounded-[8px] text-[#666] text-[14px]"
              value={tempFilters?.startDate}
              onChange={(e) => {
                setTempFilters((prev) => ({
                  ...prev,
                  startDate: e.target.value,
                }));
              }}
            />
            <p className="text-[#333] text-[16px] font-[400] inter">To</p>
            <input
              type="date"
              className="border h-[40px] px-4 rounded-[8px] text-[#666] text-[14px]"
              value={tempFilters?.endDate}
              onChange={(e) => {
                setTempFilters((prev) => ({
                  ...prev,
                  endDate: e.target.value,
                }));
              }}
            />
            <button
              className="border border-[#0226BE] text-[#0226BE] py-2 px-3 text-[13.3px] inter font-[500] rounded-[8px]  cursor-pointer"
              onClick={(e) => setFilters(tempFilters)}
            >
              Search
            </button>
            <p
              className="text-[14px] underline underline-offset-4 cursor-pointer"
              onClick={(e) => {
                const start = new Date(
                  new Date().getFullYear(),
                  new Date().getMonth() - 3,
                  new Date().getDate()
                )?.toLocaleDateString("en-CA");
                const end = new Date().toLocaleDateString("en-CA");

                if (
                  tempFilters?.startDate !== start ||
                  tempFilters?.endDate !== end
                ) {
                  setTempFilters({
                    startDate: start,
                    endDate: end,
                  });
                  refetchSurveyTerminatedSessionsData(start, end);
                }
              }}
            >
              Clear filters
            </p>
          </div>
        </div>
        <button
          className="bg-[#0226BE] text-[#fff] py-2 px-3 text-[13.3px] inter font-[500] rounded-[8px] btnShadow"
          onClick={(e) => DownloadToExcel()}
        >
          Export To Excel
        </button>
      </div>
      {/* table */}
      <div>
        <div className="w-full tableBorder flex flex-col">
          {terminatedSessionsDataLoading ? (
            <TableLoader />
          ) : terminatedSessionsDataError ? (
            <div className="w-full  h-[200px] flex items-center justify-center">
              <h1 className="text-[#666666]">Something went wrong </h1>
            </div>
          ) : (
            <div className="w-full overflow-x-auto containerScroll">
              <table
                className="whitespace-nowrap text-left w-full"
                id="table-to-xls-DATA"
              >
                <thead className=" h-[45px] ">
                  <tr className="py-3 ">
                    {[
                      "Ref ID",
                      "Survey No.",
                      "Project No.",
                      "Mirats Status",
                      "Client Status",
                      "Date",
                    ]?.map((d) => (
                      <td className="text-[#666] text-[14px] font-[600] pl-3  text-start">
                        {d}
                      </td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {terminatedSessionsData?.data?.length ? (
                    terminatedSessionsData?.data?.map((da) => (
                      <tr className=" tableBorder transition-all cursor-pointer hover:bg-[#f6f9ff] ">
                        <td className="pl-3 py-6 text-[#667085] font-[400] text-[12px] text-start">
                          {da?.ref_id}
                        </td>
                        <td className="pl-3 py-6 text-[#667085] font-[400] text-[12px] text-start">
                          {data?.surveyNumber}
                        </td>
                        <td className="pl-3 py-6 text-[#667085] font-[400] text-[12px] text-start">
                          {data?.projectNumber}
                        </td>

                        <td className="pl-3 py-6 text-[#667085] font-[400] text-[12px] text-start">
                          {getStatsDetail("mirats", da?.mirats_status)} (
                          {da?.mirats_status})
                        </td>
                        <td className="pl-3 py-6 text-[#667085] font-[400] text-[12px] text-start">
                          {getStatsDetail("client", da?.client_status)} (
                          {da?.client_status})
                        </td>
                        <td className="pl-3 py-6 text-[#667085] font-[400] text-[12px] text-start">
                          {new Date(da?.date)?.toLocaleDateString("en-UK", {
                            day: "2-digit",
                            month: "short",
                            year: "numeric",
                          })}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td colSpan={12}>
                      <div className="py-10">
                        <NoData
                          msg={
                            <div className="flex items-center gap-[10px]">
                              <h1>Try Changing the filters</h1>
                              {/* <button
                    className="py-1 px-4 bg-primary text-[#fff] rounded-md font-[500]"
                    onClick={(e) => console.log("hehe")}
                  >
                    Clear
                  </button> */}
                            </div>
                          }
                        />
                      </div>
                    </td>
                  )}
                </tbody>
              </table>
            </div>
          )}
          <CustomTablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            sx={{ fontSize: "10px" }}
            count={terminatedSessionsData?.pagination?.totalRecords || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            componentsProps={{
              select: {
                "aria-label": "rows per page",
              },
              actions: {
                showFirstButton: true,
                showLastButton: true,
              },
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default TerminationData;
