import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import TotalSurveyIcon from "../../assets/icons/TotalSurveyIcon.svg";
import TotalLiveSurveyIcon from "../../assets/icons/TotalLiveSurvey.svg";
import TotalPendingSurvey from "../../assets/icons/TotalPendingSurvey.svg";
import TotalCompleatSurvey from "../../assets/icons/TotalCompleateSurvey.svg";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineModeEditOutline, MdDownload } from "react-icons/md";
import { IoMdArchive } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { CustomTablePagination, timeDifference } from "../../Utlils";
import NoData from "../../components/NoData";
import { utils, writeFile } from "xlsx";
import DeleteModal from "../../components/DeleteModal";
import { CircularProgress } from "@mui/material";
import Required from "../../components/Required";
import { getValueByCountryRateAndLOI } from "./surveyDetails/components/helperData";
import SurveyTableLoader from "./surveyDetails/components/SurveyTableLoader";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addNewSurvey,
  archiveSurvey,
  editSurveyAction,
  getAllSurveyStats,
  getSurveyCount,
} from "./surveyActions";
import { calculateValue } from "../Feasibility/CalcHelper";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 530,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
};

let dummyfilterData = [
  "All",
  "Live",
  "Awarded",
  "Paused",
  "Completed",
  "billed",
  "Archived",
];

const Survey = () => {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(
    localStorage.getItem("survey-tab") ? localStorage.getItem("survey-tab") : 1
  );
  const {
    teamMembers,
    countryData,
    user,
    setSnackbar,
    allSurveyGroup,
    getAllSurveyGroup,
    industryData,
    studyTypesData,
    organization,
  } = useGlobalContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filters, setFilters] = useState({});
  const [debouncedSurveySearchText, setDebouncedSurveySearchText] = useState(
    filters?.search || ""
  );
  // fetch surveyStats
  const {
    data: allSurveyStats,
    isLoading: allSurveyStatsLoading,
    isError: allSurveyStatsError,
    refetch,
  } = useQuery({
    queryKey: [
      "allSurveyStatus",
      organization?.id,
      undefined,
      tab,
      page,
      rowsPerPage,
      filters?.industry,
      filters?.projectManager,
      filters?.studyType,
      filters?.country,
      debouncedSurveySearchText,
    ],
    queryFn: () =>
      getAllSurveyStats(
        organization?.id,
        undefined,
        dummyfilterData?.[tab]?.toLowerCase(),
        page,
        rowsPerPage,
        filters?.industry,
        filters?.projectManager,
        filters?.studyType,
        filters?.country,
        debouncedSurveySearchText
      ),
    staleTime: 10000,
    cacheTime: 15000,
  });
  const { mutateAsync: refetchSurveys } = useMutation({
    mutationFn: () =>
      getAllSurveyStats(
        organization?.id,
        "remove",
        dummyfilterData?.[tab]?.toLowerCase(),
        page,
        rowsPerPage
      ),
    onSuccess: () => {
      queryClient.invalidateQueries([
        "allSurveyStatus",
        organization?.id,
        "remove",
        dummyfilterData?.[tab]?.toLowerCase(),
        page,
        rowsPerPage,
      ]);
    },
  });
  const handleSurveyStatsRefresh = () => {
    refetchSurveys();
  };
  // fetch survey statuses count
  const {
    data: surveyStatusesCount,
    isLoading: surveyStatusesCountLoading,
    isError: surveyStatusesCountError,
  } = useQuery({
    queryFn: () => getSurveyCount(organization?.id),
    queryKey: ["surveyCounts", organization?.id],
    staleTime: 10000,
    cacheTime: 15000,
  });
  // mutate the survey statuses count when survey gets archived
  const { mutateAsync: mutateSurveyCounts } = useMutation({
    mutationFn: archiveSurvey,
    onSuccess: () => {
      queryClient.invalidateQueries(["surveyCounts", organization?.id]);
    },
  });
  // mutate after creating new survey
  const { mutateAsync: addSurveyMutation } = useMutation({
    mutationFn: ({ inputData, user }) => addNewSurvey(inputData, user),
    onSuccess: () => {
      queryClient.invalidateQueries(["surveyCounts", organization?.id]);
    },
  });

  const [selectRows, setSelectRows] = useState([]);

  const [open, setOpen] = useState(false);
  const [surveyTypeModal, setSurveyTypeModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const handleOpen = () => setOpen(true);
  const [inputData, setInputData] = useState({
    edit: false,
    setup: {
      targetAudience: "B2C",
    },
  });

  // get all survey stats

  // Archive SURVEYS:
  const deleteSurveys = async (e) => {
    e.preventDefault();
    try {
      setTab(6);
      await mutateSurveyCounts(selectRows);
      handleSurveyStatsRefresh();
      setSelectRows([]);
      setSnackbar((prev) => ({
        open: true,
        msg: "Survey Archived Successfully!",
        severity: "success",
      }));
    } catch (error) {
      setSnackbar((prev) => ({
        open: true,
        msg: "Something went wrong!",
        severity: "error",
      }));
    } finally {
      setDeleteModal(false);
    }
  };

  const handleCloseModal = (e) => setDeleteModal(false);

  // HANDLE CHANGE:
  const handleChange = (e) => {
    let { name, value } = e.target;
    setInputData((prev) => ({ ...prev, [name]: value }));
  };
  //ADD SURVEY & SURVEY GROUP:
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await addSurveyMutation({ inputData, user });
      handleSurveyStatsRefresh();
      queryClient.invalidateQueries([
        "allSurveyStatus",
        organization?.id,
        "remove",
        "awarded",
        page,
        rowsPerPage,
      ]);
      await getAllSurveyGroup();
      setInputData({
        edit: false,
        setup: {
          targetAudience: "B2C",
        },
      });
      setSnackbar((prev) => ({
        open: true,
        msg: "Survey Created Successfully !",
        severity: "success",
      }));
    } catch (error) {
      setSnackbar((prev) => ({
        open: true,
        msg: "Something went wrong!",
        severity: "error",
      }));
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };
  // console.log(name);

  //EDIT SURVEY & SURVEY GROUP:
  const editSurvey = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      await editSurveyAction(inputData);
      handleSurveyStatsRefresh();
      setSnackbar((prev) => ({
        open: true,
        msg: "Survey Details Updated Successfully !",
        severity: "success",
      }));
    } catch (error) {
      setSnackbar((prev) => ({
        open: true,
        msg: "Something went wrong !",
        severity: "error",
      }));
      // console.log(error);
    } finally {
      setSelectRows([]);
      setOpen(false);
      setLoading(false);
      setInputData({
        edit: false,
        setup: {
          targetAudience: "B2C",
        },
      });
    }
  };

  //HANDLE FILTER CHANGE DATA:
  const handelFilterChange = (e) => {
    let { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  //GENERATE EXCEL FILE:
  const [xlData, setXlData] = useState([]);
  const generateExcelFile = (orgData) => {
    let generateData = [];
    orgData?.forEach((data) => {
      const surveyStats = allSurveyStats?.data?.find(
        (stats) => stats?.survey?._id == data?._id
      );
      generateData.push({
        "Survey Name": data?.surveyName,
        "Project Number": data?.projectNumber,
        "Survey Number": data?.surveyNumber,
        "Progress/completes": `${surveyStats?.completes}/${data?.expectedMatrix?.expectedCompletes}`,
        "Avg. LOI": `${
          surveyStats?.avgLoi ? surveyStats?.avgLoi + "min" : "0 min"
        }`,
        "Conversation Rate": `${
          surveyStats?.conversion ? surveyStats?.conversion + "%" : " 0 %"
        } `,
        "Incidence Rate": `${surveyStats?.IR ? `${surveyStats?.IR} %` : `0 %`}`,
        "Avg. CPI": `${surveyStats?.avgCPI || 0}`,
        "Drop-off": `${
          surveyStats?.dropRate ? `${surveyStats?.dropRate} %` : `0 `
        }`,
        EPC: `${Number(surveyStats?.epc) || 0}`,
      });
    });
    setXlData(generateData);
  };
  useEffect(() => {
    const handler = setTimeout(() => {
      if (page != 0) {
        setPage(0);
      }
      setDebouncedSurveySearchText(filters?.search);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filters?.search]);
  useEffect(() => {
    setInputData((prev) => ({
      ...prev,
      peoples: { projectManager: [user?._id] },
    }));
    if (selectRows?.length) {
      generateExcelFile(selectRows);
    } else {
      generateExcelFile(allSurveyStats?.data?.map((d) => d?.survey));
    }
  }, [allSurveyStats?.data?.length, selectRows?.length]);
  useEffect(() => {
    if (
      inputData?.expectedMatrix?.expectedCompletionLOI &&
      inputData?.expectedMatrix?.expectedIncidenceRate &&
      inputData?.setup?.country &&
      inputData?.setup?.targetAudience
    ) {
      setInputData((prev) => ({
        ...prev,
        setup: {
          ...prev.setup,
          surveyCpi: getValueByCountryRateAndLOI(
            inputData?.setup?.country,
            inputData?.expectedMatrix?.expectedIncidenceRate,
            inputData?.expectedMatrix?.expectedCompletionLOI,
            inputData?.setup?.targetAudience
          ),
          // surveyCpi: calculateValue(
          //   inputData?.setup?.country,
          //   inputData?.expectedMatrix?.expectedCompletionLOI,
          //   inputData?.expectedMatrix?.expectedIncidenceRate,
          //   inputData?.setup?.targetAudience == "B2C" ? 1 : 2
          // ),
        },
      }));
    }
  }, [
    inputData?.expectedMatrix?.expectedCompletionLOI,
    inputData?.expectedMatrix?.expectedIncidenceRate,
    inputData?.setup?.country,
  ]);
  //DOWNLOAD TO EXCEL DATA:
  const DownloadToExcel = () => {
    var wb = utils.book_new(),
      ws = utils.json_to_sheet(xlData);
    utils.book_append_sheet(wb, ws, "Sheet1");
    setSelectRows([]);
    writeFile(wb, `Survey-Data.xlsx`);
    setSnackbar({
      open: true,
      severity: "success",
      msg: "Excel Sheet Downloaded!",
    });
  };

  // pagination function
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // console.log("inputData=>", inputData);
  // console.log("user=>", user);
  // console.log("allSurveyStats=>", allSurveyStats);
  // console.log("surveyStatusesCount=>", surveyStatusesCount);
  // console.log("filters=>", filters);
  // console.log("selectRows=>", selectRows);
  return (
    <div className="w-full flex flex-col gap-[30px] py-6">
      <div className="w-full flex items-center justify-between ">
        <h1 className="text-[#333] text-[32px] font-[500] inter">Surveys</h1>
        {/* <button
          className="bg-[#0226BE] text-[#fff] py-2 px-3 text-[13.3px] inter font-[500] rounded-[8px]"
          onClick={handleOpen}
        >
          Create Target Screening
        </button> */}
        <button
          className="bg-[#0226BE] text-[#fff] py-2 px-3 text-[13.3px] inter font-[500] rounded-[8px]"
          onClick={(e) => setSurveyTypeModal(true)}
        >
          Create Survey
        </button>
      </div>
      {/* survey card */}
      <div className="w-full grid grid-cols-5 gap-10">
        <div className="surveyCardShadow rounded-[10px] bg-[#fff]  py-4 px-5 flex flex-col gap-2">
          <img src={TotalSurveyIcon} alt="" className="w-[26px] h-[27px]" />
          {/* inconvenience */}
          <h3 className="text-[#000] inter font-[600] text-[18px]">
            {surveyStatusesCount?.all || 0}
          </h3>
          <p className="text-[#000A12] text-[14px] font-[500] inter">
            Total Surveys
          </p>
        </div>
        <div className="surveyCardShadow rounded-[10px] bg-[#fff]  py-4 px-5 flex flex-col gap-2">
          <img src={TotalLiveSurveyIcon} alt="" className="w-[26px] h-[27px]" />
          <h3 className="text-[#000] inter font-[600] text-[18px]">
            {" "}
            {surveyStatusesCount?.live || 0}
          </h3>
          <p className="text-[#000A12] text-[14px] font-[500] inter">
            Total Live Survey
          </p>
        </div>
        <div className="surveyCardShadow rounded-[10px] bg-[#fff]  py-4 px-5 flex flex-col gap-2">
          <img src={TotalCompleatSurvey} alt="" className="w-[26px] h-[27px]" />
          <h3 className="text-[#000] inter font-[600] text-[18px]">
            {" "}
            {surveyStatusesCount?.completed || 0}
          </h3>
          <p className="text-[#000A12] text-[14px] font-[500] inter">
            Total Completed Surveys
          </p>
        </div>
        <div className="surveyCardShadow rounded-[10px] bg-[#fff]  py-4 px-5 flex flex-col gap-2">
          <img src={TotalPendingSurvey} alt="" className="w-[26px] h-[27px]" />
          <h3 className="text-[#000] inter font-[600] text-[18px]">
            {surveyStatusesCount?.paused || 0}
          </h3>
          <p className="text-[#000A12] text-[14px] font-[500] inter">
            Total Paused Surveys
          </p>
        </div>
      </div>
      {/* filters row */}
      <div className="flex items-center justify-between py-3">
        <div className="flex items-center gap-5">
          <select
            className="border border-[#E0E0E0] py-2 px-4 rounded-[8px] text-[#666] text-[14px] focus:outline-none w-[170px]"
            onChange={handelFilterChange}
            value={filters?.industry || ""}
            name="industry"
          >
            <option value="" selected>
              Industry Type
            </option>
            {industryData?.map((data, key) => (
              <option key={key} value={data?.value}>
                {data?.label}
              </option>
            ))}
          </select>
          <select
            className="border border-[#E0E0E0] py-2 px-4 rounded-[8px] text-[#666] text-[14px] focus:outline-none w-[170px]"
            onChange={handelFilterChange}
            value={filters?.projectManager || ""}
            name="projectManager"
          >
            <option value="" selected>
              Project Manager
            </option>
            {teamMembers?.map((data, key) => (
              <option value={data?._id} key={key}>
                {data?.personalDetails?.fullName}
              </option>
            ))}
          </select>
          <select
            className="border border-[#E0E0E0] py-2 px-4 rounded-[8px] text-[#666] text-[14px] focus:outline-none w-[170px]"
            onChange={handelFilterChange}
            name="studyType"
            value={filters?.studyType || ""}
          >
            <option value="" selected>
              Study Type
            </option>
            {studyTypesData?.map((data, ind) => (
              <option value={data?.value} key={ind}>
                {data?.label}
              </option>
            ))}
          </select>
          <select
            className="border border-[#E0E0E0] py-2 px-4 rounded-[8px] text-[#666] text-[14px] focus:outline-none w-[170px]"
            onChange={handelFilterChange}
            value={filters?.country || ""}
            name="country"
          >
            <option value="" selected>
              Select Country{" "}
            </option>
            {countryData?.isLoading ? (
              <option value="" disabled>
                Loading Countries...
              </option>
            ) : countryData?.error ? (
              <option value="" disabled>
                Error while fetching countries try refreshing the page
              </option>
            ) : (
              countryData?.data?.map((data, key) => (
                <option value={data?.name} key={key}>
                  {data?.name}
                </option>
              ))
            )}
          </select>
          <p
            className="text-[14px] underline underline-offset-4 cursor-pointer"
            onClick={(e) => setFilters({})}
          >
            Clear filters
          </p>
        </div>

        <div className="w-[28%]">
          <input
            type="text"
            className="w-full border border-[#E0E0E0] rounded-[8px] focus:outline-none py-2 px-2 text-[12px] h-[40px]"
            name="search"
            value={filters?.search || ""}
            onChange={handelFilterChange}
            placeholder="Search by Survey Name, Survey Number, Project Number"
          />
        </div>
      </div>

      {/* table  */}

      <div>
        {/* tabs */}
        <div className="w-full tableBorder flex items-center justify-between px-10 py-3 mt-[5px]">
          {dummyfilterData?.map((data, ind) => (
            <h1
              key={ind}
              className={`text-[#000] text-[16px] font-[400] cursor-pointer transition-all ease-linear capitalize ${
                tab == ind &&
                `text-[#0226BE] font-[700] underline decoration-2 decoration-[#0226BE] underline-offset-[17px]`
              }`}
              onClick={(e) => {
                setTab(ind);
                setSelectRows([]);
                setPage(0);
                localStorage.setItem("survey-tab", ind);
              }}
            >
              {data} (
              {ind == 0
                ? surveyStatusesCount?.all || 0
                : surveyStatusesCount?.[data?.toLowerCase()] || 0}
              )
            </h1>
          ))}
        </div>
        <div className="w-full tableBorder flex items-center justify-between px-6 py-5 ">
          <div className="flex items-center gap-4">
            <input
              type="checkbox"
              className="w-[15px] h-[15px] cursor-pointer ml-[-10px]"
              checked={selectRows?.length}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectRows(allSurveyStats?.data?.map((d) => d?.survey));
                } else {
                  setSelectRows([]);
                }
              }}
            />
            {selectRows?.length ? (
              <span className="text-[15px] text-[#0226BE]">
                Selected: ({selectRows?.length})
              </span>
            ) : (
              <></>
            )}
            {selectRows?.length === 1 ? (
              <MdOutlineModeEditOutline
                size={24}
                className="cursor-pointer"
                onClick={(e) => {
                  if (selectRows?.length && selectRows?.length == 1) {
                    setOpen(true);
                    setInputData({
                      edit: true,
                      setup: {
                        targetAudience: "B2C",
                      },
                      ...selectRows?.[0],
                    });
                  } else {
                    setSnackbar((prev) => ({
                      open: true,
                      msg: `Please Select one survey to edit!`,
                      severity: "warning",
                    }));
                  }
                }}
              />
            ) : (
              ""
            )}

            <MdDownload
              size={24}
              onClick={(e) => {
                DownloadToExcel();
              }}
              className="cursor-pointer"
            />

            <IoMdArchive
              size={24}
              className="cursor-pointer"
              onClick={(e) => {
                if (selectRows?.length) {
                  let nonArchiveSurveys = selectRows?.filter(
                    (d) =>
                      d?.surveyStatus !== "archived" &&
                      d?.surveyStatus !== "completed" &&
                      d?.surveyStatus !== "billed"
                  );
                  if (nonArchiveSurveys.length) {
                    setDeleteModal(true);
                  } else {
                    setSnackbar((prev) => ({
                      open: true,
                      msg: "Already Archived, Billed and Completed surveys can't be  archived !",
                      severity: "warning",
                    }));
                  }
                } else {
                  setSnackbar((prev) => ({
                    open: true,
                    msg: "Please Select at least one survey to Archive!",
                    severity: "warning",
                  }));
                }
              }}
            />
          </div>
        </div>
        <div className="w-full tableBorder flex flex-col ">
          {allSurveyStatsLoading ? (
            <SurveyTableLoader />
          ) : allSurveyStatsError ? (
            <div className=" h-[200px] flex items-center justify-center">
              <h1 className="text-[#666666]">Something went wrong </h1>
            </div>
          ) : allSurveyStats?.data?.noData ? (
            <NoData />
          ) : (
            <div className="w-full overflow-x-auto containerScroll">
              <table
                className="whitespace-nowrap text-left w-full"
                id="table-to-xls-DATA"
              >
                <thead className=" h-[45px] ">
                  <tr className="py-3">
                    <th className="text-[#464545] text-[12px] font-[600] px-3">
                      {/* <input
                          type="checkbox"
                          className="w-[15px] h-[15px] cursor-pointer"
                        /> */}
                    </th>
                    <th className="text-[#464545] text-[12px] font-[600] px-3">
                      Survey Name/ Survey Number/ Project Number
                    </th>

                    <th className="text-[#666] text-[12px] font-[600] px-3">
                      Progress
                    </th>
                    <th className="text-[#666] text-[12px] font-[600] px-3">
                      Survey CPI
                    </th>
                    <th className="text-[#666] text-[12px] font-[600] px-3">
                      Avg. LOI
                    </th>
                    <th className="text-[#666] text-[12px] font-[600] px-3">
                      Conversation Rate
                    </th>
                    <th className="text-[#666] text-[12px] font-[600] px-3">
                      Incidence Rate
                    </th>
                    <th className="text-[#666] text-[12px] font-[600] px-3">
                      Avg. CPI
                    </th>
                    <th className="text-[#666] text-[12px] font-[600] px-3">
                      Drop-off
                    </th>
                    <th className="text-[#666] text-[12px] font-[600] px-3">
                      EPC
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allSurveyStats?.data?.length == 0 ? (
                    <td colSpan={9}>
                      <div className="py-10">
                        <NoData />
                      </div>
                    </td>
                  ) : (
                    allSurveyStats?.data?.map((data, ind) => {
                      return (
                        <tr
                          key={ind}
                          className=" tableBorder transition-all cursor-pointer hover:bg-[#f6f9ff] "
                          onClick={(e) => {
                            navigate(`${data?.survey?._id}/details`);
                          }}
                        >
                          <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                            <div className="flex items-center gap-[11px] text-[20px] text-[#333333]">
                              <input
                                type="checkbox"
                                className="w-[15px] h-[15px] cursor-pointer"
                                checked={selectRows?.some(
                                  (se) => se?._id == data?.survey?._id
                                )}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectRows((prev) => [
                                      ...prev,
                                      data?.survey,
                                    ]);
                                  } else {
                                    setSelectRows(
                                      selectRows?.filter(
                                        (sc) => sc?._id !== data?.survey?._id
                                      )
                                    );
                                  }
                                }}
                              />
                            </div>
                          </td>
                          <td className=" py-2 text-[#667085] font-[400] text-[14px] ">
                            <div className="flex flex-col gap-[10px] py-1  ">
                              <div className="flex items-center  gap-[10px]">
                                <h2
                                  className={` text-blue-500 font-[500]  text-[13px] max-w-[300px] overflow-hidden text-ellipsis px-1`}
                                >
                                  {data?.survey?.surveyName}
                                </h2>
                                <p
                                  className={`bg-blue-500 text-blue-100 font-[500] px-2 text-[10px] border border-blue-500 rounded-md`}
                                >
                                  {data?.survey?.setup?.country}
                                </p>
                              </div>
                              <div className="">
                                <p className="text-[#667085] font-[400] text-[13px] space-x-2 flex items-center">
                                  <p
                                    className={`${
                                      String(
                                        data?.survey?.projectNumber
                                      )?.includes(
                                        debouncedSurveySearchText?.trim()
                                      ) &&
                                      debouncedSurveySearchText?.trim()?.length
                                        ? "bg-yellow-300"
                                        : "bg-transparent"
                                    } px-1`}
                                  >
                                    PN#{data?.survey?.projectNumber}
                                  </p>
                                  <p
                                    className={`${
                                      String(
                                        data?.survey?.surveyNumber
                                      )?.includes(
                                        debouncedSurveySearchText?.trim()
                                      ) &&
                                      debouncedSurveySearchText?.trim()?.length
                                        ? "bg-yellow-300"
                                        : "bg-transparent"
                                    } px-1`}
                                  >
                                    SN#
                                    {data?.survey?.surveyNumber}
                                  </p>
                                  {data?.survey?.surveyStatus == "billed" ? (
                                    <span
                                      className={`${
                                        data?.invoiceStatus !== "Invoiced"
                                          ? "bg-[#C0C2C9] text-[#333] font-[600]"
                                          : "bg-[#333] text-[#fff]"
                                      } py-1 px-2  text-[8px] font-[500]  rounded-full`}
                                    >
                                      {data?.invoiceStatus}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </p>
                                <p className="text-[#333] font-[700]  text-[12px]">
                                  {data?.lastComplete
                                    ? `Last Complete ${timeDifference(
                                        new Date(data?.lastComplete)?.getTime()
                                      )} ago`
                                    : ""}
                                </p>
                                <div className="mt-2">
                                  {/* {data?.surveyStatus == "completed" ? ( */}
                                  {data?.surveyStatus == "completed" ? (
                                    data?.reconciliationStatus ==
                                    "Reconciled" ? (
                                      <p className="text-[#8aff76] bg-[#189585]   text-[10px] font-[500] w-fit text-center rounded-full tracking-wide px-2">
                                        {data?.reconciliationStatus}
                                      </p>
                                    ) : data?.reconciliationStatus ==
                                      "Partially-Reconciled" ? (
                                      <p className="text-[#fff] bg-[#f8ba5a]   text-[10px] font-[500] w-fit px-2 text-center rounded-full tracking-wide">
                                        {data?.reconciliationStatus}
                                      </p>
                                    ) : (
                                      <p className="text-[#fff] bg-[#25e8ce]   text-[10px] font-[500] w-fit px-2 text-center rounded-full">
                                        {data?.reconciliationStatus}
                                      </p>
                                    )
                                  ) : (
                                    ""
                                  )}
                                  {/* // ) : ( // "" // )} */}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className=" py-2 text-[#667085] font-[400] text-[14px] ">
                            <div className="flex items-center justify-between w-[150px]  gap-[10px]">
                              <div>
                                <div className="bg-gray-200 w-[100px] h-[8px] rounded-full overflow-hidden ">
                                  <div
                                    className={`bg-blue-500 h-full  rounded-full  `}
                                    style={{
                                      // width: `50%`,
                                      width:
                                        (
                                          (+data?.completes /
                                            +data?.survey?.expectedMatrix
                                              ?.expectedCompletes) *
                                          100
                                        )?.toFixed(2) + "%",
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <div>
                                {data?.completes} /{" "}
                                {
                                  data?.survey?.expectedMatrix
                                    ?.expectedCompletes
                                }
                              </div>
                            </div>
                          </td>
                          <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] text-center">
                            $ {data?.survey?.setup?.surveyCpi || 0}
                          </td>
                          <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                            {data?.avgLoi ? `${data?.avgLoi} min` : "0 min"}
                          </td>
                          <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                            {data?.conversion
                              ? `${Number(data?.conversion)?.toFixed(2)} %`
                              : "0 %"}
                          </td>
                          <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                            {data?.IR
                              ? `${Number(data?.IR)?.toFixed(2)} %`
                              : `0 %`}
                          </td>
                          <td className="px-3 py-2 text-[#667085] font-[400] text-[14px] ">
                            $ {data?.avgCPI || 0}
                          </td>
                          <td className="px-3 py-2  ">
                            <p
                              className={`${
                                data?.dropRate >= 25
                                  ? "text-red-500"
                                  : "text-[#667085]"
                              } font-[500] text-[14px] `}
                            >
                              {data?.dropRate ? `${data?.dropRate} %` : `0 `}
                            </p>
                          </td>
                          <td className="px-3 py-2  font-[400]  ">
                            <p
                              className={`${
                                data?.epc <= 0.25
                                  ? "text-red-500"
                                  : "text-[#667085]"
                              } font-[500] text-[14px]`}
                            >
                              ${Number(data?.epc) || 0}
                            </p>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          )}
          <CustomTablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            sx={{ fontSize: "10px" }}
            count={allSurveyStats?.pagination?.totalRecords || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            componentsProps={{
              select: {
                "aria-label": "rows per page",
              },
              actions: {
                showFirstButton: true,
                showLastButton: true,
              },
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>

      {/* choose survey type modal  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={surveyTypeModal}
        onClose={() => {
          setSurveyTypeModal(false);
        }}
        closeAfterTransition
      >
        <Fade in={surveyTypeModal}>
          <Box sx={{ ...style, width: 400 }}>
            <div className="px-6 py-4 ">
              {/* border border-primary */}
              <div className=" rounded-md py-2 space-y-6">
                <h1 className="text-[#666] font-[500] text-[22px] text-center">
                  Select Target Audience
                </h1>
                <div className="flex items-center gap-[25px] justify-center cursor-pointer">
                  <div
                    className={` py-4 text-center w-[50%] space-y-3 rounded-lg shadow-md ${
                      inputData?.setup?.targetAudience == "B2C"
                        ? "bg-primary/20 border-2 border-primary"
                        : "bg-primary/10"
                    }`}
                    onClick={(e) => {
                      if (inputData?.setup?.targetAudience !== "B2C") {
                        setInputData((prev) => ({
                          ...prev,
                          setup: {
                            ...prev?.setup,
                            targetAudience: "B2C",
                          },
                        }));
                      }
                    }}
                  >
                    <p className="text-[14px]">Create</p>
                    <h3
                      className={`text-[30px] font-[500] ${
                        inputData?.setup?.targetAudience == "B2C"
                          ? " text-primary"
                          : "text-primary/60"
                      } `}
                    >
                      B2C
                    </h3>
                    <p className="text-[14px]">Targeting Survey</p>
                  </div>

                  <div
                    className={` py-4 text-center w-[50%] space-y-3 cursor-pointer rounded-lg shadow-md ${
                      inputData?.setup?.targetAudience == "B2B"
                        ? "bg-primary/20 border-2 border-primary"
                        : "bg-primary/10"
                    }`}
                    onClick={(e) => {
                      if (inputData?.setup?.targetAudience !== "B2B") {
                        setInputData((prev) => ({
                          ...prev,
                          setup: {
                            ...prev?.setup,
                            targetAudience: "B2B",
                          },
                        }));
                      }
                    }}
                  >
                    <p className="text-[14px]">Create</p>
                    <h3
                      className={`text-[30px] font-[500] ${
                        inputData?.setup?.targetAudience == "B2B"
                          ? " text-primary"
                          : "text-primary/60"
                      } `}
                    >
                      B2B
                    </h3>
                    <p className="text-[14px]">Targeting Survey</p>
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={(e) => {
                      handleOpen();
                      setSurveyTypeModal(false);
                    }}
                    className="w-full  text-[#fff] inter text-[16px] font-[400] bg-[#0226BE] rounded-[4px] py-2 mt-4  "
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* create modal*/}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => {
          setOpen(false);
          setInputData({
            edit: false,
            setup: {
              targetAudience: "B2C",
            },
          });
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={{ ...style }}>
            <div className="w-full flex items-center flex-col gap-[10px]  h-[500px] md:h-[550px] xl:h-[600px] ">
              <div className="w-full py-5 border-b border-[#E0E0E0]  text-center relative">
                <h1 className="text-[#333333] text-[20px] font-[600]">
                  {inputData?.edit ? "Edit" : "Create"} Target Screener Survey
                </h1>
                <p
                  className=" bg-[#F1F1F1] py-1 px-1 rounded-[50%] w-fit absolute top-3 cursor-pointer right-4"
                  onClick={() => {
                    setOpen(false);
                    setInputData({
                      edit: false,
                      setup: {
                        targetAudience: "B2C",
                      },
                    });
                    setSelectRows([]);
                  }}
                >
                  <RxCross2 size={16} />
                </p>
              </div>
              <form
                className="w-full flex flex-col max-h-[85%]   items-center"
                onSubmit={inputData?.edit ? editSurvey : handleSubmit}
              >
                <div className="w-full py-2 px-5  flex flex-col gap-[15px] max-h-[90%] overflow-y-auto containerScroll ">
                  <div className="flex flex-col gap-[10px]">
                    <label
                      htmlFor="SurveyName"
                      className="text-[#333] text-[14px] font-[500]"
                    >
                      Survey Name <Required />
                    </label>
                    <input
                      type="text"
                      name="surveyName"
                      required
                      onChange={handleChange}
                      value={inputData?.surveyName}
                      className="border border-[#E2E2E5] rounded-[4px] pl-3 h-[40px] focus:outline-none"
                    />
                  </div>
                  {/* survey group start */}
                  <div className="flex flex-col gap-[10px]">
                    <label
                      htmlFor="SurveyName"
                      className="text-[#333] text-[14px] font-[500]"
                    >
                      Survey Group
                    </label>
                    <div className="flex w-full items-center relative">
                      <select
                        className="w-full border border-[#E2E2E5] rounded-[4px] h-[40px] placeholder:pl-3 placeholder:text-[14px] placeholder:text-[#12112766] focus:outline-none pl-3 text-[14px]"
                        // value={
                        //   inputData?.edit
                        //     ? inputData?.surveyGroup?._id
                        //     : inputData?.surveyGroup
                        // }
                        value={inputData?.surveyGroup}
                        onChange={(e) => {
                          if (inputData?.edit && !inputData?.prevSurveyGroup) {
                            setInputData((prev) => ({
                              ...prev,
                              prevSurveyGroup: inputData?.surveyGroup?._id,
                              surveyGroup: e.target.value,
                            }));
                          } else {
                            setInputData((prev) => ({
                              ...prev,
                              surveyGroup: e.target.value,
                            }));
                          }
                        }}
                      >
                        <option selected>Select Survey Group</option>
                        {allSurveyGroup?.map((d, i) => (
                          <option value={d?._id} key={i}>
                            {d?.groupName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* survey group end */}
                  <div className="flex flex-col gap-[10px]">
                    <label
                      htmlFor="SurveyName"
                      className="text-[#333] text-[14px] font-[500]"
                    >
                      Project Manager <Required />
                    </label>
                    <select
                      className="border border-[#E2E2E5] rounded-[4px] h-[40px] text-[14px] pl-3"
                      required
                      // value={
                      //   inputData?.edit
                      //     ? inputData?.peoples?.projectManager
                      //     : inputData?.peoples?.projectManager
                      // }
                      value={inputData?.peoples?.projectManager}
                      onChange={(e) =>
                        setInputData((prev) => ({
                          ...prev,
                          peoples: {
                            ...prev.peoples,
                            projectManager: [e.target.value],
                          },
                        }))
                      }
                    >
                      <option value="">Select Project Manger</option>
                      {teamMembers?.map((data, key) => (
                        <option key={key} value={data?._id}>
                          {data?.personalDetails?.fullName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="grid grid-cols-3 gap-[10px]">
                    {/* no of competes */}
                    <div className="w-full  space-y-2">
                      <label
                        htmlFor="SurveyName"
                        className="text-[#333] text-[14px] font-[500]"
                      >
                        No. of Completes <Required />
                      </label>
                      <input
                        type="number"
                        placeholder="10"
                        required
                        min="10"
                        value={
                          inputData?.expectedMatrix?.expectedCompletes || ""
                        }
                        onKeyDown={(e) => {
                          if (e.key === "e" || e.key === "+" || e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          if (e.target.value <= 1000000) {
                            setInputData((prev) => ({
                              ...prev,
                              expectedMatrix: {
                                ...prev.expectedMatrix,
                                expectedCompletes: Number(e.target.value),
                              },
                            }));
                          } else {
                            setSnackbar({
                              open: true,
                              msg: "No of completes should be less than or equal to 1000000",
                              severity: "error",
                            });
                          }
                        }}
                        className="border border-[#E2E2E5] text-myBlack rounded-[4px] h-[40px] pl-3 focus:outline-none w-full"
                      />
                    </div>
                    {/* expected loi */}
                    <div className="w-full  space-y-2">
                      <label
                        htmlFor="expectedCompletionLOI"
                        className="text-[#333] text-[14px] font-[500]"
                      >
                        Expected LOI (mins) <Required />
                      </label>
                      <input
                        type="number"
                        placeholder="15"
                        step="0.01"
                        required
                        min="1"
                        value={
                          inputData?.expectedMatrix?.expectedCompletionLOI || ""
                        }
                        onKeyDown={(e) => {
                          if (e.key === "e" || e.key === "+" || e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          if (e.target.value <= 90) {
                            setInputData((prev) => ({
                              ...prev,
                              expectedMatrix: {
                                ...prev.expectedMatrix,
                                expectedCompletionLOI: Number(e.target.value),
                              },
                            }));
                          } else {
                            setSnackbar({
                              open: true,
                              msg: "Expected LOI should be less than or equal to 90 mins",
                              severity: "error",
                            });
                          }
                        }}
                        className="border border-[#E2E2E5] text-myBlack rounded-[4px] h-[40px] pl-3 focus:outline-none w-full"
                      />
                    </div>
                    {/* expected IR */}
                    <div className="w-full  space-y-2">
                      <label
                        htmlFor="expectedIncidenceRate"
                        className="text-[#333] text-[14px] font-[500]"
                      >
                        Expected IR(%) <Required />
                      </label>
                      <input
                        type="number"
                        placeholder="25"
                        step="0.01"
                        required
                        min="1"
                        value={
                          inputData?.expectedMatrix?.expectedIncidenceRate || ""
                        }
                        onKeyDown={(e) => {
                          if (e.key === "e" || e.key === "+" || e.key === "-") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          if (e.target.value <= 100) {
                            setInputData((prev) => ({
                              ...prev,
                              expectedMatrix: {
                                ...prev.expectedMatrix,
                                expectedIncidenceRate: Number(e.target.value),
                              },
                            }));
                          } else {
                            setSnackbar({
                              open: true,
                              msg: "Expected IR should be less than or equal to 100 %",
                              severity: "error",
                            });
                          }
                        }}
                        className="border border-[#E2E2E5] w-full text-myBlack rounded-[4px] h-[40px] pl-3 focus:outline-none"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-[10px]">
                    <label
                      htmlFor="SurveyName"
                      className="text-[#333] text-[14px] font-[500]"
                    >
                      Country <Required />
                    </label>
                    <select
                      className="border border-[#E2E2E5] rounded-[4px] h-[40px] text-[14px] text-myBlack pl-3"
                      required
                      value={inputData?.setup?.countryCode}
                      onChange={(e) =>
                        setInputData((prev) => ({
                          ...prev,
                          setup: {
                            ...prev.setup,
                            country:
                              e.target.options[e.target.selectedIndex].text,
                            countryCode: e.target.value,
                          },
                        }))
                      }
                    >
                      <option value="">Select Country </option>
                      {countryData?.isLoading ? (
                        <option value="" disabled>
                          Loading Countries...
                        </option>
                      ) : countryData?.error ? (
                        <option value="" disabled>
                          Error while fetching countries try refreshing the page
                        </option>
                      ) : (
                        countryData?.data?.map((data, key) => (
                          <option value={data?.iso2} key={key}>
                            {data?.name}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="flex flex-col gap-[10px]">
                    <label
                      htmlFor="surveyCpi"
                      className="text-[#333] text-[14px] font-[500]"
                    >
                      Survey CPI (USD) <Required />
                    </label>
                    <input
                      type="number"
                      placeholder="2.5"
                      step="0.01"
                      min="0.50"
                      required
                      onKeyDown={(e) => {
                        if (e.key === "e" || e.key === "+" || e.key === "-") {
                          e.preventDefault();
                        }
                      }}
                      value={inputData?.setup?.surveyCpi || ""}
                      onChange={(e) => {
                        if (e.target.value <= 499) {
                          setInputData((prev) => ({
                            ...prev,
                            setup: {
                              ...prev.setup,
                              surveyCpi: Number(e.target.value),
                            },
                          }));
                        } else {
                          setSnackbar({
                            msg: "Survey CPI should be less than or equal to 499",
                            open: true,
                            severity: "error",
                          });
                        }
                      }}
                      className="border border-[#E2E2E5] text-myBlack rounded-[4px] h-[40px] pl-3 focus:outline-none w-full"
                    />
                  </div>
                </div>
                <div className="w-full flex px-5 pb-2">
                  {loading ? (
                    <div className="w-full flex justify-center  px-5 bg-[#0226BE] py-2 mt-4">
                      <CircularProgress className="text-center" size={23} />
                    </div>
                  ) : (
                    <button
                      type="submit"
                      className="w-full  text-[#fff] inter text-[16px] font-[400] bg-[#0226BE] rounded-[4px] py-2 mt-4  "
                    >
                      {inputData?.edit ? "Edit" : "Create"} Survey
                    </button>
                  )}
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* DELETE MODAL */}
      <DeleteModal
        open={deleteModal}
        handleClose={handleCloseModal}
        btnText="Archive"
        oparationText="Archive"
        text={
          selectRows?.length
            ? selectRows
                ?.filter(
                  (d) =>
                    d?.surveyStatus !== "archived" &&
                    d?.surveyStatus !== "completed" &&
                    d?.surveyStatus !== "billed"
                )
                ?.map((data) => data?.surveyName)
            : allSurveyStats?.data
                ?.filter(
                  (d) =>
                    d?.survey?.surveyStatus !== "archived" &&
                    d?.survey?.surveyStatus !== "completed" &&
                    d?.survey?.surveyStatus !== "billed"
                )
                ?.map((data) => data?.survey?.surveyName)
        }
        handleClick={(e) => deleteSurveys(e)}
      />
    </div>
  );
};

export default Survey;
